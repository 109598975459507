import { get, post,put } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';
class Role {
  static #baseUrl = '/role'
  static async create(data) {
    return await post(this.#baseUrl, data)

  }
  static async update(data, id) {
    return await put(this.#baseUrl + `/${id}`, data)
   }
  static async delete() { }
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params)
    return await get(this.#baseUrl + '?' + urlParams)
    // return await get(this.#baseUrl)
  }
  static async readOne(filter) { 
    console.log(filter)
 
  }
  static async readById(id) {
    return await get(`${this.#baseUrl}/${id}`);
  }
}

export default Role;
