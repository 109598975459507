import { Drawer, Input, Button } from "antd";
import { useEffect, useState } from "react";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { useQuote } from "./Quote.context";
import Customer from "../../../../bu/actions/Customer";
import QuoteForm from "./QouteForm";
function QuoteEstimateDrawer() {
  const { estimateDrawerService } = useQuote();

  useEffect(() => {
    if (estimateDrawerService.mode === "create") {
    }
    if (estimateDrawerService.mode === "edit") {
      console.log("opened in edit mode");
    }
  }, [estimateDrawerService.mode, estimateDrawerService.editCustomerId]);

  return (
    <div>
      <Drawer
        title={
          estimateDrawerService.mode === "create"
            ? " Create Quote Estimation"
            : " edit Quote Estimation"
        }
        placement="right"
        onClose={(e) => {
          estimateDrawerService.close();
        }}
        style={{
          display: "flex",
          overflow: "hidden",
          // width: 600,
        }}
        width="500"
        open={estimateDrawerService.isOpen}
      >
        <h4>Quote Estimation</h4>
        <div>{JSON.stringify(estimateDrawerService)}</div>
      </Drawer>
    </div>
  );
}

export default QuoteEstimateDrawer;
