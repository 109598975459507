import React, { useState, useEffect } from "react";
import { Button, Drawer, Table, ConfigProvider, DatePicker } from "antd";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import AchievementCreation from "../../../../../components/creationComponents/createachievementform";
import emptyIcon from '../../../../../assets/EmptyState.jpeg';
import NoData from '../../../../../components/noData/index.js';
import Achievement from "../../../../../bu/actions/Achievements";
import { useParams } from "react-router-dom";
export default function AchievementPage({ messageApi, user }) {
  const { id } = useParams();
  const { RangePicker } = DatePicker;
  const [openAchievement, setOpenAchievement] = useState(false);
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [achievementCreated, setAchievementCreated] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const [achievements, setAchievements] = useState([]);

  const getAchievements = async (page) => {
    // console.log('getAchievements currentPage', page)
    try{
    const response = await Achievement.readAll(Number(id), { skip: (page - 1) * 10 })
    setAchievements(response.data);
    console.log("hhhhhhhhhh", response)
    setTotal(response.length);
    } catch (error) {
      console.error('Error fetching filtered ratings:', error);
      setAchievements([]);
    }
  }

  useEffect(() => {
    try {
      getAchievements(1)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return (
          <>
            {new Date(date).toLocaleDateString('en-GB')}
          </>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    // {
    //   title: 'Special Note',
    //   dataIndex: 'specialNote',
    //   key: 'specialNote',
    // },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      key: 'attachments',
      render: (attachments) => (
      <div>
        {attachments.map((attachment, index) => (
          <div key={index}>
            <LinkOutlined style={{ marginRight: 8 }} />
            <a href={attachment} target="_blank" rel="noopener noreferrer">
              Attachment {index + 1}
            </a>
          </div>
        ))}
      </div>
    ),
    },
  ];

  const showAchievementDrawer = () => {
    setOpenAchievement(true);
    setAchievementCreated(true);
  };

  const onCloseAchievement = () => {
    setOpenAchievement(false);
    getAchievements(1);
    setCurrentPage(1);
  };

  const onChange = (page) => {
    setCurrentPage(page);
    getAchievements(page);
  };
  const handleDateChange = async (dates) => {
    try {
      const filteredData = await Achievement.readAll(
        Number(id), {
        starts_at: dates ? new Date(dates[0].$d).toISOString() : '',
        ends_at: dates ? new Date(dates[1].$d).toISOString() : ''
      });
      setDateRange(dates)
      setAchievements(filteredData?.data || []);

    } catch (error) {
      console.error('Error fetching filtered ratings:', error);
      setAchievements([]);
    }
  };

  return (
    <div style={{ paddingInline: 50, borderBottom: 'none' }}>
      <div style={{ textAlign: 'end', paddingTop: 50 }}>
        <Button style={{ width: 160, borderRadius: 2, height: 36 }} type="primary"
          onClick={showAchievementDrawer}>
          + Add Achievement
        </Button>
      </div>
      <div >
        {/* <RangePicker
          onChange={handleDateChange}
          placeholder={['From Date', 'To Date']} /> */}
          <RangePicker placeholder={['FromDate', 'ToDate']} size="large" onChange={handleDateChange} />
      </div>
      <div style={{ marginTop: 30 }}>
        {Array.isArray(achievements) && achievements.length > 0 ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  cellPaddingBlock: 10,
                },
              },
            }}
          >
            <Table
              // style={{ backgroundColor: '#ECECF0D9' }}
              // className="achievement-table"
              columns={columns}
              dataSource={achievements}
              pagination={{
                onChange,
                total,
                defaultPageSize: 5,
                current: currentPage,
                showSizeChanger: false,
              }}
            />
          </ConfigProvider>
        ) : (
          <NoData
            emptyIcon={emptyIcon}
            noDataMessage="You have no achievements yet"
          />
        )}

        <Drawer
          width={'50%'}
          style={{
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 500,
          }}
          title={
            <span style={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>
              Create Achievement
            </span>
          }
          placement="right"
          onClose={onCloseAchievement}
          open={openAchievement}
        >

          <AchievementCreation messageApi={messageApi} onCloseDrawer={onCloseAchievement} userId={Number(id)} />
        </Drawer>
      </div>
    </div>
  );
}
