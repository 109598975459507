import React, { useState } from "react";
// import { Menu, Drawer } from "antd";
import { useNavigate } from "react-router-dom";

import {
  HomeOutlined,
  UserOutlined,
  ProjectOutlined,
  FileSearchOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
  PlusSquareOutlined,
  ClockCircleOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  TagOutlined,
} from "@ant-design/icons"; // Import icons from Ant Design
import { useAppState } from "../../../state";
import AchievementCreation from "../../../components/creationComponents/createachievementform";
import SuggestionCreation from "../../../components/creationComponents/SuggestionCreation";
import CreateRole from "../../../components/creationComponents/CreateRole";
// -----------
// import usicaicon from "../../../assets/usicaicon.png";
import usicaicon from "../../../assets/usicaLogo.svg";
export default function PrimarySidebar() {
  const [state, setState] = useAppState();
  const [open, setOpen] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [openLogTime, setOpenLogTime] = useState(false);
  const [openRoles, setOpenRoles] = useState(false);
  const navigate = useNavigate();

  const showProjectDrawer = () => {
    setOpen(true);
  };

  const showTaskDrawer = () => {
    setOpenTask(true);
  };

  const showLogTimeDrawer = () => {
    setOpenLogTime(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCloseTask = () => {
    setOpenTask(false);
  };

  const onCloseLogTime = () => {
    setOpenLogTime(false);
  };

  if (1 === 1)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: 80,
          // border: "1px solid red",
          // minWidth: 260,
        }}
      >
        <div
          style={{
            display: "flex",
            // padding: "5px 10px",
            alignItems: "center",
            justifyContent: "center",
            height: 80,
            width: 80,
            // border: "1px solid red",
            background: "#134ea5",
          }}
        >
          <img style={{ height: 60, width: 60 }} src={usicaicon} alt="" />
          {/*  <div
            style={{
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
          <div style={{ fontSize: 22, fontWeight: 700, color: "#291670" }}>
              USICA
            </div>
            <div style={{ fontSize: 14 }}>Teknoservices Pvt. Ltd.</div> 
          </div>*/}
        </div>
        <div style={{ flex: 1, background: "#1d63cb", padding: 10, overflow: "auto", maxHeight: "90vh", overflowX: "hidden" }}>
          <style>
            {`
      ::-webkit-scrollbar {
        width: 0px; /* Set the width of the scrollbar */
      }
    `}
          </style>
          {[
            {
              title: "Dashboard",
              Icon: HomeOutlined,
              path: "/dashboard",
              shouldDisplay: true,
            },
            {
              title: "All Time Entries",
              Icon: FieldTimeOutlined,
              path: "/all-time-entries",
              shouldDisplay:
              !state?.user?.groups?.includes(
                "GROUP_TECHNICAL_TEAM"
              ) ||
                state?.user?.permissions.includes("super-admin"),
            },
            {
              title: "Employees",
              Icon: UserOutlined,
              path: "/employee",
              shouldDisplay:
                state?.user?.accessibleEntities.includes("employees") ||
                state?.user?.permissions.includes("super-admin"),
            },
            {
              title: "Activity List",
              Icon: UnorderedListOutlined,
              path: "/activities",
              shouldDisplay:
                state?.user?.accessibleEntities.includes("activity") ||
                state?.user?.permissions.includes("super-admin"),
            },
            {
              title: "Projects",
              Icon: ProjectOutlined,
              path: "/projects",
              shouldDisplay: !state?.user?.groups?.includes(
                "GROUP_TECHNICAL_TEAM"
              ),
            },
            {
              title: "Time Entry",
              Icon: FieldTimeOutlined,
              path: "/timesheets",
              shouldDisplay:
                state?.user?.accessibleEntities.includes("timeentry") ||
                state?.user?.permissions.includes("super-admin"),
            },
            // {
            //   title: "Customers",
            //   Icon: FieldTimeOutlined,
            //   path: "/customers",
            //   shouldDisplay: true,
            // },
            // {
            //   title: "Quote",
            //   Icon: FieldTimeOutlined,
            //   path: "/quotes",
            //   shouldDisplay: true,
            // },
            // {
            //   title: "PO",
            //   Icon: FieldTimeOutlined,
            //   path: "/po",
            //   shouldDisplay: true,
            // },
            // {
            //   title: "Invoice",
            //   Icon: FieldTimeOutlined,
            //   path: "/invoice",
            //   shouldDisplay: true,
            // },
            // {
            //   title: "Payments",
            //   Icon: FieldTimeOutlined,
            //   path: "/payments",
            //   shouldDisplay: true,
            // },
            // {
            //   title: "COR",
            //   Icon: FieldTimeOutlined,
            //   path: "/cor",
            //   shouldDisplay: true,
            // },
            {
              title: "Roles",
              Icon: TagOutlined,
              path: "/roles",
              shouldDisplay:
                state?.user?.accessibleEntities.includes("roles") ||
                state?.user?.permissions.includes("super-admin"),
            },
          ].map((d, i) => {
            if (d.shouldDisplay)
              return (
                <div
                  key={i + "-" + d.title}
                  onClick={() => navigate(d.path)}
                  style={{
                    padding: 5,
                    margin: 5,
                    // border: "1px solid #eee",
                    fontWeight: 600,
                    letterSpacing: 0.5,
                    cursor: "pointer",
                    display: "flex",
                    color: "#fff",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: 6,
                      borderRadius: 10,
                      // border: "1px solid #ccc",
                      background: "#fff",
                    }}
                  >
                    <d.Icon style={{ fontSize: 20, color: "#3a7efc" }} />
                  </div>
                  <div
                    style={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 10,
                      // fontWeight: 300,
                      padding: 3,
                      textAlign: "center",
                    }}
                  >
                    {d.title}
                  </div>
                </div>
              );
          })}
        </div>
        {/* <div style={{ padding: 10 }}>Sidebar footer</div> */}
      </div>
    );
}
