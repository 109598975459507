import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { get } from "../../../../api/request";

export default function InvoiceDetails() {
  const [invoice, setInvoice] = useState({});
  const { id } = useParams();
  async function loadInvoice() {
    const res = await get(`invoice/${id}`);
    setInvoice(res);
  }
  useEffect(() => {
    if (id) {
      loadInvoice();
    }
  }, [id]);
  return (
    <div style={{ padding: 10 }}>
      <h3>Invoice: {invoice.seq_number}</h3>
      <h3>Cusomer Name: {invoice?.customer?.name}</h3>
      <h3>Project Name: {invoice?.project?.name}</h3>
      <table style={{ width: "100%", background: "#fff" }} border="1">
        <tr>
          <td>SL.No</td>
          <td>Description</td>
          <td>Unit rate</td>
          <td>No of Hours/Unit</td>
          <td>Total Amount</td>
        </tr>
        {invoice?.invoice_cors?.length > 0 ? (
          invoice.invoice_cors.map((d, i) => (
            <tr key={i}>
              <td style={{ textAlign: "center" }}>{i + 1}</td>
              <td>{d.description}</td>
              <td>{d.cor?.cor_amount}</td>
              <td>{d.hours_per_unit}</td>
              <td>
                {isNaN(d.cor?.cor_amount) || isNaN(d.hours_per_unit) ? 'N/A' : d.cor?.cor_amount * d.hours_per_unit}
              </td>

            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" style={{ textAlign: "center" }}>No data available</td>
          </tr>
        )}
      </table>
    </div>
  );
}
