import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useParams, useSearchParams } from 'react-router-dom';
import { Rate, Tabs } from "antd";
import "./index.css";
import user from "../../../../assets/userlogo.png";
import About from "../Profile/About/index.js";
import EmployeeRate from "./EmployeeRate/index.js";
import Achievement from "./Achievement/index.js";
import Suggestion from "./Suggestion/index.js";
import { CameraTwoTone } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { get } from "../../../../api/request";
import User from "../../../../bu/actions/Users";

const profileInfo = [
  {
    image: user,
    name: "Rayan Gotti",
    designataion: "Product Engineer",
    pendingtask: 10,
    completedtask: 100,
  },
];

const EmployeeProfile = [
  {
    empId: "USICA001",
    empName: "Rayan Gotti",
    dob: "02/11/1984",
    doj: "02/10/2011",
    position: "Manager",
    department: "Product-Design",
    exp: 10,
    totalexp: 12,
    phone: 9482631542,
    email: "usica@gmail.com",
  },
];
const onChange = (key) => { };



const Profile = ({ messageApi }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState("")

  const info = profileInfo[0];
  const { id } = useParams();
  const tabBarGutterValue = 250;
  const [user, setUser] = useState({})
  const customTabBarStyle = {
    padding: "10px",
    maxWidth: "calc(100vw - 500px)"
  };
  useEffect(() => {
    loadProfileData()
    console.log("calling userapi")
  }, []);
  useEffect(() => {
    switch (searchParams.get("tab")) {
      case "ratings":
        setActiveKey("2")
        break;
      case "achievements":
        setActiveKey("3")
        break;
      case "suggestions":
        setActiveKey("4")
        break;
      default:
        setActiveKey("1")
        break;
    }
  }, [searchParams])
  const tabs = [
    {
      key: "1",
      label: "About",
      children: <About user={user} />,
    },
    {
      key: "2",
      label: "Ratings",
      children: <EmployeeRate messageApi={messageApi} user={user} />
    },
    {
      key: "3",
      label: "Achievements",
      children: <Achievement messageApi={messageApi} user={user} />
    },
    {
      key: "4",
      label: "Suggestions",
      children: <Suggestion messageApi={messageApi} user={user} />
    },
  ];
  async function loadProfileData() {

    const res = await get(`user/${id}`);
    console.log(res);
    setUser(res)
  }
  return (
    <div className="profile-maincontainer">
      <div className="change-cover">
        <text className="change-cover-text">Change cover</text>
        <CameraTwoTone
          style={{
            position: "absolute",
            top: 7,
            left: 135,
            zIndex: 1,
            fontSize: "1.5em",
          }}
        />
      </div>
      <div className="profile-maincontainer-subsection">
        <div className="profile-maincontainer-sidebar">
          <div className="profile-maincontainer-sidebar-title">
            <img src={info.image} alt="" className="Profile-pic" />
            <CameraTwoTone
              style={{
                position: "absolute",
                top: 105,
                left: 156,
                zIndex: 1,
                color: blue.primary,
                fontSize: "1.5em",
              }}
            />
            <p className="profile-name">{user?.profile?.first_name} {user?.profile?.last_name}</p>
            {/* <p className="profile-designation">{user?.profile?.designation_id}</p> */}
          </div>
          <div className="profile-rate-tab">
            <div className="profile-rate-tab-text">3.0</div>
            <div className="profile-rate-tab-star">
              <Rate disabled defaultValue={3} />
            </div>
          </div>

          <div className="profile-completetask">
            <div className="profile-completetask-text">Task Completed</div>
            <div className="profile-completetask-value">
              {info.completedtask}
            </div>
          </div>
          <div className="profile-pendingtask">
            <div className="profile-pendingtask-text">Task Pending</div>
            <div className="profile-pendingtask-value">{info.pendingtask}</div>
          </div>
        </div>
        <div className="profile-maincontainer-details">
          <div className="profile-maincontainer-tabs">
            <Tabs

              activeKey={activeKey}
              type="card"
              size="large"
              items={tabs}
              tabBarStyle={customTabBarStyle}
              onTabClick={(key) => { setActiveKey(key) }}
            />
            {/* <Tabs type="card"
              defaultActiveKey="1"
              size="large"
              tabBarStyle={customTabBarStyle}
              centered={true}
              items={tabs}
              tabBarGutter={tabBarGutterValue}
              onChange={onChange}
            /> */}
          </div>
        </div>
      </div>


    </div>
  );
};

export default Profile;
