import CustomerSidebar from "./QuoteSidebar";
import { QuoteProvider, useQuote } from "./Quote.context";
import CustomersList from "./QuoteList";
import CustomerDrawer from "./QuoteDrawer";

import QuoteList from "./QuoteList";
import QuoteEstimateDrawer from "./QuoteEstimateDrawer";
function QuotePage({}) {
  return (
    <QuoteProvider>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <CustomerSidebar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginLeft: 10 }}>Quotes</h2>
          {/* <CustomersList /> */}
          <QuoteList />
          <CustomerDrawer />
          <QuoteEstimateDrawer />
        </div>
      </div>
    </QuoteProvider>
  );
}

export default QuotePage;
