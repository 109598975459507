import CustomerSidebar from "./CustomerSidebar";
import { CustomersProvider, useCustomer } from "./Customer.context";
import CustomersList from "./CustomersList";
import CustomerDrawer from "./CustomerDrawer";
function CustomersPage({}) {
  return (
    <CustomersProvider>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <CustomerSidebar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginLeft: 10 }}>Customers</h2>
          <CustomersList />
          <CustomerDrawer />
        </div>
      </div>
    </CustomersProvider>
  );
}

export default CustomersPage;
