import { redirect, useNavigate } from "react-router-dom";
import { Button, Drawer, Form, Input, DatePicker, Table } from "antd";
import { LinkOutlined, LinkedinOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "./index.css";
import emptyicon from '../../../../../assets/EmptyState.jpeg';
import NoData from '../../../../../components/noData/index.js';
import SuggestionCreation from "../../../../../components/creationComponents/SuggestionCreation";
import Suggestion from "../../../../../bu/actions/Suggestion.js";
import { useParams } from "react-router-dom";
import { date } from "yup";
const { RangePicker } = DatePicker;
const { Search } = Input;

export default function SuggestionPage({ messageApi, user }) {
  const { id } = useParams();
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [achievementCreated, setAchievementCreated] = useState(false);
  const [dateRange, setDateRange] = useState();
  const showSuggestionDrawer = () => {
    setOpenSuggestion(true);
    setAchievementCreated(true);
  };
  const onCloseSuggestion = () => {
    setOpenSuggestion(false);
    getSuggestions(1);
    setCurrentPage(1);
  };
  const onChange = (page) => {
    setCurrentPage(page);
    setSuggestions(page);
  };

  const [suggestions, setSuggestions] = useState({});
  const getSuggestions = async (page) => {
    console.log('getTasks currentPage', page)
  try{
    const response = await Suggestion.readAll((id), { skip: (page - 1) * 10 })
    setSuggestions(response.data);
    setTotal(response.meta.total);
  } catch (error) {
    console.error('Error fetching filtered ratings:', error);
    setSuggestions([]);
  }
  }
  useEffect(() => {
    try {
      getSuggestions(1)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return (
          <>
            {new Date(date).toLocaleDateString('en-GB')}
          </>
        );
      },
    },

    {
      title: 'Attachments',
      dataIndex: 'attachments',
      key: 'attachments',
      render: (attachments) => (
        <div>
          {attachments.map((attachment, index) => (
            <li key={index}>
              <LinkOutlined style={{ marginRight: 8 }} />
              <a href={attachment} target="_blank" rel="noopener noreferrer">
                Attachment {index + 1}
              </a>
            </li>
          ))}
        </div>
      ),
    },
  ];

  const handleDateChange = async (dates) => {
    try {
      const filteredData = await Suggestion.readAll(
        Number(id), {
        starts_at: dates ? new Date(dates[0].$d).toISOString() : '',
        ends_at: dates ? new Date(dates[1].$d).toISOString() : ''
      });
      setDateRange(dates)
      setSuggestions(filteredData?.data || []);

    } catch (error) {
      console.error('Error fetching filtered ratings:', error);
      setSuggestions([]);
    }
  };
  return (
    <div style={{ paddingInline: 50, borderBottom: 'none', }}>
      <div style={{ textAlign: "end", paddingTop: 50 }}>
        <Button style={{ width: 136, borderRadius: 2, height: 36 }} type="primary" onClick={showSuggestionDrawer}> + Add Suggestion</Button>
      </div>
      <div className="suggestion_list_searchbox_container">

        <RangePicker placeholder={['FromDate', 'ToDate']} size="large" onChange={handleDateChange} />

      </div>


      {Array.isArray(suggestions) && suggestions.length > 0 ? (
        <Table
          style={{ padding: '0px 20px' }}
          className='suggestions-table'
          columns={columns}
          dataSource={suggestions}
          pagination={{
            onChange,
            total,
            defaultPageSize: 5,
            current: currentPage,
            showSizeChanger: false,
          }}
        />
      ) : (
        <div>
          <NoData emptyIcon={emptyicon} noDataMessage="You have no Suggestion yet" />
        </div>
      )}


      <Drawer
        width={"50%"}
        style={{
          textAlign: "center",
          fontSize: 20,
          fontWeight: 500,
        }}
        title={
          <span style={{ textAlign: "center", fontSize: 20 }}>
            Suggestion
          </span>
        }
        placement="right"
        onClose={onCloseSuggestion}
        open={openSuggestion}
      >
        <SuggestionCreation messageApi={messageApi} onClose={onCloseSuggestion} userId={id} />
      </Drawer>
    </div>
  );
}
