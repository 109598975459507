import { get, post } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';
class Rate {
    static #baseUrl = '/profile/rating'
    static async create(data) {
        return await post(this.#baseUrl, data)

    }
    static async update() { }
    static async delete() { }
    static async readAll(userId, params = {}) {
        const urlParams = objectToUrlParams(params)
        return await get(`/profile/${userId}` + '?' + urlParams)
        // return await get(this.#baseUrl)
    }
}

export default Rate;
