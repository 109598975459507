import { Drawer, Input, Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import FormFieldTitle from '../../../../components/global/FormFieldTitle';
import ErrorMessage from '../../../../components/global/ErrorMessage';
import { usePo } from './Invoice.context';
import Customer from '../../../../bu/actions/Customer';
import POForm from './InvoiceForm';
import { get, post } from '../../../../api/request';
let corIndex = 1;
function PoDrawer() {
  const { loadInvices, drawerService } = usePo();
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [cors, setCors] = useState([]);
  const [uninvoicedBalance, setUninvoicedBalance] = useState(null);
  const lineOptions = [{ description: 'COR', unitRate: 10, noOfHrs: 10 }];
  async function loadCustomers() {
    try {
      const res = await get('customer?limit=1000');
      setCustomers(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function loadUninvoincedBalance() {
    const res = await get(`project/${selectedProjectId}/balances?`);
    setUninvoicedBalance(res.data);
    console.log('setUninvoicedBalance: ', res.data);
  }
  async function loadCOR() {
    const res = await get(
      `cor?limit=1000&searchMap=${JSON.stringify({
        project_id: selectedProjectId,
      })}`
    );
    setCors(res.data);
    console.log('cors: ', res.data);
  }
  async function loadProjects() {
    try {
      const res = await get('project?limit=1000');
      setProjects(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (selectedProjectId) {
      loadCOR();
      loadUninvoincedBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);
  useEffect(() => {
    loadCustomers();
    loadProjects();
    if (drawerService.mode === 'create') {
      setSelectedCustomerId(null);
      setLineItems([]);
      setSelectedProjectId(null);
    }
    if (drawerService.mode === 'edit') {
    }
  }, [drawerService.mode, drawerService.editCustomerId, drawerService.isOpen]);

  return (
    <div>
      <Drawer
        title={
          drawerService.mode === 'create' ? 'Create Invoice' : 'Edit Invoice'
        }
        placement="right"
        onClose={(e) => {
          drawerService.close();
        }}
        style={{
          display: 'flex',
          //
          overflow: 'hidden',
          // width: 600,
        }}
        width="1000"
        open={drawerService.isOpen}
      >
        <div
          style={{
            flex: 1,
            height: '99%',
            display: 'flex',
          }}
        >
          <div style={{ flex: 1, padding: 10 }}>
            <div style={{ marginBottom: 10 }}>
              <div>Customer </div>
              <div>
                <Select
                  style={{ width: 200 }}
                  placeholder="select Customer"
                  value={selectedCustomerId}
                  onChange={(e) => setSelectedCustomerId(e)}
                >
                  {customers.map((c, i) => (
                    <option key={'cu' + i} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Project </div>
              <div>
                <Select
                  value={selectedProjectId}
                  style={{ width: 200 }}
                  placeholder="select Project"
                  onChange={(e) => {
                    setSelectedProjectId(e);
                  }}
                >
                  {projects.map((c, i) => (
                    <option key={'cu' + i} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            {/* <div style={{ marginBottom: 10 }}>
              <div>Po Number </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Po Ammount </div>
              <div></div>
            </div> */}
          </div>
          <div
            style={{
              flex: 3,
              padding: 10,
              background: '#f1f1f1',
              display: 'flex',
              flexDirection: 'column',
              overflowY:'auto'
            }}
          >
            <div style={{ padding: 10, background: '#f1f1f1' }}>
              {uninvoicedBalance && (
                <table style={{ width: '100%', background: '#fff' }} border="1">
                  <tr>
                    <td>Detailing</td>
                    <td>Designing</td>
                    {uninvoicedBalance?.cors?.map((cor) => (
                      <td>{`${cor.number}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td>{uninvoicedBalance.detailing_amount}</td>
                    <td>{uninvoicedBalance.design_amount}</td>
                    {uninvoicedBalance?.cors?.map((cor) => (
                      <td>{`${cor.amount}`}</td>
                    ))}
                  </tr>
                </table>
              )}
            </div>
            <div style={{ padding: 10, background: '#f1f1f1' }}>
              <table style={{ width: '100%', background: '#fff' }} border="1">
                <tr>
                  <td>SL.No</td>
                  <td>Description</td>
                  <td>Unit rate</td>
                  <td>No of Hours/Unit1</td>
                  <td>Total Amount</td>
                  <td>Actions</td>
                </tr>
                {lineItems.map((d, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: 'center' }}>{i + 1}</td>
                    <td>
                      {
                        <Select
                          value={d.seq_number}
                          style={{ width: 200 }}
                          disabled={
                            d.description === 'detailing' ||
                            d.description === 'design'
                          }
                          onChange={(e) => {
                            setLineItems((pv) => {
                              console.log(pv[i].id);
                              pv[i].id = e;
                              return [...pv];
                            });
                          }}
                        >
                          {cors.map((c, i) => (
                            <option key={'cu1' + i} value={c.id}>
                              COR# {c.seq_number}
                            </option>
                          ))}
                        </Select>
                      }
                      {/* {d.description} */}
                    </td>
                    <td>
                      <Input
                        value={d.unitRate}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setLineItems((pv) => {
                            console.log(pv[i].unitRate);
                            if (parseInt(e.target.value))
                              pv[i].unitRate = parseInt(e.target.value);
                            return [...pv];
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        value={d.noOfHrs}
                        onChange={(e) => {
                          setLineItems((pv) => {
                            if (parseInt(e.target.value))
                              pv[i].noOfHrs = parseInt(e.target.value);
                            pv[i].hours_per_unit = parseInt(e.target.value);
                            return [...pv];
                          });
                        }}
                      />
                    </td>
                    <td>{d.unitRate && d.noOfHrs && d.unitRate + d.noOfHrs}</td>
                    <td>
                      <Button
                        onClick={(e) => {
                          setLineItems((pv) => {
                            pv.splice(i, 1);
                            return [...pv];
                          });
                        }}
                        size={'small'}
                      >
                        remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
              <div style={{ display: 'flex', marginTop: 10 }}>
                <div style={{ flex: 1 }} />
                {lineItems.filter((d) => d.description === 'detailing')
                  .length === 0 && (
                  <Button
                    onClick={(e) =>
                      setLineItems((p) => [...p, { description: 'detailing' }])
                    }
                  >
                    Add Detailing
                  </Button>
                )}
                {lineItems.filter((d) => d.description === 'design').length ===
                  0 && (
                  <Button
                    onClick={(e) =>
                      setLineItems((p) => [...p, { description: 'design' }])
                    }
                  >
                    Add Design
                  </Button>
                )}
                <Button
                  onClick={(e) =>
                    setLineItems((p) => [
                      ...p,
                      {
                        description: 'COR#' + corIndex++,
                      },
                    ])
                  }
                >
                  Add COR
                </Button>
              </div>
              <div style={{ display: 'flex', paddingTop: 10 }}>
                <div style={{ flex: 1 }} />

                <Button
                  style={{ width: 120 }}
                  type="primary"
                  onClick={async (e) => {
                    const data = {
                      project_id: selectedProjectId,
                      customer_id: selectedCustomerId,
                    };

                    const detailing = lineItems.filter(
                      (d) => d.description === 'detainling'
                    );
                    if (detailing.length > 0) {
                      data.detailing_amount_description =
                        detailing[0]?.description;
                      data.detailing_amount_hours_per_unit =
                        detailing[0]?.unitRate;
                      data.detailing_amount = detailing[0]?.noOfHrs;
                    }
                    // design_amount
                    const design = lineItems.filter(
                      (d) => d.description === 'design'
                    );
                    if (design.length > 0) {
                      data.design_amount_description = design[0]?.description;
                      data.design_amount_hours_per_unit = design[0]?.unitRate;
                      data.design_amount = design[0]?.noOfHrs;
                    }
                    data.cors = lineItems.filter((d) =>
                      d.description.includes('COR')
                    );
                    const res = await post('invoice', data);
                    console.log(res);
                    alert('Invoice created Successfully!');
                    drawerService.close();
                    loadInvices()
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PoDrawer;
