import React, { createContext, useContext, useEffect, useState } from "react";
import propTypes from "prop-types";
import Customer from "../../../../bu/actions/Customer";
const CustomersContext = createContext();
const sampleData = [
  {
    id: 1,
    name: "Walmart",
    contacts: [
      {
        name: "Walmart person1",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Walmart person2",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Walmart person3",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Walmart person4",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
    ],
  },
  {
    id: 2,
    name: "Welders",
    contacts: [
      {
        name: "Welders person1",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Welders person2",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Welders person3",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Welders person4",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
    ],
  },
  {
    id: 3,
    name: "Jhonies",
    contacts: [
      {
        name: "Jhonies person1",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Jhonies person2",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Jhonies person3",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
      {
        name: "Jhonies person4",
        phone: "9988998898",
        email: "someEmail@gmail.com",
      },
    ],
  },
];
export const CustomersProvider = ({ children }) => {
  const [customers, setCustomers] = useState([]);
  const [searchKey, setSerachKey] = useState("");
  const [drawerState, setDrawerState] = useState({
    open: false,
    mode: "create",
    editCustomerId: null,
  });

  function openToCreate() {
    const _d = { ...drawerState };
    _d.open = true;
    _d.editCustomerId = null;
    _d.mode = "create";
    setDrawerState(_d);
  }
  function openToEdit(id) {
    const _d = { ...drawerState };
    _d.open = true;
    _d.mode = "edit";
    _d.editCustomerId = id;
    setDrawerState(_d);
  }
  function close() {
    const _d = { ...drawerState };
    _d.open = false;
    setDrawerState(_d);
  }
  const extend = (i, shouldExpand) => {
    try {
      const _c = [...customers];
      console.log(_c);
      _c[i].expanded = shouldExpand;
      setCustomers(_c);
    } catch (e) {
      console.log(e);
    }
  };
  const loadCustomers = async () => {
    try {
      const cres = await Customer.readAll();
      setCustomers(
        cres?.data.map((d, i) => ({
          ...d,
          expanded: false,
        }))
      );
    } catch (e) {}
  };
  useEffect(() => {
    loadCustomers();
  }, []);
  const createCategory = () => {};

  return (
    <CustomersContext.Provider
      value={{
        customers,
        searchKey,
        setSerachKey,
        extend,
        loadCustomers,
        createCategory,
        editCustomerId: null,
        drawerService: {
          isOpen: drawerState.open,
          openToCreate,
          close,
          openToEdit,
          mode: drawerState.mode,
          editCustomerId: drawerState.editCustomerId,
        },
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};
CustomersContext.propTypes = {
  children: propTypes.node.isRequired,
};
export const useCustomer = () => useContext(CustomersContext);
