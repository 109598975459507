import React, { useState, useEffect } from "react";
import "./index.css";
import {
  Table,
  Button,
  Drawer,
  ConfigProvider,
  Form,
  DatePicker,
  Input,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Activities from "../../../../bu/actions/Activities";
import CreateActivity from "../../../../components/creationComponents/CreateActivity";
import emptyIcon from "../../../../assets/EmptyState.png";
import NoData from "../../../../components/noData";
import { useAppState } from "../../../../state";

export default function ActivityPage({ messageApi, emitter }) {
  const [state, setState] = useAppState();
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [recordToUpdate, setRecordToUpdate] = useState({});
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);
  // const [activityNames, setActivityNames] = useState([]);
  const { RangePicker } = DatePicker;
  const { Search } = Input;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button
          style={{ color: "blue", border: "none", background: "none" }}
          icon={<EditOutlined />}
          onClick={() => {
            setRecordToUpdate(record);
            setIsUpdate(true);
            setOpen(true);
          }}
        ></Button>
      ),
    },
  ];

  const columnsForSubActivities = [
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "1%",
      render: (text, record, index) => {
        // Customize the style of the row header (Name in this case)
        const headerStyle = {
          color: "blue", // Change the color to your desired color
          // Add any other styles as needed
        };

        return <span style={headerStyle}></span>;
      },
    },
    {
      title: "Sub-activity name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        // Customize the style of the row header (Name in this case)
        const headerStyle = {
          color: "blue", // Change the color to your desired color
          // Add any other styles as needed
        };

        return <span style={headerStyle}>{text}</span>;
      },
    },
    {
      title: "Sub-activity description",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => {
        // Customize the style of the row header (Name in this case)
        const headerStyle = {
          color: "blue", // Change the color to your desired color
          // Add any other styles as needed
        };

        return <span style={headerStyle}>{text}</span>;
      },
    },
  ];

  const fetchActivities = async (page) => {
    try {
      const response = await Activities.readAll({
        skip: (page - 1) * 10,
        sort: "created_at",
        sortOrder: "desc",
        searchMap: JSON.stringify({ parent_activity_id: null }),
      });
      const activities = response.data.map((activity) => {
        return { key: activity.id, ...activity };
      });
      setActivities(activities);
      setTotal(response.meta.total);
    } catch (error) {
      console.error("Error fetching activities:", error);
      // Handle error state or display an error message
    }
  };

  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const onClose = async () => {
    setOpen(false);
    form.resetFields();
    await fetchActivities(1);
  };
  const handleCreateActivity = () => {
    setIsUpdate(false);
    setOpen(true);
  };

  const onChange = (page) => {
    setCurrentPage(page);
    fetchActivities(page);
  };

  const onClear = async () => {
    console.log("handleCreateTask 1");
    emitter.emit("clear-activity-form");
  };

  const onSubmit = async () => {
    console.log("handleCreateTask 1");
    emitter.emit("create-activity");
  };

  useEffect(() => {
    const handleResize = () => {
      let newCellPaddingBlock;

      if (window.innerWidth < 500) {
        newCellPaddingBlock = 15;
      } else if (window.innerWidth < 740) {
        newCellPaddingBlock = 10;
      } else {
        newCellPaddingBlock = 2;
      }

      setCellPaddingBlock(newCellPaddingBlock);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="activity-main-container">
      <div className="activity-container">
        <div className="activity-page-button">
          <div className="activity-page-title-container">
            <div className="activity-page-title">Activities</div>
          </div>
          <div className="activity-page-container">
            {(state?.user?.permissions.includes("project.create") ||
              state?.user?.permissions.includes("super-admin")) && (
              <>
                <Button
                  style={{ height: 30, backgroundColor: "#8686ff" }}
                  type="primary"
                  onClick={handleCreateActivity}
                >
                  + Create Activity
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="activity-table-field"></div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: cellPaddingBlock,
            },
          },
        }}
      >
        <Table
          className="activity-table"
          columns={columns}
          dataSource={activities}
          pagination={{
            onChange: handlePaginationChange,
            total,
            defaultPageSize: 10,
            current: currentPage,
            showSizeChanger: false,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                // title={() => 'Sub-activities for ' + record.name}
                columns={columnsForSubActivities}
                dataSource={record.sub_activities}
                pagination={false}
              />
            ),
            rowExpandable: (record) => record?.sub_activities?.length > 0,
          }}
        />
      </ConfigProvider>

      <Drawer
        width={window.innerWidth > 1460 ? "728.5px" : "50%"}
        title={`${isUpdate ? "Update" : "Create"} Activity`}
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <div style={{ textAlign: "right" }}>
            <Button style={{ marginRight: 8 }} onClick={onClear}>
              Clear
            </Button>
            <Button
              style={{
                backgroundColor: "#8686ff",
                width: "75.5px",
                height: "30px",
              }}
              type="primary"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        }
      >
        <CreateActivity
          messageApi={messageApi}
          onCreateActivityCloseDrawer={onClose}
          emitter={emitter}
          isUpdate={isUpdate}
          recordToUpdate={recordToUpdate}
        />
      </Drawer>
    </div>
  );
}
