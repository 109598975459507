import "../../../../index.css";
import "./index.css";
import {Table} from 'antd'
import { useEffect, useReducer, useState } from "react";
import projectReducer from "./projectReducer";
import ProjectSidebar from "./ProjectSidebar";
import PageHeader from "../../../../components/PageHeader";
import { Button, Drawer, Input } from "antd";
import useProject from "./projectService";
import { json, useNavigate, useParams } from "react-router";
import CreateProjectDrawer from "./CreateProject";
import AllocateWorkDrawer from "./AllocateWork";
import AllocateProjectDrawer from "./AllocateProject";
import dayjs from "dayjs";
import User from "../../../../bu/actions/Users";
import { useAppState } from "../../../../state";
import { get } from "../../../../api/request";
import { useLocation } from "react-router-dom";
const initialState = {
  projects: [],
};

export default function AllocatedWorkList({title}) {
  //   [projects, loadProjects, page, projectsLoadingStatus]
  const { id } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const [timeentr, setTimeEntry] = useState([]);
  const [project, setProject] = useState({});
  const [projectTimeentries, setProjectTimeentries] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  async function loadTimeEntries() {
    // const res = await get("/");
  }
  async function loadProject() {
    try {
      const res = await get(`project/${id}`);
      const res1 = await get(`userproject/${id}?limli=1000`);
      setProjectUsers(res1);
      const us = localStorage.getItem("userState");
       const userState = JSON.parse(us);
       const userId = userState.user.user_id;
       const groups = userState.user.groups;
      let searchMap = `%7B%22project_id%22%3A${id}%7D`;
        if (userId && !groups.includes('GROUP_TEAM_LEAD') ) {
            searchMap = `%7B%22project_id%22%3A${id}%2C%22user_id%22%3A${userId}%7D`;
        }
      // const res2 = await get(
      //   `/allocatedwork?limit=1000&searchMap=%7B%22project_id%22%3A${id}%7D`
      // );
      const res2 = await get(`/allocatedwork?limit=1000&searchMap=${searchMap}`);
      console.log("res===>", res, res1, res2);
      setProject(res);
      setProjectTimeentries(res2.data || []);
    } catch (e) {
      console.log(e);
    }
  }
  useState(() => {
    loadTimeEntries();
  }, []);
  useState(() => {
    if (id) loadProject();
  }, [id]);
 
  const columns = [
    {
      title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => `${record.user.profile.first_name} ${record.user.profile.last_name}`,
    },
    {
      title: 'Domain',
    dataIndex: [ 'domain', 'name'], // Adjusted to access nested properties
    key: 'domain',
    },
    {
      title: 'Activity',
      dataIndex: ['activity','name'],
      key: 'activity',
    },
    {
      title: 'Duration',
      dataIndex: ['duration'],
      key: 'duration',
    },
    {
      title: 'Quantity',
      dataIndex: ['quantity'],
      key: 'quantity',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (start_date) => dayjs(start_date).format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: 'End Time',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (end_date) => dayjs(end_date).format("DD-MM-YYYY hh:mm A"),
    },
  ];
  return (
    <div className="page-container page-v-split">
     
      <div
        className="page-body"
        style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{ marginBottom: 20, cursor: "pointer", color: "blue" }}
          onClick={(e) => {
            if (location.pathname.match(/^\/dashboard\/work-allocation\/\d+$/)) {
              navigation("/dashboard");
            } else { 
            navigation("/projects")}
          }}
        >
          {"<-"} Back to {title +" "}
        </div>
        <PageHeader pageTitle={"Time Allocated Work"} />
        <div
          style={{
            overflow: "scroll",
            flex: 1,
          }}
        >
          {/* <div style={{ padding: 10 }}>Project ID: {id}</div> */}
          <Table dataSource={projectTimeentries} columns={columns}  pagination={false}/>
          
        </div>
      </div>
    </div>
  );
}
