import { Input, Button, Drawer, Select } from "antd";
import { useEffect, useState } from "react";
import { get, put } from "../../../../api/request";

export default function OtherEstimation({ estimate }) {
  const [data, setData] = useState([]);
  const [discriptions, setDiscriptions] = useState([]);
  const [notes, setNotes] = useState([]);
  async function loadOtherEstimation() {
    try {
      const res = await get(`estimate/${estimate?.id}/secondary-estimation`);
      setData(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadDiscription() {
    try {
      const res = await get(
        `secondary-estimation-activity?limit=1000`
        // `secondary-estimation-activity?searchMap=%7B%22parent_activity_id%22%3Anull%7D`
      );
      //   setData(res.data);
      console.log("res----------->>>", res);
      setDiscriptions(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadNotes(did) {
    try {
      const res = await get(
        `secondary-estimation-activity?searchMap=%7B%22parent_activity_id%22%3A${did}%7D`
      );
      //   setData(res.data);
      console.log("res----------->>>Notes", res);
      setNotes(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    loadOtherEstimation();
    loadDiscription();
    // loadNotes(6);
  }, [estimate]);
  return (
    <div style={{ flex: 1 }}>
      <div style={{ background: "#aaa", padding: 15 }}>
        <Button
          //   size="small"
          style={{ marginBottom: 10 }}
          type="primary"
          onClick={(e) => {
            setData([...data, {}]);
          }}
        >
          Add Item
        </Button>

        <table
          style={{
            //   background: "#fff",
            width: "100%",
          }}
        >
          <tr
            style={{
              fontSize: 12,
              background: "#eee",
            }}
          >
            <td style={{ padding: 5 }}>SL/NO</td>
            <td style={{ padding: 5 }}>DESCRIPTION</td>
            <td style={{ padding: 5 }}>NOTE </td>
            <td style={{ padding: 5 }}>ENTER TOTAL ESTIMATE IN HOURS </td>

            <td style={{ padding: 5 }}>REMARKS</td>
            <td style={{ padding: 5 }}></td>
          </tr>
          <tbody>
            {data.map((d, i) => (
              <tr
                key={"main" + i}
                style={{
                  background: i % 2 === 0 ? "#fff" : "#f1f1f1",
                  fontSize: 13,
                }}
              >
                <td style={{ padding: 5, textAlign: "center" }}>{i + 1}</td>
                <td style={{ padding: 5 }}>
                  <Select
                    style={{ minWidth: 150, width: "100%" }}
                    defaultValue={d.parent_secondary_estimation_activity?.name}
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].parent_secondary_estimation_activity_id = e;
                        dt[i].secondary_estimation_activity_id = null;
                        dt[i].secondary_estimation_activity = {};
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                  >
                    {discriptions
                      .filter((d) => d.parent_activity_id === null)
                      .map((d, i) => (
                        <option key={"dis" + i} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                  </Select>
                </td>
                <td style={{ padding: 5 }}>
                  {/* {d.secondary_estimation_activity_id} */}
                  <Select
                    style={{ minWidth: 150, width: "100%" }}
                    value={d.secondary_estimation_activity_id}
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].secondary_estimation_activity_id = e;
                        //   dt[i].secondary_estimation_activity = {};
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                  >
                    {discriptions
                      .filter(
                        (dt) =>
                          dt.parent_activity_id ===
                          d.parent_secondary_estimation_activity_id
                      )
                      .map((d, i) => (
                        <option key={"notes" + i} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                  </Select>
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Input
                    value={d.duration_in_hours}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].duration_in_hours = parseInt(e.target.value);
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                  />
                </td>
                <td style={{ padding: 5 }}>
                  <Input
                    value={d.remark}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].remark = e.target.value;
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    onClick={async (e) => {
                      try {
                        const res = await put(
                          `estimate/${estimate?.id}/secondary-estimation`,
                          { secondaryEstimations: data }
                        );
                        console.log("successfully updated");
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    Save
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
