import { Button, Drawer, Input, DatePicker, message } from "antd";
import { useEffect, useState } from "react";
import Project from "../../../../bu/actions/Projects";
import dayjs from "dayjs";
const { TextArea } = Input;
export default function CreateProjectDrawer({
  createProjectDrawer,
  tl,
  projects,
  page,
  loadProjects,
}) {
  const [name, setName] = useState("");
  const [tlSearchKey, setTlSearchKey] = useState("");
  const [selectedTlIndex, setSelectedTlIndex] = useState(null);
  const [selectedTL, setselectedTL] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [tlError, setTlError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [projectDescriptionError, setProjectDescriptionError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    console.log("edit clicked updated", createProjectDrawer.editProjectId);
    let project = projects.filter(
      (d) => d.id === createProjectDrawer.editProjectId
    )[0];
    if (project) {
      setName(project?.name);
      setselectedTL(project?.assignee);
      setStartDate(dayjs(project?.starts_at));
      setEndDate(dayjs(project?.ends_at));
      setProjectDescription(project?.description);

      console.log(project);
    } else {
      clearInputs();
    }
  }, [createProjectDrawer.editProjectId]);

  function validateForm() {
    let hasError = false;
    if (!name) {
      setNameError("Project Name  is mandatory.");
      hasError = true;
    }
    if (!startDate) {
      setStartDateError("Start Date is mandatory.");
      hasError = true;
    }
    if (!endDate) {
      setEndDateError("End Date is mandatory.");
      hasError = true;
    }
    if (!projectDescription) {
      setProjectDescriptionError("Project Description is mandatory.");
      hasError = true;
    }
    console.log(
      "projectDescription",
      projectDescription,
      projectDescription.length
    );

    if (projectDescription.length < 5) {
      setProjectDescriptionError(
        "Project Description should have atleast 5 charectors!"
      );
      hasError = true;
    }
    if (!selectedTL) {
      setTlError("You must select TL for the Project");
      hasError = true;
    }
    if (name.length < 2) {
      setNameError("Project Name should contain atlease 2 character");
      hasError = true;
    }
    const specialCharRegex = /[!@#$%^&()_+"']/;
    if (specialCharRegex.test(name)) {
      setNameError("Values not allowed for Project Name");
      hasError = true;
    }
    // - cmppare start date and end date

    return !hasError;
  }
  function clearInputs() {
    setName("");
    setStartDate("");
    setEndDate("");
    setselectedTL(null);
    setProjectDescription("");
  }
  return (
    <Drawer
      title={createProjectDrawer.editMode ? "Edit Project" : "Create Project"}
      placement="right"
      onClose={(e) => {
        console.log("close call");
        createProjectDrawer.close();
      }}
      style={{
        // border: "1px solid red",
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
      open={createProjectDrawer.isOpen}
    >
      <div
        style={{
          flex: 1,
          height: "99%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {contextHolder}
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 12, marginBottom: 5 }}>Project Name:</div>
            <div>
              <Input
                placeholder="Type Project Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
              />
            </div>
            {nameError && (
              <div
                className="form-error"
                style={{ fontSize: 10, color: "red" }}
              >
                {nameError}
              </div>
            )}
          </div>
          <div>
            <div style={{ fontSize: 12, marginBottom: 5 }}>
              Select Team Lead:
            </div>{" "}
            <div>
              <Input
                placeholder="Type TL Name"
                value={tlSearchKey}
                onChange={(e) => setTlSearchKey(e.target.value)}
              />
            </div>
            <div
              style={{
                height: 150,
                overflowY: "scroll",
                background: "#f1f1f1",
                marginTop: 5,
                padding: "10px 5px",
              }}
            >
              {tl.list
                .filter((d) => {
                  return (
                    d.profile?.first_name
                      .toUpperCase()
                      ?.includes(tlSearchKey?.toUpperCase()) ||
                    d.profile?.last_name
                      ?.toUpperCase()
                      .includes(tlSearchKey?.toUpperCase())
                  );
                })
                .map((d, i) => {
                  return (
                    <div
                      key={"tl" + i}
                      style={{
                        margin: 5,
                        padding: 5,

                        background: selectedTL === d.id ? "#1677ff" : "#fff",
                        color: selectedTL === d.id ? "#fff" : "#333",
                        border: selectedTL === d.id ? "1px solid #ccc" : "",
                        display: "flex",
                        borderRadius: 5,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          fontSize: 12,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {d?.profile?.first_name} {d?.profile?.last_name}
                      </div>
                      <div>
                        <Button
                          size={"small"}
                          style={{
                            fontSize: 10,
                            background: selectedTL === d.id ? "#fff" : "#fff",
                            color: selectedTL === d.id ? "#222" : "#222",
                          }}
                          onClick={(e) => {
                            setselectedTL(d.id);
                            setSelectedTlIndex(i);
                            setTlError("");
                          }}
                        >
                          {selectedTL === d.id ? "selected" : "select"}
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
            {tlError && (
              <div
                className="form-error"
                style={{ fontSize: 10, color: "red" }}
              >
                {tlError}
              </div>
            )}
            {selectedTL && (
              <div
                style={{
                  background: "#eee",
                  padding: 10,
                  fontSize: 12,
                  color: "blue",
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }}>
                  {" "}
                  <span style={{ color: "#333" }}>Selected TL: </span>
                  <span style={{ fontWeight: 600 }}>
                    {
                      tl.list.filter((d) => {
                        return selectedTL === d.id;
                      })[0]?.profile?.first_name
                    }
                  </span>
                </div>
                <Button size={"small"} onClick={(e) => setselectedTL(null)}>
                  clear
                </Button>
              </div>
            )}
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 12, marginBottom: 5 }}>Start Date:</div>
            <div>
              <DatePicker
                onChange={(e) => {}}
                style={{ width: "100%" }}
                value={startDate}
                onChange={(date, dateString) => {
                  setStartDate(date);
                  setStartDateError("");
                }}
              />
            </div>
            {startDateError && (
              <div
                className="form-error"
                style={{ fontSize: 10, color: "red" }}
              >
                {startDateError}
              </div>
            )}
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 12, marginBottom: 5 }}>End Date:</div>
            <div>
              <DatePicker
                onChange={(e) => {}}
                style={{ width: "100%" }}
                value={endDate}
                onChange={(date, dateString) => {
                  setEndDate(date);
                  setEndDateError("");
                }}
              />
            </div>
            {endDateError && (
              <div
                className="form-error"
                style={{ fontSize: 10, color: "red" }}
              >
                {endDateError}
              </div>
            )}
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 12, marginBottom: 5 }}>
              Project Description:
            </div>
            <div>
              <TextArea
                style={{}}
                value={projectDescription}
                onChange={(e) => {
                  setProjectDescription(e.target.value);
                  setProjectDescriptionError("");
                }}
              />
            </div>
            {projectDescriptionError && (
              <div
                className="form-error"
                style={{ fontSize: 10, color: "red" }}
              >
                {projectDescriptionError}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", gap: 10, justifyContent: "right" }}>
          <Button onClick={createProjectDrawer.close}>Cancel</Button>
          <Button
            type={"primary"}
            onClick={async (e) => {
              if (validateForm()) {
                const data = {
                  name: name,
                  description: projectDescription,
                  assignee: Number(selectedTL),
                  starts_at: new Date(startDate.toString()).toISOString(),
                  ends_at: new Date(endDate.toString()).toISOString(),
                };
                if (createProjectDrawer.editMode) {
                  let project = projects.filter(
                    (d) => d.id === createProjectDrawer.editProjectId
                  )[0];
                  try {
                    console.log("edit", project);
                    await Project.update(project.id, data);
                    page.change(0);
                    loadProjects(false);
                    alert("project Updated");
                  } catch (error) {
                    console.log(error);
                    alert(error);
                  }
                } else {
                  console.log("create", data);

                  try {
                    await Project.create(data);

                    page.change(0);
                    loadProjects(false);
                    createProjectDrawer.close();
                    clearInputs();

                    messageApi.open({
                      type: "success",
                      content: "Project Created",
                    });
                  } catch (error) {
                    console.log(error.response?.data);

                    messageApi.open({
                      type: "error",
                      content: error.response?.data,
                    });
                  }
                }
              } else {
                messageApi.open({
                  type: "error",
                  content: "Need to clear all the errors",
                });
                console.log("Has Error");
              }
              //createProjectDrawer.createProject({});
            }}
          >
            {createProjectDrawer.editMode ? "Edit " : "Create "}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
