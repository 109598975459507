import { Button, Input } from "antd";
import { usePo } from "./Cor.context";

function CustomerSidebar() {
  const { drawerService, searchKey, setSerachKey } = usePo();
  return (
    <div style={{ width: 200, background: "#fff", padding: 10 }}>
      <Button
        style={{ width: "100%" }}
        type="primary"
        onClick={(e) => drawerService.openToCreate()}
      >
        Create COR
      </Button>
      <div style={{ marginTop: 10 }}>
        <Input
          value={searchKey}
          onChange={(e) => setSerachKey(e.target.value)}
          placeholder={"search"}
        />
      </div>
    </div>
  );
}

export default CustomerSidebar;
