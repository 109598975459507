import React, { useState } from 'react';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import loginLogo from '../../../../assets/loginicon.png';
import { useEffect } from "react";
import { getAuthTokenFromLocalStorage, setAuthTokenFromLocalStorage } from "../../../../bu/lib/auth";
import { Button, Input, message } from "antd";
import { useForm } from "react-hook-form";
import { login } from '../../../../bu/actions/login'
import { useLocation, useNavigate } from 'react-router-dom';
import { decodeToken } from "react-jwt";
import './index.css';
import { useAppState } from "../../../../state"
export default function Login({ messageApi }) {
  const [state, setState] = useAppState()
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [values, setValues] = useState({
    name: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  function validateInput(value, name) {
    if (value.trim() === '') {
      return 'This field is required.';
    } else if (name === 'name' && !/^[A-Za-z0-9]+$/.test(value)) {
      return 'Only characters and numbers are allowed.';
    }
    return '';
  }



  function handleChange({ target }) {
    const { value, name } = target;

    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      ['name', 'password'].forEach((field) => {
        const errorMessage = validateInput(values[field], field);
        setErrors((errors) => {
          return {
            ...errors,
            [field]: errorMessage,
          }
        });
      })
      if (errors.name || errors.password)

        return
      const user = await login({ employee_id: values.name, password: values.password })

      const empi_id = values.name; // Assuming values.name is the employee ID


      localStorage.setItem('employeeid', empi_id);



      if ('token' in user) {
        const decodedToken = decodeToken(user?.token)
        decodedToken.accessibleEntities = decodedToken.permissions.map(permission => permission.split('.')[0])
        console.log("login_data =====>", decodedToken)
        setState((prevState) => { return { ...prevState, user: decodedToken } })
        setAuthTokenFromLocalStorage(user.token)
        const queryParams = new URLSearchParams(location.search);
        const redirect = queryParams.get('redirect');
        navigate(redirect || '/')
      }


      messageApi.open({
        type: 'success',
        content: 'Logged in successfully',
      });
      navigate('/dashboard')
    } catch (error) {
      if (error?.response?.status >= 400) {
        console.log("82", error)
        messageApi.open({
          type: 'error',
          content: error?.response?.data?.error || 'Login failed'
        });
      }
    }

  };

  return (
    <>
      {/* {contextHolder} */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
      >
        <div className="usica-newlogin-section">
          <div className="usica-newlogin-header">
            <div className="usica-newlogin-logo">
              <img src={loginLogo} alt="" />
            </div>
            <h2 className="usica-newlogin-title">USICA Teknoservices</h2>
          </div>
          <div className="usica-newlogin-form-container">
            <div className="usica-newlogin-form">
              <form
                onSubmit={handleSubmit}
                onSuccess={() => {
                  console.log("Your application is updated.");
                }}
                onError={() => {
                  console.log("Submission has failed.");
                }}
              >

                <div>
                  <h2 className="usica-newlogin-form-title">Login</h2>
                  <p className="usica-newlogin-form-paragraph">
                    Welcome back! Please login to your account
                  </p>
                </div>

                <div>
                  <div className="usica-newlogin-form-username">
                    <Input
                      size="large"
                      placeholder="EMPLOYEE ID"
                      prefix={<UserOutlined />}
                      value={values.name}
                      name="name"
                      onChange={handleChange}
                      className="usica-newlogin-input-username"
                    />
                  </div>
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "13px", marginTop: '4px' }}>
                      {errors.name}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="PASSWORD"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className="usica-newlogin-input-password"
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.password && (
                    <p style={{ color: "red", fontSize: "13px", marginTop: '4px' }}>
                      {errors.password}
                    </p>
                  )}

                  {/* <div className="usica-newlogin-forgot-password-container">
                    <span className="usica-newlogin-forgot-password">
                      Forgot Password?
                    </span>
                  </div> */}
                </div>

                <div>
                  <Button
                    className="usica-newlogin-button"
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    LOGIN
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
