import { usePo } from './Po.context';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
export default function CustomersList() {
  const { POs, drawerService } = usePo();
  const columns = [
    {
      // title: "Customer Name",
      title: <span style={{ fontSize: 10, color: '$555' }}>Customer Name</span>,
      dataIndex: 'customerName',
      key: 'customerName',
      render: (text, row) => {
        return <span>{row?.quote?.customer?.name || '-'}</span>;
      },
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Quote No</span>,
      dataIndex: 'quoteNo',
      key: 'quoteNo',
      render: (text, row) => {
        return <span>{row?.quote?.seq_number || '-'}</span>;
      },
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> PO Number</span>,
      dataIndex: 'po_number',
      key: 'po_number',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>PO Date</span>,
      dataIndex: 'poDate',
      key: 'poDate',
      render: (text) => dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Detailing</span>,
      dataIndex: 'detailing_amount',
      key: 'detailing_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Design</span>,
      dataIndex: 'design_amount',
      key: 'design_amount',
    },
    {
      title: (
        <span style={{ fontSize: 10, color: '$555' }}> Design Vendor</span>
      ),
      dataIndex: 'design_vendor_amount',
      key: 'design_vendor_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>C to Usica</span>,
      dataIndex: 'c_to_usica',
      key: 'c_to_usica',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>C to Client</span>,
      dataIndex: 'c_to_client',
      key: 'c_to_client',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Total PO Amount</span>,
      dataIndex: 'revised_po_amount',
      key: 'revised_po_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>Actions</span>,
      dataIndex: 'poDate',
      key: 'poDate',
      render: (text, d) => (
        <div style={{ display: 'flex' }}>
          <Button
            style={{ fontSize: 12 }}
            size="small"
            onClick={(e) => {
              console.log(d.id, d);
              drawerService.openToEdit(d.id, {
                ...d,
                po_date: dayjs(d.po_date),
                customerName: d.quote.customer.id,
                quote_id: d.quote.id,
              });
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ flex: 1, overflow: 'scroll' }}>
      <Table
        dataSource={POs?.data || []}
        columns={columns}
        pagination={false} // Set to false if pagination is not needed
        bordered
        style={{ width: '100%', padding: '20px' }}
        className="custom-table"
        rowKey="id" // Assuming 'poNumber' is unique
      />
    </div>
  );
}
