
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './CreateEmployeeRate.css';
import { Button, Slider, } from "antd";
import { Form } from "antd";
import Rate from "../../bu/actions/Rating";

const CreateEmployeeRate = ({ messageApi, onCloseRate, userId }) => {
    console.log(userId)
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const initialFormData = {
        "efficiency": { title: "Efficiency", value: 0, errorMessage: "Efficiency is required", backgroundColor: '#FF5353' },
        "quality": { title: "Quality", value: 0, errorMessage: "Quality is required", backgroundColor: '#FC845E' },
        "teamPlayer": { title: "Team Player", value: 0, errorMessage: "Team Player is required", backgroundColor: '#58C856' },
        "punctuality": { title: "Punctuality", value: 0, errorMessage: "Punctuality is required", backgroundColor: '#5479FD' },
        "trainingAndDevelopment": { title: "Training and Development", value: 0, errorMessage: "Training and Development is required", backgroundColor: '#8A5098' }
    };

    const [formData, setFormData] = useState(initialFormData);
    const [averageRating, setAverageRating] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const calculateAverage = () => {
        const total = Object.values(formData).reduce((acc, item) => acc + item.value, 0);
        const averagePercentage = (total / Object.keys(formData).length).toFixed(2);
        const rating = getRating(averagePercentage);
        setAverageRating(rating);
        return averagePercentage;
    };

    const getRating = (averagePercentage) => {
        if (averagePercentage >= 90) {
            return "Excellent";
        } else if (averagePercentage >= 80) {
            return "Very Good";
        } else if (averagePercentage >= 70) {
            return "Good";
        } else if (averagePercentage >= 60) {
            return "Fair";
        } else if (averagePercentage >= 50) {
            return "Needs Improvement";
        } else {
            return "Poor";
        }
    };

    const handleCreateRate = async () => {
        try {
            await Rate.create({
                efficiency: formData.efficiency.value,
                quality: formData.quality.value,
                teamPlayer: formData.teamPlayer.value,
                punctuality: formData.punctuality.value,
                trainingAndDev: formData.trainingAndDevelopment.value,
                user_id: userId
            })
            onCloseRate();
            messageApi.open({
                type: 'success',
                content: 'Rate successfully created',
            });
            navigate(`/profile/${userId}?tab=ratings`)
        } catch (error) {
            console.log(error)
            messageApi.open({
                type: 'error',
                content: error?.response?.data || 'Error creating rate',
            });
        }

    };
    useEffect(() => {
        resetForm();
    }, [onCloseRate]);

    const resetForm = () => {
        form.resetFields();
        setFormData(initialFormData);
        setAverageRating("");
    };

    return (
        <div>
            <Form
                name="rateForm"
                className="rate_form"
                autoComplete="off"
                form={form}
                requiredMark="optional"
                onSubmit={(d) => {
                    console.log(d)
                }}
            >


                {Object.keys(formData).map((d, i) => {
                    return <div style={{ display: "flex", paddingBottom: 25 }}>
                        <InputController
                            name={d} title={formData[d].title} value={formData[d].value}
                            errorMessage={formData[d].errorMessage}
                            backgroundColor={formData[d].backgroundColor}
                            changeHandler={e => {
                                let _t = { ...formData }
                                _t[d].value = e
                                setFormData(_t)
                                calculateAverage();
                            }} /></div>
                })}
                <div style={{ display: "flex", paddingBottom: 40, paddingInline: 70, gap: 30 }}>
                    <div>Average Performance:</div>
                    <div>{averageRating}</div>
                </div>

                <div style={{ display: "flex", paddingInline: 70 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleCreateRate}
                        style={{ width: 114.607, height: 39.36, borderRadius: 2, }}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default CreateEmployeeRate;

const InputController = ({ name, title, value, changeHandler, errorMessage, backgroundColor }) => {
    return <div style={{ display: "flex", alignItems: "baseline", flex: 1, marginLeft: 10, justifyContent: "center", gap: 15 }}>
        <Form.Item
            label={<span style={{ minWidth: 170, textAlign: "left" }} >{title}</span>}
            name={name}
            rules={[
                {
                    required: true,
                    message: errorMessage,
                },
            ]}>

            <Slider
                style={{ width: "530px", marginLeft: 1 }}
                trackStyle={{ backgroundColor }}
                value={value}
                onChange={changeHandler}
            />
        </Form.Item>
        <div style={{ border: '2px solid #c5b5b5', width: 50, height: 26, borderRadius: 5, textAlign: 'center' }}>{value}%</div>
    </div>
}
