export default function objectToUrlParams(object) {
    let urlParams = '';

    for (const [key, value] of Object.entries(object)) {
        urlParams += `${key}=${encodeURIComponent(value)}&`;
    }

    // Remove the trailing ampersand (&).
    urlParams = urlParams.slice(0, -1);

    return urlParams;
}