import { get, post, patch, del, put } from "../../api/request";
import objectToUrlParams from "../../utils/objectToUrlParams";
class Project {
  static #baseUrl = "/project";
  static async create(data) {
    return await post(this.#baseUrl, data);
  }
  static async update(projectId, data) {
    return await put(`${this.#baseUrl}/${projectId}`, data);
  }
  static async delete(projectId) {
    return await del(`${this.#baseUrl}/${projectId}`);
  }
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params);
    return await get(this.#baseUrl + "?" + urlParams);
    // return await get(this.#baseUrl)
  }
  static async readOne(filter) {
    console.log(filter);
  }

  static async workAllocate(data) {
    return await post(`/allocatedwork`, {
      ...data,
    });
  }
  static async getWorkAllocate(projectId, userId) {
    console.log("calling");
    return await get(`/allocatedwork`, {
      searchMap: JSON.stringify({
        subquery: {
          AND: [{ user_id: { in: [userId] } }, { project_id: projectId }],
        },
      }),
    });
  }

  static async allocateWork(projectId, data) {
    const workToAllocate = data;
    const keysToDelete = [
      "uid",
      "key",
      "employeeName",
      "startDate",
      "endDate",
      "workDurationStartDate",
      "workDurationEndDate",
      "startTime",
      "endTime",
      "domain",
      "subActivity",
      "errors",
      "hasErrors",
    ];
    workToAllocate.forEach((eachEntry) => {
      keysToDelete.forEach((key) => delete eachEntry[key]);
    });
    return await post(`${this.#baseUrl}/${projectId}/allocate-work`, {
      workToAllocate,
    });
  }
}

export default Project;
