import { get } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';

class Profile {
  static #baseUrl = '/profile'

  static async create() {}

  static async update() {}

  static async delete() {}
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params)
    return await get(this.#baseUrl + '?' + urlParams)
    // return await get(this.#baseUrl)
  }

  static async readOne(filter) {}
}

export default Profile;