import React, { useState, useEffect } from "react";
import { Select, Table, Space, DatePicker, TimePicker, InputNumber, Input, Button } from "antd";
import "./AllocateWork.css";
import { MinusCircleOutlined } from '@ant-design/icons';
import Projects from '../../bu/actions/Projects';
import UserProject from '../../bu/actions/UserProject';
import Domain from '../../bu/actions/Domain';
import Activities from '../../bu/actions/Activities';
import dayjs from 'dayjs';
import generateUniqueId from '../../utils/generateUniqueId';
import ProjectOptionsSelectionModal from "../inputComponents/projectOptions/modal";
const { RangePicker } = DatePicker;

const AllocateWork = ({ messageApi, onWorkAllocationCloseDrawer, emitter, openAllocateWorkDrawer }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedSubActivity, setSelectedSubActivity] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [activityOptions, setActivityOptions] = useState([]);
    const [subactivityOptions, setSubActivityOptions] = useState({ main: [] });
    const [selectedProjectEmployees, setSelectedProjectEmployees] = useState([]);
    const [submitWorkAllocation, setSubmitWorkAllocation] = useState(null);
    const [doesFormHaveError, setDoesFormHaveError] = useState(false);
    const [projectOptionsModalOpen, setProjectOptionsModalOpen] = useState(false);

    const updatedColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
            width: "180px",
        },
        {
            title: 'Domain Name',
            dataIndex: 'domain',
            key: 'domain',
            render: (_, record) => (
                <>
                    <Select
                        placeholder="Select domain name"
                        value={record.domain || undefined}
                        onChange={(value) => handleDomainChange(value, record.key)}
                        options={domainOptions}
                    ></Select>
                    <p style={{ color: 'red' }}>{record.errors?.domain}</p>
                </>
            ),
        },
        {
            title: 'Activity Name',
            dataIndex: 'activity',
            key: 'activity',
            render: (_, record) => (
                <>
                    <Select
                        placeholder="Select activity name"
                        value={record.activity || undefined}
                        onChange={(value) => { console.log(59); handleActivityChange(value, record.key, record.uid) }}
                        options={activityOptions}
                        popupMatchSelectWidth={true}
                    />
                    <p style={{ color: 'red' }}>{record.errors?.activity}</p>
                </>
            ),
        },
        {
            title: 'SubActivity Name',
            dataIndex: 'subActivity',
            key: 'subActivity',
            render: (_, record) => (
                <>
                    <Select
                        placeholder="Select subactivity name"
                        value={record.subActivity || undefined}
                        onChange={(value) => handleSubActivityChange(value, record.key)}
                        options={subactivityOptions[record.uid]}
                        popupMatchSelectWidth={true}
                        notFoundContent={"No subactivities present"}
                    />
                    <p style={{ color: 'red' }}>{record.errors?.subActivity}</p>
                </>
            ),
        },
        {
            title: 'Start Date - End Date',
            dataIndex: 'dateRange',
            key: 'dateRange',
            render: (_, record) => (
                <>
                    <RangePicker
                        disabledDate={(current) => disabledDates(current, record.workDurationStartDate, record.workDurationEndDate)}
                        value={[record.startDate, record.endDate]}
                        onChange={(dates) => {
                            handleDateRangeChange(dates, record.key);
                        }}
                    /><br />
                    Work Duration: {record?.workDurationStartDate?.toISOString().split('T')[0]} / {record?.workDurationEndDate?.toISOString().split('T')[0]}
                    <p style={{ color: 'red' }}>{record?.errors?.start_date || record?.errors?.end_date}</p>
                </>

            ),
        },
        {
            title: 'Duration (in hours)',
            dataIndex: 'duration',
            key: 'duration',
            render: (_, record) => (
                <>
                    <Input
                        placeholder="Hours"
                        value={record.duration}
                        onChange={(e) => handleDurationChange(e.target.value, record.key)}
                    />
                    <p style={{ color: 'red' }}>{record.errors?.duration}</p>
                </>
            ),
        },
        {
            title: 'Start Time - End Time',
            dataIndex: 'timeRange',
            key: 'timeRange',
            render: (_, record) => (
                <>
                    <TimePicker.RangePicker
                        use12Hours
                        value={[record.startTime, record.endTime]}
                        onChange={(times) => {
                            handleTimeRangeChange(times, record.key);
                        }}
                        format="h:mm A"
                    /><br />
                    <p style={{ color: 'red' }}>{record.errors?.start_time || record.errors?.end_time}</p>
                </>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (_, record) => (
                <>
                    <InputNumber
                        placeholder="Quantity"
                        value={record.quantity}
                        onChange={(value) => handleQuantityChange(value < 0 ? 0 : value, record.key)}
                        min={0}
                    />
                    <p style={{ color: 'red' }}>{record.errors?.quantity}</p>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => (
                <Space size="middle">
                    <MinusCircleOutlined onClick={() => handleDeleteRow(record.key)} />
                </Space>
            ),
        },
    ];

    const disabledDates = (current, workDurationStartDate, workDurationEndDate) => {
        // Disable dates before 2023-01-01 and after 2023-12-31
        return current && (current < dayjs(workDurationStartDate) || current > dayjs(workDurationEndDate));
    };

    const mapDataToSelectOptions = (response, hasSubActivities) => {
        const { data = [] } = response
        if (hasSubActivities) {
            return data.map(eachEntry => ({
                label: eachEntry.name,
                value: eachEntry.id,
                hasSubActivities: Boolean(eachEntry.sub_activities?.length)
            }));
        }
        return data.map(eachEntry => ({
            label: eachEntry.name,
            value: eachEntry.id
        }));
    }
    useEffect(() => {
        const getDataFromModel = async (modelDetails) => {
            try {
                const response = await modelDetails[0].readAll(modelDetails[3]);
                const modifiedResult = mapDataToSelectOptions(response, modelDetails[2], modelDetails[4])
                modelDetails[1](modifiedResult || []);
            } catch (e) {
                console.error("Error fetching project data:", e);
            }
        };

        for (let modelDetails of
            [[Domain, setDomainOptions],
            [Activities, setActivityOptions, true, { searchMap: JSON.stringify({ 'parent_activity_id': null }) }]]) {
            getDataFromModel(modelDetails);
        }
    }, []);


    useEffect(() => {
        if (selectedProject?.id) handleProjectSelect(selectedProject?.id)
    }, [selectedProject])
    const handleProjectSelect = async (value) => {
        try {
            const userProjects = await UserProject.readOne(value)
            const modifiedUsers = userProjects?.map(userProject => ({
                user_id: userProject.user_id,
                startDate: userProject.start_date,
                endDate: userProject.end_date,
                label: `${userProject.user.profile.first_name} ${userProject.user.profile.last_name}`,
                value: `${userProject.user.profile.first_name} ${userProject.user.profile.last_name}`
            }));
            const updatedTeamMembers = modifiedUsers.map((user, index) => ({
                uid: generateUniqueId(),
                project_id: value,
                user_id: user.user_id,
                key: index.toString(),
                employeeName: user.label,
                // Add other properties as needed
                startDate: dayjs(user.startDate),
                endDate: dayjs(user.endDate),
                workDurationStartDate: dayjs(user.startDate),
                workDurationEndDate: dayjs(user.endDate),
                start_date: dayjs(user.startDate).toISOString(),
                end_date: dayjs(user.endDate).toISOString(),
                startTime: "",
                endTime: "",
                duration: "",
                quantity: "",
            }));
            setSelectedProjectEmployees(updatedTeamMembers);
            setSubActivityOptions((prevVal) => {
                const updatedSubActivityOptions = {}
                updatedTeamMembers.forEach(eachTeammemberRecord => {
                    updatedSubActivityOptions[eachTeammemberRecord.uid] = prevVal.main
                })
                return { ...prevVal, ...updatedSubActivityOptions }
            })
        } catch (e) {
            console.error("Error fetching employee data:", e);
        }

    };

    useEffect(() => {
        if (selectedDomain) {
            for (const projectEmployee of selectedProjectEmployees) {
                handleDomainChange(selectedDomain, projectEmployee.key)
            }
        }
        if (selectedSubActivity) {
            for (const projectEmployee of selectedProjectEmployees) {
                handleSubActivityChange(selectedSubActivity, projectEmployee.key)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDomain, selectedActivity, selectedSubActivity])

    const clearInputs = () => {
        setSelectedProjectEmployees(() => []);
        setSelectedProject(null)
        setSelectedDomain(null)
        setSelectedSubActivity(null)
        setSelectedActivity(null)
    }
    useEffect(() => {
        // Listen for the custom event
        emitter.on('allocate-work', () => setSubmitWorkAllocation(true));
        emitter.on('clear-allocate-work-form', () => {
            clearInputs()
        });

        return () => {
            // Remove the event listener when the component unmounts
            emitter.off('create-activity', () => setSubmitWorkAllocation(true));
        };
    }, [emitter]);

    function validateInputs(data) {
        const workToAllocate = { ...data };
        const errors = {}
        if (typeof data.activity_id !== 'number' || !Number.isInteger(data.activity_id)) {
            return { ...workToAllocate, errors, hasErrors: false };
        }
        errors.activity = typeof data.activity_id !== 'number' || !Number.isInteger(data.activity_id) ? 'Please select activity.' : '';
        errors.domain = typeof data.domain_id !== 'number' || !Number.isInteger(data.domain_id) ? 'Please select domain.' : '';
        errors.duration = typeof data.duration !== 'number' || isNaN(data.duration) ? 'Please provide duration.' : '';
        errors.end_date = !isValidISO8601(data.end_date) ? 'Please select end date.' : '';
        errors.start_date = !isValidISO8601(data.start_date) ? 'Please select start date.' : '';
        errors.start_date = dayjs(data.start_date).isBefore(data.workDurationStartDate) ? 'Start Date cannot be lesser than work start date' : '';
        errors.start_date = dayjs(data.end_date).isAfter(data.workDurationEndDate) ? 'End Date cannot be greater than work end date' : '';
        errors.end_time = !isValidISO8601(data.end_time) ? 'Please select end time.' : '';
        errors.start_time = !isValidISO8601(data.start_time) ? 'Please select start time.' : '';
        errors.project = typeof data.project_id !== 'number' || !Number.isInteger(data.project_id) ? 'Select a project' : '';
        errors.quantity = typeof data.quantity !== 'number' || !Number.isInteger(data.quantity) ? 'Enter quantity' : '';
        errors.user = typeof data.user_id !== 'number' || !Number.isInteger(data.user_id) ? 'user_id must be an integer.' : '';
        errors.subActivity = typeof data.activity_id === 'number' && typeof data.subActivity !== 'number'
            && activityOptions.find(activity => activity.value === data.activity_id)?.hasSubActivities ?
            'Please select a sub-activity' : ''
        const hasErrors = Object.values(errors).some((error) => error !== '');

        return { ...workToAllocate, errors, hasErrors };
    }

    function isValidISO8601(dateString) {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/;
        return iso8601Regex.test(dateString);
    }

    useEffect(() => {
        const updateWorkAllocation = async () => {
            try {
                if (!selectedProject?.id) {
                    messageApi.open({
                        type: 'error',
                        content: 'Must select a project',
                    });
                    return
                }
                if (!selectedProjectEmployees.length || !selectedProjectEmployees.some(eachWorkAllocated => eachWorkAllocated.activity)) {
                    messageApi.open({
                        type: 'error',
                        content: 'Must allocate work for at least one employee',
                    });
                    return
                }
                const validatedSelectedProjectEmployees = selectedProjectEmployees.map(workAllocation => validateInputs(workAllocation))
                setSelectedProjectEmployees(prevVal => validatedSelectedProjectEmployees)
                if (validatedSelectedProjectEmployees.some(workAllocaton => workAllocaton.hasErrors)) return;
                const allocatedWorkProjectEmployees = selectedProjectEmployees.filter(employees => employees.activity_id)
                await Projects.allocateWork(selectedProject?.id, allocatedWorkProjectEmployees)
                messageApi.open({
                    type: 'success',
                    content: 'Work is successfully allocated',
                });
                clearInputs()
                onWorkAllocationCloseDrawer()
            } catch (error) {
                console.log(error)
                messageApi.open({
                    type: 'error',
                    content: error?.response?.data?.error || 'Error allocating work. Contact Admin',
                });
            } finally {
                setSubmitWorkAllocation(false)
                setDoesFormHaveError(false)
            }
        }
        if (submitWorkAllocation)
            updateWorkAllocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitWorkAllocation]);


    const handleDomainChange = (value, key) => {
        setSelectedProjectEmployees((prevEmployeeState) => prevEmployeeState.map((item) =>
            item.key === key ? { ...item, domain: value, domain_id: value } : item
        ));
    }

    const handleActivityChange = async (value, key, uid) => {
        await fetchSubActivityOnActivityChange(value, uid)
        setSelectedProjectEmployees((prevEmployeeState) => prevEmployeeState.map((item) =>
            item.key === key ? { ...item, activity: value, subActivity: null, activity_id: value } : item
        ));

    }
    const handleSubActivityChange = (value, key) => {
        setSelectedProjectEmployees((prevEmployeeState) => prevEmployeeState.map((item) =>
            item.key === key ? { ...item, subActivity: value, activity_id: value } : item
        ));
    };
    const handleDateRangeChange = (dates, key) => {
        const updatedTeamMembers = selectedProjectEmployees.map((item) =>
            item.key === key ? {
                ...item,
                startDate: dates?.[0] || null,
                endDate: dates?.[1] || null,
                start_date: dates?.[0]?.toISOString(),
                end_date: dates?.[0]?.toISOString(),

            } : item
        );
        setSelectedProjectEmployees(updatedTeamMembers);
    };
    const handleTimeRangeChange = (times, key) => {

        const updatedTeamMembers = selectedProjectEmployees.map((item) =>
            item.key === key ? {
                ...item,
                startTime: times?.[0] || null,
                endTime: times?.[1] || null,
                start_time: times?.[0]?.toISOString(),
                end_time: times?.[0]?.toISOString(),
                duration: times?.[0] && times?.[1] ?
                    Number(Number(dayjs(times?.[1]).diff(dayjs(times?.[0]), 'hour', true), 2).toFixed(2)) :
                    item.duration
            } : item
        );
        setSelectedProjectEmployees(updatedTeamMembers);
    };
    const handleDurationChange = (value, key) => {
        const numberRegex = /^\d|[1][0-6]\.?\d{0,2}$/;
        // Check if the input matches the number regex
        if (numberRegex.test(value) || value === '') {
            value = parseInt(value) > 23 ? 23 : value
            const defaultStartTime = dayjs().hour(9).minute(0).second(0).millisecond(0)
            const updatedTeamMembers = selectedProjectEmployees.map((item) => {
                const startTimeVal = dayjs(item.start_time ? item.start_time : defaultStartTime)
                const endTimeVal = dayjs(item.start_time ? item.start_time : defaultStartTime).add(value ? value : 0, 'hour')
                return item.key === key ? {
                    ...item,
                    duration: Number(value),
                    startTime: startTimeVal,
                    endTime: endTimeVal,
                    start_time: startTimeVal.toISOString(),
                    end_time: endTimeVal.toISOString(),

                } : item
            }
            );
            setSelectedProjectEmployees(updatedTeamMembers);
        }

    };

    const handleQuantityChange = (value, key) => {
        const updatedTeamMembers = selectedProjectEmployees.map((item) =>
            item.key === key ? { ...item, quantity: value } : item
        );
        setSelectedProjectEmployees(updatedTeamMembers);
    };
    const handleDeleteRow = (key) => {
        const updatedTeamMembers = selectedProjectEmployees.filter((item) => item.key !== key);
        setSelectedProjectEmployees(updatedTeamMembers);
    };

    const fetchSubActivityOnActivityChange = async (value, uid) => {
        const activity = await Activities.readOne(value)
        const modifiedResult = mapDataToSelectOptions({ data: activity?.data.sub_activities })
        if (uid === 'main') {
            setSubActivityOptions((prevVal) => {
                const updatedSubActivityOptions = {}
                Object.keys(prevVal).forEach(eachUID => {
                    updatedSubActivityOptions[eachUID] = modifiedResult
                })
                return updatedSubActivityOptions
            })
            return
        }
        setSubActivityOptions((prevVal) => { return { ...prevVal, [uid]: modifiedResult } })
    }
    const updateSubActivityOnActivityChange = async (value, uid) => {
        await fetchSubActivityOnActivityChange(value, uid)
        if (uid === 'main') {
            for (const projectEmployee of selectedProjectEmployees) {
                handleActivityChange(value, projectEmployee.key)
            }
            setSelectedSubActivity(null)
            setSelectedActivity(value)
        }
    }

    return (
        <>
            <div style={{ display: "flex", margin: "auto 50px", justifyContent: "space-between" }}>

                <div className="allocate-work-section">
                    <div className="allocate-work-custom-div">
                        <label className="allocate-work-label">Project Name</label>
                        <span style={{ maxWidth: '200px', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                            <span style={{
                                maxWidth: '170px', overflow: 'hidden', textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap', display: 'inline-block'
                            }}>
                                {selectedProject?.id && selectedProject?.name}
                            </span>
                            <Button onClick={() => setProjectOptionsModalOpen(true)} style={{ marginLeft: '10px' }}>
                                {selectedProject?.id ? 'Edit' : 'Select Project'}
                            </Button>
                        </span>
                    </div>
                </div>
                <div className="allocate-work-section">
                    <div className="allocate-work-custom-div">
                        <label className="allocate-work-label">Domain</label>
                        <Select
                            className="allocate-work-field"
                            placeholder="Select domain"
                            value={selectedDomain}
                            onChange={(value) => setSelectedDomain(value)}
                            options={domainOptions}
                            disabled={!selectedProject}
                        />
                    </div>
                </div>
                <div className="allocate-work-section">
                    <div className="allocate-work-custom-div">
                        <label className="allocate-work-label">Activity Name</label>
                        <Select
                            className="allocate-work-field"
                            placeholder="Select activity name"
                            value={selectedActivity}
                            onChange={(value) => updateSubActivityOnActivityChange(value, 'main')}
                            options={activityOptions}
                            disabled={!selectedProject}
                        />
                    </div>
                </div>
                <div className="allocate-work-section">
                    <div className="allocate-work-custom-div">
                        <label className="allocate-work-label">SubActivity Name</label>
                        <Select
                            className="allocate-work-field"
                            placeholder="Select subactivity name"
                            value={selectedSubActivity}
                            onChange={(value) => setSelectedSubActivity(value)}
                            options={subactivityOptions['main']}
                            notFoundContent={"No subactivities present"}
                            disabled={!selectedProject}
                        />
                    </div>
                </div>
            </div>
            <div className="allocate-work-section" style={{ margin: "auto 50px" }}>
                <Table
                    dataSource={selectedProjectEmployees}
                    columns={updatedColumns}
                    scroll={{ x: window.innerWidth < 2000 ? 2000 : window.innerWidth }}
                    // scroll={{ x: true }}
                    title={() => (
                        <div style={{ display: "flex", fontSize: '20px', fontWeight: 'bold', marginTop: '15px' }}>
                        </div>
                    )}
                    responsive
                />
            </div>
            <ProjectOptionsSelectionModal projectOptionsModalOpen={projectOptionsModalOpen}
                setProjectOptionsModalOpen={setProjectOptionsModalOpen}
                setSelectedProject={setSelectedProject} projectStatus='ALLOCATED'
                parentOpen={openAllocateWorkDrawer || submitWorkAllocation} />
        </>
    );
};

export default AllocateWork;