import { get, post, patch } from "../../api/request";
import objectToUrlParams from "../../utils/objectToUrlParams";

class User {
  static #baseUrl = "/user";

  static async create(data) {
    return await post(this.#baseUrl, data);
  }

  static async update() {}
  static async updateStatus(id, data) {
    return await patch(`${this.#baseUrl}/${id}/status`, data);
  }
  static async delete() {}
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params);
    return await get(this.#baseUrl + "?" + urlParams);
    // return await get(this.#baseUrl)
  }

  static async readOne(id) {
    return await get(this.#baseUrl + "/" + id);
  }
  static async getAllLeads(limit = 10) {
    const urlParams = objectToUrlParams({
      limit,
      searchMap: JSON.stringify({
        subquery: { profile: { designation_id: { in: [2, 3, 4] } } },
      }),
    });
    return await get(this.#baseUrl + "?" + urlParams);
  }
}

export default User;
