import React, { createContext, useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import PO from '../../../../bu/actions/PO';
import Customer from '../../../../bu/actions/Customer';
import Quote from '../../../../bu/actions/Quote';
import Projects from '../../../../bu/actions/Projects'

const PoContext = createContext();

export const PoProvider = ({ children }) => {
  const [POs, setPOs] = useState([]);
  const [searchKey, setSerachKey] = useState('');
  const [drawerState, setDrawerState] = useState({
    open: false,
    mode: 'create',
    editId: null,
    editObject: null,
  });

  async function fetchCustomers() {
    return await Customer.readAll();
  }

  async function fetchQuotesForCustomers(project_id) {
    const quotes = await Quote.readAll({
      searchMap: JSON.stringify({ project_id: project_id }),
    });
    const _d = { ...drawerState };
    _d.quotes = quotes?.data || [];
    console.log(28, _d);
    setDrawerState(_d);
  }

  async function fetchProjectsForCustomers(customer_id) {
    const projects = await Projects.readAll({
      searchMap: JSON.stringify({ customer_id: customer_id}),
    });
    const _d = { ...drawerState };
    _d.projects = projects?.data || [];
    console.log(28, _d);
    setDrawerState(_d);
  }
  async function openToCreate() {
    const customers = await fetchCustomers();
    const _d = { ...drawerState };
    _d.customers = customers?.data || [];
    _d.open = true;
    _d.editId = null;
    _d.editObject = null;
    _d.mode = 'create';
    setDrawerState(_d);
  }
  async function openToEdit(id, object) {
    const customers = await fetchCustomers();
    console.log(id, 'from tehb open to edit ');
    const _d = { ...drawerState };
    _d.customers = customers?.data || [];
    _d.open = true;
    _d.mode = 'edit';
    _d.editId = id;
    _d.editObject = object;
    setDrawerState(_d);
  }
  function close() {
    const _d = { ...drawerState };
    _d.open = false;
    setDrawerState(_d);
  }

  const loadCustomers = async () => {
    try {
      //bu logic
      const posData = await PO.readAll({
        // skip: page * limit,
        limit: 1000, //pageSize,
        sort: 'created_at',
        sortOrder: 'desc',
      });
      setPOs(posData);
    } catch (e) {}
  };
  useEffect(() => {
    loadCustomers();
  }, []);
  const createPo = async (data) => {
    await PO.create(data);
    loadCustomers();
  };
  const editPo = async (data) => {
    console.log(78, drawerState.editId);
    await PO.update(data, drawerState.editId);
    loadCustomers();
  };

  return (
    <PoContext.Provider
      value={{
        POs,
        searchKey,
        setSerachKey,
        loadCustomers,
        createPo,
        editPo,
        editId: null,
        drawerService: {
          customers: drawerState.customers,
          isOpen: drawerState.open,
          openToCreate,
          close,
          openToEdit,
          mode: drawerState.mode,
          editId: drawerState.editId,
          editObject: drawerState.editObject,
          fetchQuotesForCustomers,
          fetchProjectsForCustomers,
          quotes: drawerState.quotes,
          projects : drawerState.projects
        },
      }}
    >
      {children}
    </PoContext.Provider>
  );
};
PoContext.propTypes = {
  children: propTypes.node.isRequired,
};
export const usePo = () => useContext(PoContext);
