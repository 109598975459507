import React, { createContext, useContext, useEffect, useState } from "react";
import propTypes from "prop-types";
import { get } from "../../../../api/request";

const InvoiceContext = createContext();

export const InvoiceProvider = ({ children }) => {
  const [POs, setPOs] = useState([]);
  const [searchKey, setSerachKey] = useState("");
  const [drawerState, setDrawerState] = useState({
    open: false,
    mode: "create",
    editId: null,
    editObject: null,
  });

  function openToCreate() {
    const _d = { ...drawerState };
    _d.open = true;
    _d.editId = null;
    _d.editObject = null;
    _d.mode = "create";
    setDrawerState(_d);
  }
  function openToEdit(id, object) {
    console.log(id, "from tehb open to edit ");
    const _d = { ...drawerState };
    _d.open = true;
    _d.mode = "edit";
    _d.editId = id;
    _d.editObject = object;
    setDrawerState(_d);
  }
  function close() {
    const _d = { ...drawerState };
    _d.open = false;
    setDrawerState(_d);
  }

  const loadCustomers = async () => {
    try {
      const res = await get("payment?limit=1000");
      console.log(res);
      setPOs(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    loadCustomers();
  }, []);
  const createPo = (data) => {
    setPOs((p) => [...p, data]);
  };
  const editPo = (data) => {
    setPOs((p) => [...p, data]);
  };

  return (
    <InvoiceContext.Provider
      value={{
        POs,
        searchKey,
        setSerachKey,
        loadCustomers,
        createPo,
        editPo,
        editId: null,
        drawerService: {
          isOpen: drawerState.open,
          openToCreate,
          close,
          openToEdit,
          mode: drawerState.mode,
          editId: drawerState.editId,
          editObject: drawerState.editObject,
        },
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
InvoiceContext.propTypes = {
  children: propTypes.node.isRequired,
};
export const usePo = () => useContext(InvoiceContext);
