import { Button, Input } from "antd";

import { useEffect, useState } from "react";
import { put } from "../../../../api/request";
import { estimate, getEstimete, addEstimation } from "./data";
import Estimation from "./Estimation";

export default function Division({ divisionId }) {
  const [estimates, setEstimates] = useState([]);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [selectedEstimateObject, setSelectedEstimateObject] = useState({});
  async function loadEstimate() {
    const res = await getEstimete(divisionId);
    console.log("res---------------------");
    console.log(res);
    setEstimates(res);
  }
  useEffect(() => {
    if (divisionId) loadEstimate();
  }, [divisionId]);

  useEffect(() => {
    console.log(
      "------------>",
      estimates.filter((d) => d.estimate_type === selectedEstimate)
    );
    setSelectedEstimateObject(
      estimates.filter((d) => d.estimate_type === selectedEstimate)[0]
    );
  }, [selectedEstimate]);
  if (divisionId)
    return (
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <div>{JSON.stringify(estimates)}</div> */}
        <div
          style={{ display: "flex", padding: 10, gap: 10, background: "#ccc" }}
        >
          {/* {JSON.stringify(estimates)} */}
          {estimates.filter((d) => d.estimate_type === "MAIN").length === 0 && (
            <div>
              <Button
                onClick={async (e) => {
                  await addEstimation(divisionId, {
                    estimateType: "MAIN",
                  });
                  loadEstimate();
                  setSelectedEstimate("MAIN");
                }}
              >
                Create Main Estimate
              </Button>
            </div>
          )}
          {estimates.filter((d) => d.estimate_type === "MISCELLANEOUS")
            .length === 0 && (
            <div>
              <Button
                onClick={async (e) => {
                  await addEstimation(divisionId, {
                    estimateType: "MISCELLANEOUS",
                  });
                  loadEstimate();
                  setSelectedEstimate("MISCELLANEOUS");
                }}
              >
                Create MISCELLANEOUS Estimate
              </Button>
            </div>
          )}
          {estimates.filter((d) => d.estimate_type === "MAIN").length > 0 && (
            <div>
              <Button
                type={selectedEstimate === "MAIN" ? "primary" : "default"}
                onClick={(e) => setSelectedEstimate("MAIN")}
              >
                MAIN Estimate
              </Button>
            </div>
          )}
          {estimates.filter((d) => d.estimate_type === "MISCELLANEOUS").length >
            0 && (
            <div>
              <Button
                type={
                  selectedEstimate === "MISCELLANEOUS" ? "primary" : "default"
                }
                onClick={(e) => setSelectedEstimate("MISCELLANEOUS")}
              >
                MISCELLANEOUS Estimate
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {!selectedEstimate && (
            <div style={{ textAlign: "center", margin: 30 }}>
              Select Estimation type
            </div>
          )}
          {selectedEstimate && (
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  //   border: "1px solid #ccc",
                  display: "flex",
                }}
              >
                {/* {JSON.stringify(estimates)} */}
                <Estimation
                  divisionId={divisionId}
                  estimationType={selectedEstimate}
                  estimates={estimates.filter(
                    (d) => d.estimate_type === selectedEstimate
                  )}
                />
              </div>
              <div style={{ padding: 20 }}>
                <div style={{ padding: 5, display: "flex" }}>
                Inclusion:
                  <Input
                    value={selectedEstimateObject?.inclusions}
                    onChange={(e) =>
                      setSelectedEstimateObject((pc) => ({
                        ...pc,
                        inclusions: e.target.value,
                      }))
                    }
                  />
                </div>
                <div style={{ padding: 5, display: "flex" }}>
                Exclusion:
                  <Input
                    value={selectedEstimateObject?.exclusions}
                    onChange={(e) =>
                      setSelectedEstimateObject((pc) => ({
                        ...pc,
                        exclusions: e.target.value,
                      }))
                    }
                  />
                </div>
                <Button
                  style={{}}
                  onClick={async (e) => {
                    try {
                      console.log("saving", selectedEstimateObject);
                      const res = await put(`estimate/${selectedEstimateObject.id}`, selectedEstimateObject);
                      // Handle response data as needed
                      alert(res.message); // Show success alert
                      return res.data;
                    } catch (error) {
                      // Handle error
                      console.error("Error while saving:", error);
                      // Optionally, you can throw the error again to propagate it to the outer scope
                      throw error;
                    }
                  }}
                >
                  Save
                </Button>
                <div style={{ padding: 10 }}>
                  {/* Summary */}
                  {/* {JSON.stringify(selectedEstimateObject)} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          textAlign: "center",
          margin: "10px",
        }}
      >
        No division selected Please select one
      </div>
    );
}
