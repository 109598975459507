import React, { createContext, useContext, useEffect, useState } from "react";
import propTypes from "prop-types";
import Customer from "../../../../bu/actions/Customer";
import { get, post } from "../../../../api/request";
import Projects from '../../../../bu/actions/Projects'
const QuotesContext = createContext();

export const QuoteProvider = ({ children }) => {
  const [quotes, setQuotes] = useState([]);
  const [searchKey, setSerachKey] = useState("");
  const [drawerState, setDrawerState] = useState({
    open: false,
    mode: "create",
    editId: null,
  });
  const [estimateDrawerState, setEstimateDrawerState] = useState({
    open: false,
    mode: "create",
    editId: null,
  });

  async function fetchCustomers() {
    return await Customer.readAll();
  }

  async function openToCreate() {
    const customers = await fetchCustomers();
    const _d = { ...drawerState };
    _d.customers = customers?.data || [];
    _d.open = true;
    _d.editId = null;
    _d.editObject = null;
    _d.mode = 'create';
    setDrawerState(_d);
  }
  function openToCreateEstimate(id) {
    console.log(id);

    const _d = { ...estimateDrawerState };
    _d.open = true;
    _d.editId = id;
    _d.mode = "create";
    setEstimateDrawerState(_d);
  }

  function openToEditEstimate(id) {
    const _d = { ...estimateDrawerState };
    _d.open = true;
    _d.mode = "edit";
    _d.editId = id;
    setEstimateDrawerState(_d);
  }
  async function openToEdit(id) {
    const customers = await fetchCustomers();
    const _d = { ...drawerState };
    _d.customers = customers?.data || [];
    _d.open = true;
    _d.mode = "edit";
    _d.editId = id;
    setDrawerState(_d);
  }
  function close() {
    const _d = { ...drawerState };
    _d.open = false;
    setDrawerState(_d);
  }
  function closeEstimate() {
    const _d = { ...drawerState };
    _d.open = false;
    setEstimateDrawerState(_d);
  }

  async function createQuote(quote) {
    console.log(quote);
    // delete quote.mto;
    const res = await post("quote", {
      ...quote,
      due_date: quote.due_date.toISOString(),
      received_date: quote.received_date.toISOString(),
    });
    console.log(res);
    load();
    // setQuotes((pv) => {
    //   return [...pv, quote];
    // });
  }
  const load = async () => {
    try {
      // const cres = await Customer.readAll();
      const res = await get("quote?limit=1000");
      console.log(res);
      setQuotes(res.data);
    } catch (e) {}
  };
  useEffect(() => {
    load();
  }, []);
  async function fetchProjectsForCustomers(customer_id) {
    const projects = await Projects.readAll({
      searchMap: JSON.stringify({ customer_id: customer_id}),
    });
    const _d = { ...drawerState };
    _d.projects = projects?.data || [];
    console.log(28, _d);
    setDrawerState(_d);
  }
  const createCategory = () => {};

  return (
    <QuotesContext.Provider
      value={{
        quotes,
        searchKey,
        setSerachKey,

        load,
        createCategory,
        editId: null,
        drawerService: {
          isOpen: drawerState.open,
          customers: drawerState.customers,
          openToCreate,
          close,
          openToEdit,
          mode: drawerState.mode,
          editId: drawerState.editId,
          createQuote,
          fetchProjectsForCustomers,
          projects : drawerState.projects
        },
        estimateDrawerService: {
          isOpen: estimateDrawerState.open,
          openToCreateEstimate,
          openToEditEstimate,
          close: closeEstimate,
          mode: estimateDrawerState.mode,
          editId: estimateDrawerState.editId,
        },
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};
QuotesContext.propTypes = {
  children: propTypes.node.isRequired,
};
export const useQuote = () => useContext(QuotesContext);
