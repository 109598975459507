import { get, post, put } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams'
class Activities {
  static #baseUrl = '/activities'

  static async create(data) {
    return await post(this.#baseUrl, data)
  }
  static async update(data) {
    console.log("update", this.#baseUrl + '/' + data.id)
    return await put(this.#baseUrl + '/' + data.id, data)
  }
  static async delete() { }
  static async readAll(params = { limit: 30, sort: "name" }) {
    const urlParams = objectToUrlParams(params)
    console.log(1616, urlParams)
    return await get(this.#baseUrl + '?' + urlParams)
  }
  static async readOne(id) {
    return await get(this.#baseUrl + '/' + id)
  }
}

export default Activities;
