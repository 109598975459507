import "../../../../index.css";
import "./index.css";
import { useEffect, useReducer, useState } from "react";
import projectReducer from "./projectReducer";
import ProjectSidebar from "./ProjectSidebar";
import PageHeader from "../../../../components/PageHeader";
import { Button, Drawer, Input } from "antd";
import useProject from "./projectService";
import { json, useNavigate, useParams } from "react-router";
import CreateProjectDrawer from "./CreateProject";
import AllocateWorkDrawer from "./AllocateWork";
import AllocateProjectDrawer from "./AllocateProject";
import dayjs from "dayjs";
import User from "../../../../bu/actions/Users";
import { useAppState } from "../../../../state";
import { get } from "../../../../api/request";
import {Table, Select, DatePicker} from 'antd'
import Designation from "../../../../bu/actions/Designation";
import Domain from "../../../../bu/actions/Domain";
const initialState = {
  projects: [],
};
const { Option } = Select;
const { RangePicker } = DatePicker;

const engineeringDesignations = ['Sr.Engg Modeler',
  'Engg Modeler',
  'Jr.Engg Modeler',
  'Jr. Engg Checker',
  'Sr. Engg Checker',
  'Engg Checker',
  'Sr.Engg Editor',
  'Jr.Engg Editor',
  'Engg Editor',
  'Sr. Checker',
  'Checker',
  'Estimator',
  'Trainee']

export default function ProjectPage() {
  //   [projects, loadProjects, page, projectsLoadingStatus]
  const { id } = useParams();
  const navigation = useNavigate();
  const [timeentr, setTimeEntry] = useState([]);
  const [project, setProject] = useState({});
  const [projectTimeentries, setProjectTimeentries] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  async function loadTimeEntries() {
    // const res = await get("/");
  }

  const [employeesList, setEmployeesList] = useState([]);
  const [domains, setDomains] = useState([]);

  const [selectedProject, setSelectedProject] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);


  function handleFilterChange(filterType, value) {
    console.log(value);
    try {
      if (value !== 'all') {
        switch (filterType) {
          case 'user_id':
            setSelectedTeamMember(value);
            break;
          case 'domain_id':
            setSelectedDomain(value);
            break;
          case 'date_search':
            setSelectedDateRange(value);
            break;
          default:
            break;
        }

        // Construct object containing selected filter values
        const filters = {
          project_id: Number(id),
          user_id: filterType === 'user_id' ? value : selectedTeamMember,
          domain_id: filterType === 'domain_id' ? value : selectedDomain,
        };

        if (filterType === 'date_search') {
          const dateSearch = createDateSearch(value);
          if (dateSearch) {
            filters.dateSearch = dateSearch;
          }
        } else {
          const dateSearch = createDateSearch(selectedDateRange);
          if (dateSearch) {
            filters.dateSearch = dateSearch;
          }
        }

        console.log("filters", filters);
        getData(filters)

      } else {
        // const res2 = await get(
        //   `/timeentry?limit=1000`
        // );
        // setProjectTimeentries(res2.data || []);
      }
    } catch (e) {
      console.log(e);
      setProjectTimeentries([]);
      setSelectedUser(value);
    }
  };

  function createDateSearch(value) {
    if (!value || value.length !== 2) {
      console.error("Invalid range provided.");
      return null;
    }
  
    const [startDate, endDate] = value;
    const start_date = new Date(startDate);
    start_date.setHours(0, 0, 0, 0);
  
    const end_date = new Date(endDate);
    end_date.setHours(23, 59, 59, 999);
  
    const formattedStartDate = new Date(start_date.getTime() - (start_date.getTimezoneOffset() * 60000)).toISOString().slice(0, -5) + "Z";
    const formattedEndDate = new Date(end_date.getTime() - (end_date.getTimezoneOffset() * 60000)).toISOString().slice(0, -5) + "Z";
  
    return ["start_date", formattedStartDate, formattedEndDate];
  }

  async function getData(filters) {
    try {
      const res2 = await get(
        `/timeentry?limit=1000&searchMap=${JSON.stringify(filters)}`
      );
      setProjectTimeentries(res2.data || []);
    } catch (e) {
      console.log(e);
      setProjectTimeentries([]);
    }
  }

  const getDesignations = async () => {
    const designations = await Designation.readAll();
    return designations
      .filter((designation) =>
        engineeringDesignations.includes(designation.name)
      )
      .map((designation) => designation.id);
  };

  const getUsers = async () => {
    try {
      const designationIds = await getDesignations();

      const users = await User.readAll({
        searchMap: JSON.stringify({
          profile: { designation_id: { in: designationIds } },
        }),
        skip: 0,
        limit: 1000,
        markAsTeamMember: true,
      });
      if (users) {
        setEmployeesList(users?.data);
      }
    } catch (e) {
      console.error("Error fetching employee data:", e);
    }
  };


  async function loadProject() {
    try {
      const res = await get(`project/${id}`);
      const res1 = await get(`userproject/${id}?limli=1000`);
      setProjectUsers(res1);
      const res2 = await get(
        `/timeentry?limit=1000&searchMap=%7B%22project_id%22%3A${id}%7D`
      );
      console.log("res===>", res, res1, res2);
      setProject(res);
      setProjectTimeentries(res2.data || []);
      setSelectedUser(0)
    } catch (e) {
      console.log(e);
    }
  }
  useState(() => {
    loadTimeEntries();
  }, []);
  useState(() => {
    if (id) loadProject();
  }, [id]);


  async function loadDomain() {
    try {
      const dRes = await Domain.readAll({ limit: 1000 });
      setDomains(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getUsers()
    loadDomain()
  }, [])

  const columns = [
    {
      // title: 'Name',
      children: [
        {
          title: 'Name',
          dataIndex: ['user','profile','first_name'],
          key: 'first_name',
          render: (text, record) => `${record.user.profile.first_name} ${record.user.profile.last_name}`,
        },
  ]},
  {
    title: 'Allocated Work',
    children: [
      {
        title: 'Domain',
        dataIndex: ['allocated_work', 'domain', 'name'],
        key: 'domain',
        render: (text, record) => {
          return <span style={{ color: 'green' }}>{text}</span>;
        },
      },
      {
        title: 'Activity',
        dataIndex: ['allocated_work', 'activity', 'name'],
        key: 'activity',
        render: (text, record) => {
          return <span style={{ color: 'green' }}>{text}</span>;
        },
      },
      {
        title: 'Quantity',
        dataIndex: ['allocated_work', 'quantity'],
        key: 'quantity',
        render: (text, record) => {
          return <span style={{ color: 'green' }}>{text}</span>;
        },
      },
      {
        title: 'Start Time',
        dataIndex: ['allocated_work', 'start_date'],
        key: 'start_date',
        render: (start_date) => {
          return <span style={{ color: 'green' }}>{dayjs(start_date).format('DD/MM/YYYY h:mm A')}</span>;
        },
      },
      {
        title: 'End Time',
        dataIndex: ['allocated_work', 'end_date'],
        key: 'end_date',
        render: (end_date) => {
          return <span style={{ color: 'green' }}>{dayjs(end_date).format('DD/MM/YYYY h:mm A')}</span>;
        },
      },
      {
        title: 'Duration',
        dataIndex: ['allocated_work', 'duration'],
        key: 'duration',
        render: (text, record) => {
          return <span style={{ color: 'green' }}>{text}</span>;
        },
      },
    ],
  },
    {
      title: 'Actual Work',
      children: [
        {
          title: 'Description',
          dataIndex: ['description'],
          key: 'description',
        },
        {
          title: 'Domain',
          dataIndex: ['domain', 'name'],
          key: 'domain',
        },
        {
          title: 'Activity',
          dataIndex: ['activity', 'name'],
          key: 'activity',
        },
        {
          title: 'Quantity',
          dataIndex: ['quantity'],
          key: 'quantity',
        },
        {
          title: 'Start Time',
          dataIndex: ['start_time'],
          key: 'start_time',
          render: (start_time, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = record?.duration;
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{dayjs(start_time).format('DD/MM/YYYY h:mm A')}</span>;
          },
        },
        {
          title: 'End Time',
          dataIndex: ['end_time'],
          key: 'end_time',
          render: (end_time, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = record?.duration;
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{dayjs(end_time).format('DD/MM/YYYY h:mm A')}</span>;
          },
        },
        {
          title: 'Duration',
          dataIndex: ['duration'],
          key: 'duration',
           width: 'auto',
          render: (duration, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = duration;            
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{duration}</span>;
          },
        },
      ],
    },
  ]
  async function handleUserClick (userId){
    try{
    const res2 = await get(
      `/timeentry?limit=1000&searchMap={"project_id":${id}, "user_id":${userId}}`
    );
    console.log("res===>",res2);
    setProjectTimeentries(res2.data || []);
    setSelectedUser(userId);
    } catch(e){
      console.log(e);
      setProjectTimeentries([])
      setSelectedUser(userId)
    }
  };
  return (
    <div className="page-container">
      {/* <div style={{ width: 300, background: "#fff", padding: 10 }}>
        <div>Project Name:</div>
        <h3> {project?.name}</h3>
        <hr />
        <div>Team members:</div>
        <div>
          <div style={{ border: "1px solid #ccc", margin: 4, padding: 5 , cursor:"pointer",color: selectedUser === 0 ? "blue" : "black"}} onClick={() => loadProject()}>All</div>
          {projectUsers.map((d, i) => (
            <div
              key={"user" + i}
              style={{ border: "1px solid #ccc", margin: 4, padding: 5 ,cursor: "pointer",color: selectedUser === d.user.id ? "blue" : "black"}}
              onClick={() => handleUserClick(d.user.id)}
            >
              {d?.user?.employee_id}: {d?.user?.profile?.first_name}{" "}
              {d?.user?.profile?.last_name}
            </div>
          ))}
        </div>
      </div> */}
      <div
        className="timeentry-body-content"
        style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{ marginBottom: 20, cursor: "pointer", color: "blue" }}
          onClick={(e) => navigation("/projects")}
        >
          {"<-"} go to projects{" "}
        </div>
        <PageHeader pageTitle={"Time Entries"} />
        <div style={{padding:10}}>Project Name: {project?.name}</div>
        <div style={{ display: "flex", padding: "10px", borderBottom: "2px solid #000000" }}>
          <div style={{ margin: '10px' }}> Team Member :
            <Select
              placeholder="Select Team Member"
              onChange={(value) => handleFilterChange('user_id', value)}
              style={{ width: 200 }}
              defaultValue={'All'}
            >
              {/* <Option key="all" value="all">All</Option> */}
              {projectUsers.map(member => (
                <Option key={member?.user?.id} value={member?.user?.id}>
                  {member?.user?.profile?.first_name} {member?.user?.profile?.last_name}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: '10px' }}> Domain :
            <Select
              placeholder="Select Domain"
              onChange={(value) => handleFilterChange('domain_id', value)}
              style={{ width: 200 }}
              defaultValue={'All'}
            >
              {/* <Option key="all" value="all">All</Option> */}
              {domains.map(domain => (
                <Option key={domain?.id} value={domain?.id}>
                  {domain?.name}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: '10px' }}> Actual Work Start Date:
            <RangePicker onChange={(value) => handleFilterChange('date_search', value)} />
          </div>

        </div>
        <div
          style={{
            overflow: "scroll",
            flex: 1,
          }}
        >
          {/* <div style={{ padding: 10 }}>Project ID: {id}</div> */}
            <Table
              columns={columns}
              dataSource={projectTimeentries}
              bordered
              size="middle"
              pagination={false}
              locale={'No data available'}
            />
        </div>
      </div>
    </div>
  );
}
