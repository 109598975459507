import { useState } from "react";
import { message } from "antd";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import AppCore from "./pages/app";
import { get } from "./api/request";
import Login from "./pages/app/public/Login";
import Tasks from "./pages/app/protected/Tasks";
import ProjectsPage from "./pages/app/protected/Projects";
import ProjectsOldPage from "./pages/app/protected/Projects.bkp";
import UsersPage from "./pages/app/protected/Users";
import TimesheetsPage from "./pages/app/protected/TimeSheets";
import ProfilePage from "./pages/app/protected/Profile";
import CreateTask from "./components/creationComponents/CreateTask";
import Employee from "./pages/app/protected/Employee";

import QuotesPage from "./pages/app/protected/quote/Quote";
import { AppProvider } from "./state";
import RolePage from "./pages/app/protected/Roles";
import AllocateWorkPage from "./pages/app/protected/AllocateWork";
import ActivityPage from "./pages/app/protected/Activity";
import mitt from "mitt";
import CustomersPage from "./pages/app/protected/customers/Customers";
import PoPage from "./pages/app/protected/po/Po";
import InvoicePage from "./pages/app/protected/invoice/Invoice";
import PaymentsPage from "./pages/app/protected/payments/Payments";
import CorPage from "./pages/app/protected/cor/Cor";
import ProjectPage from "./pages/app/protected/Project";
import AllocatedWorkList from "./pages/app/protected/Project/AllocatedWorkList";
import QuoteDetails from "./pages/app/protected/quoteDetails/OuoteDetails";
import InvoiceDetails from "./pages/app/protected/invoice/InvoiceDetails";
import DashboardPage from "./pages/app/protected/Dashboard";
import AllTimeEntries from "./pages/app/protected/Project/AllTimeEntries"

const emitter = mitt();

export default function App() {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div>
      {contextHolder}
      <BrowserRouter>
        <AppProvider>
          <Routes>
            <Route
              path="/"
              element={<AppCore messageApi={messageApi} emitter={emitter} />}
            >
              <Route
                index
                element={<Home messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="about"
                element={<About messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="dashboard"
                element={
                  <DashboardPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="tasks"
                element={<Tasks messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="activities"
                element={
                  <ActivityPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="projects"
                element={
                  <ProjectsPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="project/timeentries/:id"
                element={
                  <ProjectPage messageApi={messageApi} emitter={emitter} />
                }
              />
               <Route
                path="all-time-entries"
                element={
                  <AllTimeEntries messageApi={messageApi} emitter={emitter} />
                }
              />
               <Route
                path="project/work-allocation/:id"
                element={
                  <AllocatedWorkList messageApi={messageApi} emitter={emitter}  title ={'Projects'}/>
                }
              />
              <Route
                path="dashboard/work-allocation/:id"
                element={
                  <AllocatedWorkList messageApi={messageApi} emitter={emitter} title ={'Dashboard'}/>
                }
              />
              <Route
                path="projects-old"
                element={
                  <ProjectsOldPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="quotes"
                element={
                  <QuotesPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="quotes/:id"
                element={
                  <QuoteDetails messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="users"
                element={
                  <UsersPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="timesheets"
                element={
                  <TimesheetsPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="customers"
                element={
                  <CustomersPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="profile/:id"
                element={
                  <ProfilePage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="employee"
                element={<Employee messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="profile"
                element={
                  <ProfilePage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="payments"
                element={
                  <PaymentsPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="po"
                element={<PoPage messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="cor"
                element={<CorPage messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="invoice"
                element={
                  <InvoicePage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="invoice/:id"
                element={
                  <InvoiceDetails messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="roles"
                element={<RolePage messageApi={messageApi} emitter={emitter} />}
              />
              <Route
                path="allocatework"
                element={
                  <AllocateWorkPage messageApi={messageApi} emitter={emitter} />
                }
              />
              <Route
                path="*"
                element={<NoMatch messageApi={messageApi} emitter={emitter} />}
              />
            </Route>
            <Route path="/login">
              <Route
                index
                element={<Login messageApi={messageApi} emitter={emitter} />}
              />
            </Route>
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </div>
  );
}

function Home() {
  const { Table, load } = useEntity("timeentry", {});
  // useEffect(() => {

  //   load();
  // }, [load]);
  return (
    <div style={{ padding: 10, flex: 1, overflow: "scroll" }}>
      <h1>Home Page</h1>
      <h2 onClick={(e) => load()}>Load Data</h2>
      <Table />
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function useEntity(modelName, schema) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const Table = () => {
    return (
      <div>
        <h2>{modelName}</h2>
        {data.map((d, i) => {
          return (
            <div
              key={modelName + "-dd-" + i}
              style={{ margin: 5, padding: 5, border: "1px solid #ccc" }}
            >
              {d.name}
            </div>
          );
        })}
      </div>
    );
  };
  const load = async () => {
    try {
      setLoading(true);
      const res = await get(modelName);
      setData(res.data);
      setCount([] || 0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return { data, count, loading, load, Table };
}
