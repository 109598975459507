import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, DatePicker, Select } from "antd";
import { useQuote } from "./Quote.context";
import { get } from "../../../../api/request";

const { Option } = Select;

const QuoteForm = ({ editData }) => {
  const { drawerService } = useQuote();
  const [form] = Form.useForm();
  // const [customers, setCustomers] = useState([]); // Replace with actual customer data
  // const [projects, setProjects] = useState([]); // Replace with actual project data

  // async function loadData() {
  //   const c = await get("customer?limit=1000");
  //   const p = await get("project?limit=1000");
  //   //setCustomers(c.data);
  //   //setProjects(p.data);
  // }
  useEffect(() => {
    //loadData();

    if (editData) {
      form.setFieldsValue(editData);
    }
  }, [editData, form]);
  useEffect(() => {
    console.log(drawerService);
    // if (!drawerService.IsOpen) form.resetFields();
    console.log('edit id updated', drawerService.editId);
    if (drawerService.mode === 'create') {
      form.resetFields();
    }
  }, [drawerService.editId, drawerService.isOpen, form]);

  const onFinish = (values) => {
    console.log("Received form values: ", JSON.stringify(values));
    drawerService.createQuote(values);
    drawerService.close();
    form.resetFields();
    // Submit data to server or handle accordingly
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={editData}
    >
      <Form.Item
        name="customer_id"
        label="Customer"
        rules={[{ required: true, message: "Please select a customer!" }]}
      >
        <Select
          showSearch
          placeholder="Select a customer"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(customerId) => {
            // Handle the selection of customer here
            console.log("Selected customer ID:", customerId);
            drawerService.fetchProjectsForCustomers(customerId);
            form.setFieldValue('project_id',null)
          }}
        >
         {drawerService &&
                drawerService.customers.map((customer) => (
                  <Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Option>
                ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="project_id"
        label="Project"
        rules={[{ required: true, message: "Please select a project!" }]}
      >
        {/* <Select
          showSearch
          placeholder="Select a project"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {projects.map((project) => (
            <Option key={project.id} value={project.id}>
              {project.name}
            </Option>
          ))}
        </Select> */}
         <Select placeholder="Select a project" >
              {drawerService &&
                drawerService.projects &&
                drawerService.projects.map((project) => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
            </Select>
      </Form.Item>
      <div style={{ display: "flex" }}>
        <Form.Item
          style={{ flex: 1 }}
          name="received_date"
          label="Quote Received Date"
          rules={[
            {
              required: true,
              message: "Please select the quote received date!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          style={{ flex: 1, marginRight: 0 }}
          name="due_date"
          label="Quote Due Date"
          rules={[
            { required: true, message: "Please select the quote due date!" },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </div>
      <Form.Item name="remark" label="Remark">
        <Input.TextArea />
      </Form.Item>
      <div style={{ display: "flex" }}>
        <Form.Item name="mto" valuePropName="checked" style={{ flex: 1 }}>
          <Checkbox>MTO </Checkbox>
        </Form.Item>

        <Form.Item
          name="connection_design"
          valuePropName="checked"
          style={{ flex: 1 }}
        >
          <Checkbox>Connection Design</Checkbox>
        </Form.Item>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }} />
        <Button type="primary" htmlType="submit">
          {editData ? "Update" : "Submit"}
        </Button>
        {/* <Button
          onClick={(e) => {
            form.resetFields();
          }}
        >
          reset
        </Button> */}
      </div>
    </Form>
  );
};

export default QuoteForm;
