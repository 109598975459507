import { usePo } from "./Invoice.context";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { get } from "../../../../api/request";
import { useNavigate } from "react-router";
export default function CustomersList() {
  const { invouce, drawerService } = usePo();
  // const [invouce, setInvoice] = useState([]);
  const columns = [
    {
      // title: "Customer Name",
      title: <span style={{ fontSize: 10, color: "$555" }}>Invoice No</span>,
      dataIndex: "seq_number",
      key: "customerName",
      render: (customer) => customer,
    },
    {
      // title: "Customer Name",
      title: <span style={{ fontSize: 10, color: "$555" }}>Customer Name</span>,
      dataIndex: "customer",
      key: "customerName",
      render: (customer) => customer?.name,
    },

    {
      title: <span style={{ fontSize: 10, color: "$555" }}> Project</span>,
      dataIndex: "project",
      key: "Project",
      render: (project) => project?.name,
    },

    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Created At</span>,
      dataIndex: "created_at",
      key: "poDate",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  // async function loadInvoice() {
  //   try {
  //     const res = await get("invoice?limit=1000");
  //     setInvoice(res.data);
  //     console.log(res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // useEffect(() => {
  //   loadInvoice();
  // }, []);
  const navigator = useNavigate();
  return (
    <div style={{ flex: 1, overflow: "scroll" }}>
      <Table
        dataSource={invouce}
        columns={columns}
        pagination={false} // Set to false if pagination is not needed
        bordered
        style={{ width: "100%", padding: "20px" }}
        className="custom-table"
        rowKey="poNumber" // Assuming 'poNumber' is unique
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              console.log(record);
              navigator("/invoice/" + record.id);
            },
          };
        }}
      />
    </div>
  );
}
