import { Drawer, Input, Button } from "antd";
import { useEffect, useState } from "react";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { useQuote } from "./Quote.context";
import Customer from "../../../../bu/actions/Customer";
import QuoteForm from "./QouteForm";
function CustomerDrawer() {
  const { drawerService } = useQuote();

  return (
    <div>
      <Drawer
        title={drawerService.mode === "create" ? "Create Quote" : "Edit Quote"}
        placement="right"
        onClose={(e) => {
          drawerService.close();
        }}
        style={{
          display: "flex",
          overflow: "hidden",
          // width: 600,
        }}
        width="500"
        open={drawerService.isOpen}
      >
        <h4>Quote Info</h4>
        <QuoteForm />
      </Drawer>
    </div>
  );
}

export default CustomerDrawer;
