import { Drawer, Input, Button, message, DatePicker } from "antd";
import { useEffect, useState } from "react";
import Project from "../../../../bu/actions/Projects";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import UserProject from "../../../../bu/actions/UserProject";
import Designation from "../../../../bu/actions/Designation";
import User from "../../../../bu/actions/Users";
import dayjs from "dayjs";
import { useAppState } from "../../../../state"
const { RangePicker } = DatePicker;
const engineeringDesignations = ['Sr.Engg Modeler',
  'Engg Modeler',
  'Jr.Engg Modeler',
  'Jr. Engg Checker',
  'Sr. Engg Checker',
  'Engg Checker',
  'Sr.Engg Editor',
  'Jr.Engg Editor',
  'Engg Editor',
  'Sr. Checker',
  'Checker',
  'Estimator',
  'Trainee']
export default function AllocateProjectDrawer({
  projectAllocateDrawer,
  projects,
  loadProjects,
}) {
  const [state] = useAppState();
  // const [projects, setProjects] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [projectSearchKey, setProjectSearchKey] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [employeesList, setEmployeesList] = useState([]);
  const [projectSelectionError, setProjectSelectionError] = useState(null);
  const [employeeSearchKey, setEmployeeSearchKey] = useState("");
  const [projectAlloctedUsers, setProjectAlloctedUsers] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    // loadProjectsLocal();
  }, []);
  useEffect(() => {
    if (selectedProject) {
      loadProjectUsers();
      getUsers();
    }
  }, [selectedProject]);
  // async function loadProjectsLocal() {
  //   try {
  //     const pres = await Project.readAll({ limit: 1000 });
  //     setProjects(pres.data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  async function loadProjectUsers() {
    if (!selectedProject) return;
    try {
      const pres = await UserProject.readOne(selectedProject);
      setProjectUsers(pres);
      setProjectAlloctedUsers(
        pres.map((u) => ({
          id: u.user_id,
          first_name: u.user?.profile?.first_name,
          last_name: u.user?.profile?.last_name,
          start_date: u.start_date,
          end_date: u.end_date,
          unTouched: true,
          isNew: false,
        }))
      );
      console.log(pres);
    } catch (e) {
      console.log(e);
    }
  }

  const getDesignations = async () => {
    const designations = await Designation.readAll();
    return designations
      .filter((designation) =>
        engineeringDesignations.includes(designation.name)
      )
      .map((designation) => designation.id);
  };

  const getUsers = async () => {
    try {
      const designationIds = await getDesignations();

      const users = await User.readAll({
        searchMap: JSON.stringify({
          profile: { designation_id: { in: designationIds } },
        }),
        skip: 0,
        limit: 1000,
        markAsTeamMember: true,
      });
      if (users) {
        setEmployeesList(users?.data);
      }
    } catch (e) {
      console.error("Error fetching employee data:", e);
    }
  };
  function validate() {
    let hasError = false;
    projectAlloctedUsers.map((p) => {
      if (!p.start_date) {
        hasError = true;
      }
      if (!p.end_date) hasError = true;
      setErrorMessage("Start date and End date for all employees is required");
    });
    return !hasError;
  }
  async function update() {
    let teamMembers = [];
    console.log(
      (teamMembers = projectAlloctedUsers
        .map((t) => ({
          id: t.id,
          startDate: t.start_date,
          endDate: t.end_date,
          initialStartDate: t.start_date,
          initialEndDate: t.end_date,
          deleted: t.deleted,
        }))
        .filter((d) => !d.deleted))
    );
    if (validate()) {
      const res = await UserProject.update({
        projectId: selectedProject,
        employees: teamMembers,
      });
      console.log(res, "res----");
      console.log("calling load projects");
      loadProjects(false);
      console.log("called load projects");
      messageApi.open({
        type: "success",
        content: "Work allocation successfully updated",
      });
      console.log(res);
      loadProjectUsers();
    } else {
      console.log(errorMessage);
      messageApi.open({
        type: "error",
        content: errorMessage,
      });
    }
  }
  return (
    <Drawer
      title="Allocate Project"
      placement="right"
      onClose={(e) => {
        console.log("close call");
        projectAllocateDrawer.close();
      }}
      open={projectAllocateDrawer.isOpen}
      style={{ border: "0px solid red", width: 900 }}
      width="900"
    >
      {contextHolder}
      <div
        style={{
          // border: "5px solid green",
          height: "99%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            // border: "1px solid blue",
            // height: 200,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              border: "0px solid red",
              width: 300,
              marginRight: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <FormFieldTitle>Select Project</FormFieldTitle>
              {!selectedProject && (
                <div>
                  <Input
                    placeholder={"search project"}
                    value={projectSearchKey}
                    onChange={(e) => setProjectSearchKey(e.target.value)}
                    size="small"
                    style={{ marginBottom: 5 }}
                  />
                  <div
                    style={{
                      background: "#eee",
                      padding: 5,
                      height: 200,
                      overflow: "scroll",
                    }}
                  >
                    {projects
                      .filter((p) => !state?.user?.groups?.includes("GROUP_TEAM_LEAD") ? p :
                        state?.user?.user_id === p.assignee)
                      .filter((p) =>
                        p.name
                          .toUpperCase()
                          .includes(projectSearchKey.toUpperCase())
                      )
                      .map((p, i) => {
                        return (
                          <div
                            key={"pro" + i}
                            style={{
                              background: "#fff",
                              margin: 2,
                              padding: 4,
                              cursor: "pointer",
                              color: "#222",
                            }}
                            onClick={(e) => {
                              setSelectedProject(p.id);
                              setProjectSelectionError("");
                            }}
                          >
                            {p.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {selectedProject && (
                <div
                  style={{
                    display: "flex",
                    background: "#eee",
                    alignItems: "center",
                    padding: "2px 5px",
                    // border: "1px solid red",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      color: "blue",
                      padding: 5,
                    }}
                  >
                    {projects.filter((d) => d.id === selectedProject)[0]?.name}
                  </div>

                  <div>
                    <Button
                      size={"small"}
                      style={{ fontSize: 12 }}
                      onClick={(e) => {
                        setSelectedProject(null);
                      }}
                    >
                      Change Project
                    </Button>
                  </div>
                </div>
              )}
              <ErrorMessage>{projectSelectionError}</ErrorMessage>
            </div>
            {selectedProject && (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <FormFieldTitle>Add Employees to Project</FormFieldTitle>
                <Input
                  size="small"
                  placeholder="search Employees"
                  value={employeeSearchKey}
                  onChange={(e) => setEmployeeSearchKey(e.target.value)}
                />
                <div
                  style={{
                    flex: 1,
                    overflow: "scroll",
                    background: "#f1f1f1",
                    marginTop: 10,
                  }}
                >
                  {employeesList
                    .filter((u) => {
                      return (
                        u?.profile?.first_name
                          .toUpperCase()
                          .includes(employeeSearchKey.toUpperCase()) ||
                        u?.profile?.last_name
                          .toUpperCase()
                          .includes(employeeSearchKey.toUpperCase())
                      );
                    })
                    .filter(
                      (e) =>
                        !projectAlloctedUsers.map((d) => d.id).includes(e.id)
                    )
                    .sort(
                      (a, b) =>
                        (a.isTeamMember ? 0 : 1) - (b.isTeamMember ? 0 : 1)
                    )
                    .map((u, i) => (
                      <div
                        key={"prememners" + i}
                        style={{ alignItems: "center" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              margin: 5,
                              padding: 5,
                              background: "#fff",
                              display: "flex",
                              flex: 1,
                            }}
                          >
                            <div style={{ fontSize: 12, flex: 1 }}>
                              {u?.profile?.first_name} {u?.profile?.last_name}
                            </div>
                            <div style={{ fontSize: 10 }}>
                              {u?.isTeamMember && (
                                <div style={{ color: "green" }}>Team</div>
                              )}
                            </div>
                          </div>
                          <Button
                            type="primary"
                            size="small"
                            style={{ fontSize: 10 }}
                            onClick={(e) => {
                              console.log(u);
                              const _projectUsers = [...projectAlloctedUsers];
                              _projectUsers.push({
                                ...{
                                  id: u.id,
                                  first_name: u.profile?.first_name,
                                  last_name: u.profile?.last_name,
                                  start_date: null,
                                  end_date: null,
                                },
                                isNew: true,
                              });
                              setProjectAlloctedUsers(_projectUsers);
                            }}
                          >
                            {">>"}
                          </Button>
                        </div>
                        {/* <div> Actions</div> */}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              border: "0px solid red",
              flex: 1,
              overflowY: "scroll",
              background: "#f1f1f1",
              padding: 10,
            }}
          >
            {projectAlloctedUsers.map((u, i) => (
              <div key={"memners" + i} style={{ margin: 5, padding: 5 }}>
                <div
                  style={{
                    padding: 5,
                    background: u.deleted ? "#ff9797" : "#fff",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      background: u.deleted ? "#ff9797" : "#fff",
                      color: u.deleted ? "#fff" : "#333",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      {u?.first_name} {u?.last_name}
                    </div>
                    {u.isNew && (
                      <div
                        style={{
                          background: "#ff6b4e",
                          width: 7,
                          height: 7,
                          borderRadius: 20,
                          marginRight: 5,
                        }}
                      ></div>
                    )}
                    {!u.isNew && (
                      <div
                        style={{
                          background: "#58d72f",
                          width: 7,
                          height: 7,
                          borderRadius: 20,
                          marginRight: 5,
                        }}
                      ></div>
                    )}
                    {!u.deleted && (
                      <div
                        style={{
                          fontSize: 9,
                          fontWeight: 800,
                          color: "#ff6b4e",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          console.log("---- Remov");
                          const _p = [...projectAlloctedUsers];
                          _p[i].deleted = true;
                          setProjectUsers(_p);
                        }}
                      >
                        REMOVE
                      </div>
                    )}
                    {u.deleted && (
                      <div
                        style={{
                          fontSize: 9,
                          fontWeight: 800,
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          const _p = [...projectAlloctedUsers];
                          _p[i].deleted = false;
                          setProjectUsers(_p);
                          console.log("---- Remov");
                        }}
                      >
                        UNDO
                      </div>
                    )}
                  </div>

                  {/* {JSON.stringify(u)} */}
                </div>
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: " 0px 0 10px 10px",
                    flex: 1,
                    display: "flex",
                    margin: "0px 3px",
                    padding: "5px 10px",
                    background: "#f5f5f5",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>
                    {/* <table>
                      <tr>
                        <td
                          style={{
                            textAlign: "right",
                            color: "#444",
                            fontSize: 10,
                          }}
                        >
                          Start Time:
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "right",
                            color: "#444",
                            fontSize: 10,
                          }}
                        >
                          End Time:
                        </td>
                        <td></td>
                      </tr>
                    </table> */}
                    <RangePicker
                      size={"small"}
                      style={{ fontSize: 10 }}
                      bordered={false}
                      value={[
                        u.start_date ? dayjs(u.start_date) : "",
                        u.end_date ? dayjs(u.end_date) : "",
                      ]}
                      onChange={(d = []) => {
                        console.log(d);

                        try {
                          const _p = [...projectAlloctedUsers];
                          _p[i]["start_date"] = d ? d[0]?.toISOString() : "";
                          _p[i]["end_date"] = d ? d[1]?.toISOString() : "";
                          console.log(_p);
                          setProjectAlloctedUsers(_p);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    />
                  </div>
                  {/* <div
                    style={{
                      fontSize: 10,
                      fontWeight: 600,
                      textTransform: "uppercase",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Allocate Time
                  </div> */}
                </div>
              </div>
            ))}
          </div>
          <div></div>
        </div>
        <div
          style={{ display: "flex", border: "0px solid red", marginTop: 10 }}
        >
          <div style={{ flex: 1 }}></div>
          <Button
            type="primary"
            onClick={(e) => {
              console.log("-----------------------------------PPPP");
              update();
              loadProjects(false);
              projectAllocateDrawer.close();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const FormLabel = ({ children }) => (
  <div style={{ fontSize: 12, color: "#222" }}> {children}</div>
);
