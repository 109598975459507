import axios from "axios";
import React, { useEffect, useState } from "react";
import { TimePicker, DatePicker, Select, Input, Button, InputNumber, AutoComplete } from "antd";
import "./CreateTask.css";
import Tasks from "../../bu/actions/Tasks";
import Projects from '../../bu/actions/Projects'
import { useNavigate, useLocation } from "react-router-dom";
import Users from "../../bu/actions/Users";
import Designation from "../../bu/actions/Designation";
const { Option } = AutoComplete;

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const placeHolder = { padding: 5 };
const CreateTask = ({ onCloseLogTime, messageApi }) => {
  const navigate = useNavigate();
  const location = useLocation()
  // Sample projects and status options

  const statusOptions = ["Open", "In Progress", "Review", "Approved", "Rejected", "Done"];

  const [projects, setProjects] = useState([]);
  const [statusMembers, setstatusMembers] = useState([]);
  const [tasks, setTasks] = useState([])
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [timeRange, setTimeRange] = useState([]);
  const [quantity, setQuantity] = useState(0);

  const [selectedProjectNumber, setSelectedProjectNumber] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectSearch, setProjectSearch] = useState("");
  const [selectedAssigneeId, setSelectedAssigneeId] = useState("");


  const [selectedAssignee, setSelectedAssignee] = useState(null);
  ;

  //error
  const [taskNameError, setTaskNameError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [taskDescriptionError, setTaskDescriptionError] = useState("");
  const [timeRangeError, setTimeRangeError] = useState("");
  const [selectedProjectError, setSelectedProjectError] = useState("");
  const [selectedTeamError, setSelectedTeamError] = useState("");
  const [selectedStatusError, setSelectedStatusError] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [designationId, setDesignationId] = useState(0);
  useEffect(() => {
    try {
      const getTasks = async () => {
        const tasks = await Tasks.readAll()
        console.log("tasks", tasks)
      }
      getTasks()
      const getDesignation = async () => {
        try {
          const designations = await Designation.readAll();
          const designation = designations.filter(designation => ['Sr.Engg Modeler',
            'Engg Modeler',
            'Jr.Engg Modeler',
            'Jr. Engg Checker',
            'Sr. Engg Checker',
            'Engg Checker',
            'Sr.Engg Editor',
            'Jr.Engg Editor',
            'Engg Editor',
            'Sr. Checker',
            'Checker',
            'Estimator',
            'Trainee'].includes(designation.name))
            .map(designation => designation.id).join(',')
          setDesignationId(designation || 0);

        } catch (e) {
          console.log('error');
        }
      };
      getDesignation();
    } catch (error) {
      console.log(error)
    }

  }, [])

  const handleChange = (value, option) => {
    console.log(`selected ${value}`);
    const selectedLabel = value;
    const selectedId = option.key;
    setSelectedAssignee(selectedLabel);
    setSelectedAssigneeId(selectedId);
    setSelectedTeamError('');

  };
  const handleCreateTask = async () => {

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!taskName) {
      setTaskNameError('Task Name is mandatory.');
    }
    else if (specialCharRegex.test(taskName)) {
      setTaskNameError('Values not allowed for Task Name');
    }

    if (!taskDescription) {
      setTaskDescriptionError('Task Description is mandatory.');
    }

    if (!quantity) {
      setQuantityError('Quantity is mandatory.');
    } else if (isNaN(quantity)) {
      setQuantityError('Quantity must be a number.');
    }

    if (!timeRange || timeRange.length !== 2 || !timeRange[0] || !timeRange[1]) {
      setTimeRangeError('Both start and end time are mandatory.');
    }

    if (!selectedProject) {
      setSelectedProjectError('Project Name mandatory.');

    }

    if (!selectedStatus) {
      setSelectedStatusError('Status is mandatory.');
    }

    try {
      // Handle time entry creation here (e.g., submit form data to the server)
      console.log("Creating time entry...", selectedProject, selectedProjectId, selectedTeamMember);
      const task = await Tasks.create({
        project_id: parseInt(selectedProjectId),
        name: taskName,
        starts_at: timeRange[0],
        ends_at: timeRange[1],
        quantity: parseInt(quantity),
        assignee: Number(selectedAssigneeId),
        task_status: selectedStatus.toUpperCase().replace(/\s/g, "_"),
        description: taskDescription,
      })
      onCloseLogTime();
      messageApi.open({
        type: 'success',
        content: 'Task successfully created',
      });
      navigate('/tasks')
    } catch (error) {
      console.log(error)
      messageApi.open({
        type: 'error',
        content: error?.response?.data || 'Error creating task',
      });
    }
  };

  useEffect(() => {
    if (selectedProject) {
      const filtered = projects.filter((project) =>
        project.name.toLowerCase().includes(selectedProject.toLowerCase())
      );
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects([]);
    }
  }, [selectedProject, projects]);

  const getUsers = async (name) => {
    try {
      const users = await Users.readAll({ name, designation_id: designationId });
      const modifiedUsers = users?.data?.map(user => ({
        label: `${user.profile.first_name} ${user.profile.last_name}`,

        value: user.id
      }));
      setOptions(modifiedUsers || []);
    } catch (e) {
      console.log('error')
    }
  };

  const getProjects = async (name) => {
    const projects = await Projects.readAll({ name })
    setProjects(projects?.data || [])

  }

  const handleSearchProject = async (value) => {
    await getProjects(value);
    setSearchValue(value);

  };
  const handleSearch = async (value) => {
    await getUsers(value);
    setSearchValue(value);

  };

  return (
    <>
      <div style={{ margin: "auto 50px" }}>
        <div className="create-task-section">
          <div className="create-task-custom-div">
            <label htmlFor="project" className="create-task-label">Project</label>
            <AutoComplete
              className="create-task-field"
              placeholder="Select the Project"
              value={selectedProject}
              onChange={(value) => {
                setSelectedProject(value);
                setSelectedProjectError(""); // Clear the error message
              }}
              onSelect={(value, option) => {
                setSelectedProjectId(option.key);
                setSelectedProject(option.value);
              }}
              onSearch={handleSearchProject}
              filterOption={(inputValue, option) =>
                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              notFoundContent={searchValue && options.length === 0 ? 'No such project available' : null}
            >
              {filteredProjects.map((project) => (
                <AutoComplete.Option key={project.id} value={project.name}>
                  {project.name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>

            {<div className="create-project-error-message">
              {selectedProjectError ? selectedProjectError : ' '}</div>}
          </div>
          <div className="create-task-custom-div">
            <label htmlFor="taskName" className="create-task-label">Task</label>
            <Input
              className="create-task-field"
              placeholder="Enter the Task"
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value);
                setTaskNameError("");
              }}
            />
            {<div className="create-project-error-message">
              {taskNameError ? taskNameError : ' '}</div>}
          </div>
        </div>
        <div className="create-task-section">
          <div className="create-task-custom-div">
            <label htmlFor="taskName" className="create-task-label">Start time and End time</label>

            <TimePicker.RangePicker
              className="create-task-field"
              format="h:mm a"
              placeholder={["Start Time", "End Time"]}
              value={timeRange}
              onChange={(times) => {
                setTimeRange(times);
                setTimeRangeError("");
              }}
            />

            {<div className="create-project-error-message">
              {timeRangeError ? timeRangeError : ' '}</div>}
          </div>
          <div className="create-task-custom-div">
            <label htmlFor="quantity" className="create-task-label"> Quantity  </label>
            <InputNumber min={0}
              className="create-task-field"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={(value) => {
                setQuantity(value);
                setQuantityError("")
              }}
            />
            {<div className="create-project-error-message">
              {quantityError ? quantityError : ' '}</div>}
          </div>
        </div>
        <div className="create-task-section">
          <div className="create-task-custom-div">
            <label htmlFor="taskName" className="create-task-label">Assign to team member</label>

            <AutoComplete
              onSelect={handleChange}
              onSearch={handleSearch}
              placeholder="Select the team"
              className="create-task-field"
              defaultActiveFirstOption={false} // Disable default active option
              notFoundContent={searchValue && options.length === 0 ? 'No such team member available' : null}
            >
              {options.map((option) => (
                <Option key={option.value} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </AutoComplete>
            {<div className="create-project-error-message">
              {selectedTeamError ? selectedTeamError : ' '}</div>}
          </div>
          <div className="create-task-custom-div">
            <label htmlFor="taskName" className="create-task-label">Status</label>
            <Select
              className="create-task-field"
              placeholder="Select the Status"
              onChange={(value) => {
                setSelectedStatus(value);
                setSelectedStatusError("")
              }}
            >
              {statusOptions.map((status) => (
                <Select.Option key={status} value={status}>
                  {status}
                </Select.Option>
              ))}
            </Select>
            {<div className="create-project-error-message">
              {selectedStatusError ? selectedStatusError : ' '}</div>}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="create-task-custom-discription">
            <label htmlFor="taskName" className="create-task-label">Task Description </label>
            <TextArea
              style={{ resize: 'none' }}
              placeholder="Task Description"
              value={taskDescription}
              onChange={(e) => {
                setTaskDescription(e.target.value);
                setTaskDescriptionError("")
              }}
            />
            {<div className="create-project-error-message">
              {taskDescriptionError ? taskDescriptionError : ' '}</div>}
          </div>
          <div className="create-task-custom-button">
            <Button
              type="primary" onClick={handleCreateTask}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTask;

