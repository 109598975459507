import React, { useState, useEffect, } from "react";
import './CreateRole.css';
import { DatePicker, Input, Button, AutoComplete, Table, ConfigProvider, Switch } from "antd";
import { Form, Row, Col } from "antd";
import Permission from '../../bu/actions/Permissions'
import Role from '../../bu/actions/Roles'

const { TextArea } = Input;

const CreateRole = ({ messageApi, onCloseRole, selectedRole, isFormTypeUpdate, editButtonName }) => {
  const [form] = Form.useForm();

  const [allPermissions, setAllPermissions] = useState([]);
  if (editButtonName) {
    console.log("this is calling", editButtonName)

  }

  const [roleName, setRoleName] = useState("")
  const [roleDescription, setRoleDescription] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedRole ? selectedRole.permission : []);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (editButtonName === "Save") {
      form.resetFields();
      setSelectedRowKeys([])
      getPermissions()
    }
    else {
      getPermissions()

      if (selectedRole && "name" in selectedRole) {
        const selectedPermissionsIds = selectedRole.permissions.map(permission => permission.id)
        setSelectedRowKeys(selectedPermissionsIds)

      }
      form.setFieldsValue({
        roleName: selectedRole.name,
        description: selectedRole.description,
      });
    }
  }, [editButtonName, selectedRole])

  const handleQuoteSubmit = (e) => {
    e.preventDefault();
    console.log('Creating quote...');
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);


  const handleCreateRole = async () => {
    const data = {
      name: roleName,
      description: roleDescription,
      permissions: selectedRowKeys.map(id => ({ id })),
    };
    try {
      await Role.create(data);
      onCloseRole();
      messageApi?.open({
        type: 'success',
        content: 'Role successfully created',
      });
    } catch (error) {
      console.log(8989, error);
      messageApi?.open({
        type: 'error',
        content: error?.response?.data || 'Error adding role',
      });
    }

  };

  const handleUpdateRole = async () => {
    const permissionData = {
      permissions: selectedRowKeys.map(id => ({ id })),
    };
    try {
      await Role.update(permissionData, selectedRole.id);
      setRoleDescription("");
      onCloseRole();
      messageApi?.open({
        type: 'success',
        content: 'Role successfully updated',
      });
    } catch (error) {
      console.log(8989, error);
      messageApi?.open({
        type: 'error',
        content: error?.response?.data?.error || 'Error adding role',
      });
    }

  };



  //Value
  const [quoteRemark, setQuoteRemark] = useState("");

  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);



  const handleQuoteRemarkChange = (e) => {
    setQuoteRemark(e.target.value);
  };
  const getPermissions = async () => {
    try {
      let response = await Permission.readAll()
      response = response.map((eachRole, index) => { return { ...eachRole, key: eachRole.id } })
      setAllPermissions(response)
      setTotal(response.length)

      if (selectedRole) {
        // Assuming Role.getPermissionsById is a function that fetches permissions by role ID
        // const rolePermissions = await Role.getPermissionsById(selectedRole.id);
        // setSelectedPermissions(rolePermissions);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  }

  useEffect(() => {
    try {


    } catch (error) {
      console.log(error)
    }

  }, [])

  const onSelectChange = selectedRowKeys => {
    console.log("here", selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPermissions(selectedRows);
      onSelectChange(selectedRowKeys);
    },
  };

  useEffect(() => {
    const handleResize = () => {
      const newCellPaddingBlock = window.innerWidth < 740 ? 10 : 7;
      setCellPaddingBlock(newCellPaddingBlock);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onChange = (page) => {
    setCurrentPage(page);
    getPermissions(page);
  };

  const handleButton = () => {
    if (editButtonName === "Save") {
      handleCreateRole();
    }
    else {
      handleUpdateRole();
    }
  }
  return (
    <div>
      <Form
        name="quoteForm"
        className="role-form"
        layout="vertical"
        autoComplete="off"
        requiredMark="optional"
        onSubmit={handleButton}
        form={form}
      >
        <Row style={{ justifyContent: "space-evenly" }}>
          <div>
            <Col>
              <Form.Item
                style={{ width: '280px' }}
                label="Name"
                name="roleName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                  },
                ]}
              >
                <Input
                  value={roleName}
                  onChange={(e) => {
                    setRoleName(e.target.value)
                  }}
                  disabled={isFormTypeUpdate}

                />
              </Form.Item>

              <Form.Item
                label={<span style={{ paddingLeft: "10px" }}>Description</span>}
                name="description"
                initialValue={roleDescription}
                rules={[
                  {
                    required: true,
                    message: "Please write your description",
                  },
                ]}>
                <TextArea style={{ height: 65, width: 690, resize: 'none', }}
                  value={roleDescription}
                  onChange={(e) => {
                    setRoleDescription(e.target.value);
                  }}
                  disabled={isFormTypeUpdate} />
              </Form.Item>

              <Form.Item style={{ marginBottom: '0' }}
                label={<span style={{ paddingLeft: "10px" }}>Permissions</span>}
                name="permissions"
              >

                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        cellPaddingBlock: cellPaddingBlock
                      },
                    },
                  }}
                >
                  <Table className='role-table'style={{overflow:"scroll"}}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={allPermissions || []}
                    // loading
                    // pagination={{
                    //   onChange,
                    //   total,
                    //   defaultPageSize: 6,
                    //   current: currentPage,
                    //   showSizeChanger: false,
                    // }}
                    pagination={false}
                  />
                </ConfigProvider>
              </Form.Item>
              <div style={{ textAlign: "end" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleButton}
                  style={{ width: 114.607, height: 39.36, borderRadius: 2 }}
                >
                  {editButtonName}
                </Button>
              </div>
            </Col>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default CreateRole;
