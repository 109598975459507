import { Button, Input } from "antd";
import { useQuote } from "./Quote.context";

function QuoteSidebar() {
  const { drawerService, searchKey, setSerachKey } = useQuote();
  return (
    <div style={{ width: 200, background: "#fff", padding: 10 }}>
      <Button
        style={{ width: "100%" }}
        type="primary"
        onClick={(e) => drawerService.openToCreate()}
      >
        Create Quote
      </Button>
      <div style={{ marginTop: 10 }}>
        <Input
          value={searchKey}
          onChange={(e) => setSerachKey(e.target.value)}
          placeholder={"search"}
        />
      </div>
    </div>
  );
}

export default QuoteSidebar;
