import React, { useState, useEffect } from "react";
import { Button, Select, Table, DatePicker, ConfigProvider, Avatar, Typography } from "antd";
import Users from '../../bu/actions/Users';
import Projects from '../../bu/actions/Projects';
import Designation from '../../bu/actions/Designation';
import "./AllocateProject.css";
import { MinusCircleOutlined } from '@ant-design/icons';
import UserProject from "../../bu/actions/UserProject";
import dayjs from 'dayjs';
import ProjectOptionsSelectionModal from "../inputComponents/projectOptions/modal";
const { RangePicker } = DatePicker;

const engineeringDesignations = ['Sr.Engg Modeler',
    'Engg Modeler',
    'Jr.Engg Modeler',
    'Jr. Engg Checker',
    'Sr. Engg Checker',
    'Engg Checker',
    'Sr.Engg Editor',
    'Jr.Engg Editor',
    'Engg Editor',
    'Sr. Checker',
    'Checker',
    'Estimator',
    'Trainee']

const circleStyle = {
    backgroundColor: 'white', // Set background color for the circle
    width: '20px',              // Set width and height for the circle
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',        // Make it a circle by setting border-radius to 50%
    border: '1px solid lightgrey'
};

const textStyle = {
    color: '#8d85e3',               // Set text color
    fontSize: '12px',            // Set font size for the "T"
};

const AllocateProject = ({ messageApi, onCloseAllocateProjectDrawer, emitter, openAllocateProjectDrawer }) => {
    const [employeesList, setEmployeesList] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectOptions, setProjectOptions] = useState([]);
    const [projectNameError, setProjectNameError] = useState("");
    const [selectedEmployeeError,] = useState("");
    const [currentPage,] = useState(1);
    const [cellPaddingBlock,] = useState(10);
    const limit = 100
    const [submitProjectAllocation, setSubmitProjectAllocation] = useState(false);
    const [globalEmployeesList, setGlobalEmployeesList] = useState([]);
    const [projectOptionsModalOpen, setProjectOptionsModalOpen] = useState(false);
    const handleDateChange = async (dates, dateStrings, employeeId) => {
        try {
            setTeamMembers((prevTeamMembers) => {
                const updatedMembersList = prevTeamMembers.map(teamMember => (teamMember.id === employeeId ? {
                    ...teamMember,
                    startDate: dateStrings[0] ? new Date(dateStrings[0]).toISOString() : null,
                    endDate: dateStrings[1] ? new Date(dateStrings[1]).toISOString() : null,
                    initialStartDate: dateStrings[0] ? dayjs(dateStrings[0]) : null,
                    initialEndDate: dateStrings[1] ? dayjs(dateStrings[1]) : null,
                } : teamMember));
                return updatedMembersList
            })
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemoveTeamMembers = (indexToRemove) => {
        const recordToRemove = teamMembers[indexToRemove]
        const updatedTeamMembers = teamMembers.filter(
            (_, index) => index !== indexToRemove
        );
        setTeamMembers(updatedTeamMembers);
        setEmployeesList([...employeesList, recordToRemove].sort((a, b) => a.id - b.id));
    };


    const disabledDates = (current) => {
        return current && (current >= dayjs(selectedProject.startDate) || current <= dayjs(selectedProject.endDate));
    };

    const updatedColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            render: (_, record) => (
                <>{`${record.profile.first_name} ${record.profile.last_name}`}</>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            width: '50%',
            render: (_, record) => (
                <RangePicker
                    // disabledDate={disabledDates}
                    // defaultValue={[record.initialStartDate, record.initialEndDate]}
                    value={[record.initialStartDate, record.initialEndDate]}
                    placeholder={['Start Date', 'End Date']} size="large"
                    onChange={(dates, dateStrings, index) => handleDateChange(dates, dateStrings, record.id)} />
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (_, record, index) => (
                <MinusCircleOutlined
                    style={{ fontSize: "25px", paddingLeft: "10px" }}
                    onClick={() => handleRemoveTeamMembers(index)}
                />
            ),
        },
    ];


    const employeesListColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '90%',
            render: (_, record) => (
                <>{`${record.profile.first_name} ${record.profile.last_name}`}</>
            ),
        },
        {
            title: 'isTeamMember',
            dataIndex: 'isTeamMember',
            key: 'isTeamMember',
            render: (isTeamMember, record) => (
                <>
                    {isTeamMember ? (
                        <Avatar style={circleStyle}>
                            <Typography.Text style={textStyle}>T</Typography.Text>
                        </Avatar>
                    ) : null}
                </>
            ),
        }
    ];
    const getDesignations = async () => {
        const designations = await Designation.readAll();
        return designations.filter(designation => engineeringDesignations.includes(designation.name)).map(designation => designation.id)
    }

    const getUsers = async () => {
        try {
            const designationIds = await getDesignations();
            if (currentPage === 1) setEmployeesList((prevUserList) => [])
            const users = await Users.readAll({
                searchMap:
                    JSON.stringify({
                        profile: { designation_id: { in: designationIds } }
                    }),
                skip: 0, limit, markAsTeamMember: true
            });
            if (users) {
                setGlobalEmployeesList(prevUserList => users?.data)
                setEmployeesList(prevUserList => users?.data);
            }
        } catch (e) {
            console.error("Error fetching employee data:", e);
        }
    };

    useEffect(() => {
        // Listen for the custom event
        emitter.on('allocate-project', () => setSubmitProjectAllocation(true));
        emitter.on('clear-allocate-project-form', () => {
            setEmployeesList((prevList) => [...prevList, ...teamMembers].sort((a, b) => a.id - b.id));
            setTeamMembers([])
            setSelectedProject(null)
        });

        return () => {
            // Remove the event listener when the component unmounts
            emitter.off('create-activity', () => setSubmitProjectAllocation(true));
        };
    }, [emitter]);

    useEffect(() => {
        const updateWorkAllocation = async () => {
            try {
                if (!selectedProject?.id) {
                    messageApi.open({
                        type: 'error',
                        content: 'Please select a project',
                    })
                    return
                }
                if (teamMembers.some(teamMember => !teamMember.startDate || !teamMember.endDate)) {
                    messageApi.open({
                        type: 'error',
                        content: 'Please add start and end date for all resources',
                    })
                    return
                }
                await UserProject.update({ projectId: selectedProject?.id, employees: teamMembers })
                messageApi.open({
                    type: 'success',
                    content: 'Resources are successfully allocated',
                });
                setProjectOptions([])
                setTeamMembers([])
                setEmployeesList([])
                onCloseAllocateProjectDrawer()
            } catch (error) {
                console.log(error)
                messageApi.open({
                    type: 'error',
                    content: error?.response?.data?.error || 'Error allocating resources. Contact Admin',
                });
            } finally {
                setSubmitProjectAllocation(false)

            }
        }
        if (submitProjectAllocation)
            updateWorkAllocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitProjectAllocation]);


    useEffect(() => {
        getUsers();
        return () => { setEmployeesList([]) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const getProjects = async () => {
        try {
            const projects = await Projects.readAll();
            const modifiedProjects = projects?.data?.map(project => ({
                label: project.name,
                value: project.id
            }));
            if (!Array.isArray(projects?.data)) {
                setProjectNameError('No Projects left to allocate')
            }
            setProjectOptions(modifiedProjects || []);
        } catch (e) {
            console.error("Error fetching project data:", e);
        }
    };
    useEffect(() => {
        getProjects();
        return () => {
            setSelectedProject("")
            setEmployeesList([])
            setTeamMembers([])
            setProjectNameError([])
        }
    }, []);

    const getUserProjects = async (selectedProject) => {
        try {
            const userProjectDetails = await UserProject.readOne(selectedProject)
            if (Array.isArray(userProjectDetails) && userProjectDetails?.length) {
                const formattedUserProjectDetails = userProjectDetails.map(userProjectDetail => ({
                    id: userProjectDetail.user_id,
                    startDate: new Date(userProjectDetail.start_date),
                    endDate: new Date(userProjectDetail.end_date),
                    initialStartDate: dayjs(userProjectDetail.start_date),
                    initialEndDate: dayjs(userProjectDetail.end_date),
                    profile: {
                        first_name: userProjectDetail.user.profile.first_name,
                        last_name: userProjectDetail.user.profile.last_name,
                    }
                }))
                setTeamMembers(formattedUserProjectDetails || [])
                if (formattedUserProjectDetails.length) {
                    setEmployeesList((prevEmployeeList) =>
                        globalEmployeesList.filter(employee => !formattedUserProjectDetails.some(userProjectDetail => userProjectDetail.id === employee.id)))
                }
            } else {
                setTeamMembers(() => [])
                setEmployeesList((prevEmployeeList) =>
                    globalEmployeesList)
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        setSelectedProject("")
        getUsers()
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAllocateProjectDrawer])

    useEffect(() => {
        console.log(selectedProject)
        if (selectedProject?.id) getUserProjects(selectedProject?.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject])

    return (
        <>
            <div style={{ display: "flex", height: '100%' }}>
                <div style={{ flex: 1, padding: '48px' }}>
                    <div className="allocate-project-section">
                        <div className="allocate-project-custom-div">
                            <label className="allocate-project-label">Project Name</label>
                            <span style={{ maxWidth: '200px', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                                <span style={{
                                    maxWidth: '170px', overflow: 'hidden', textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap', display: 'inline-block'
                                }}>
                                    {selectedProject?.id && selectedProject?.name}
                                </span>
                                <Button onClick={() => setProjectOptionsModalOpen(true)} style={{ marginLeft: '10px' }}>
                                    {selectedProject?.id ? 'Edit' : 'Select Project'}
                                </Button>
                            </span>
                            {<div className="allocate-project-error-message">{projectNameError ? projectNameError : ' '}</div>}
                        </div>
                    </div>
                    <div className="allocate-project-section">
                        <div className="allocate-project-custom-div">
                            <label className="allocate-project-label"> Employee Name</label>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Table: {
                                            cellPaddingBlock: cellPaddingBlock,
                                            rowHoverBg: '#e6f4ff'
                                        },
                                    },
                                }}
                            >
                                <Table
                                    // rowSelection={rowSelection}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                if (!selectedProject?.id) {
                                                    messageApi.open({
                                                        type: 'error',
                                                        content: 'Please select a project',
                                                    })
                                                    return
                                                }
                                                setTeamMembers((prevList) => [...prevList, record])
                                                const updatedEmployeeList = employeesList.filter((row) => row.id !== record.id);
                                                setEmployeesList((prevList) => updatedEmployeeList);

                                            },
                                            onMouseEnter: (e) => {

                                                e.currentTarget.style.cursor = !selectedProject?.id ? 'not-allowed' : 'pointer';
                                            },
                                            onMouseLeave: (event) => { }, // mouse leave row
                                        };
                                    }}
                                    style={{ maxWidth: 'calc(100vw - 250px)', borderCollapse: 'collapse' }}
                                    columns={employeesListColumns}
                                    dataSource={employeesList}
                                    pagination={false}
                                    scroll={{ y: 200 }}
                                    showHeader={false}
                                    rowKey='id'
                                    bordered
                                />
                            </ConfigProvider>
                            {<div className="allocate-project-error-message">{selectedEmployeeError ? selectedEmployeeError : ' '}</div>}
                        </div>
                    </div>
                    {/* <div className="allocate-project-section">
                    <div className='allocate-project-custom-div' >
                        <Button
                            className="allocate-project-button"
                            type="primary"
                            onClick={handleAddTeamMembers}
                        >
                            Add My Team Members
                        </Button>
                    </div>
                </div> */}
                </div>
                <div style={{ boxSizing: 'border-box', flex: 1.5, background: "#EBEBEB", border: '1px solid #EBEBEB', height: '100%', padding: '16px', overflow: 'scroll' }}>
                    <div className="allocate-project-section" style={{ display: "initial", background: "inherit", height: '100%' }}>
                        <div style={{ display: "flex", fontSize: '18px', fontWeight: '400', margin: '15px', marginLeft: '8px', background: "#EBEBEB" }}>
                            Allocate Work Duration
                        </div>
                        <Table
                            style={{ background: "inherit" }}
                            dataSource={teamMembers}
                            columns={updatedColumns}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                    </div>
                </div>
            </div>
            <ProjectOptionsSelectionModal projectOptionsModalOpen={projectOptionsModalOpen}
                setProjectOptionsModalOpen={setProjectOptionsModalOpen}
                setSelectedProject={setSelectedProject}
                parentOpen={submitProjectAllocation || openAllocateProjectDrawer}
            />
        </>
    );
};

export default AllocateProject;
