import { Drawer, Input, Button } from "antd";
import { useEffect, useState } from "react";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { usePo } from "./Po.context";
import Customer from "../../../../bu/actions/Customer";
import POForm from "./PoForm";
function PoDrawer() {
  const { customers, drawerService, loadCustomers } = usePo();

  useEffect(() => {
    if (drawerService.mode === "create") {
    }
    if (drawerService.mode === "edit") {
    }
  }, [drawerService.mode, drawerService.editCustomerId]);

  return (
    <div>
      <Drawer
        title={drawerService.mode === "create" ? "Create PO" : "Edit PO"}
        placement="right"
        onClose={(e) => {
          drawerService.close();
        }}
        style={{
          display: "flex",
          overflow: "hidden",
          // width: 600,
        }}
        width="600"
        open={drawerService.isOpen}
      >
        <h4>PO Info</h4>
        <POForm />
      </Drawer>
    </div>
  );
}

export default PoDrawer;
