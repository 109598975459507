import { get, post } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';
class Permission {
  static #baseUrl = '/permission'
  static async create(data) {
    return await post(this.#baseUrl, data)

  }
  static async update() { }
  static async delete() { }
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params)
    return await get(this.#baseUrl + '?' + urlParams)
    // return await get(this.#baseUrl)
  }
  static async readOne(filter) { 
    console.log(filter)
 
  }
}

export default Permission;
