import { Drawer, Input, Button, Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { usePo } from "./payments.context";
import Customer from "../../../../bu/actions/Customer";
import POForm from "./PaymentsForm";
import { get, post } from "../../../../api/request";
import dayjs from "dayjs";
function PoDrawer() {
  const { customers, drawerService, loadCustomers } = usePo();
  const [invoice, setInvoice] = useState();
  const [paymentModes, setPaymentModes] = useState([]);
  const [data, setData] = useState({});
  async function loadInvoices() {
    try {
      const res = await get("invoice?limit=1000");
      console.log(res);
      setInvoice(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadPaymentMode() {
    try {
      const res = await get("paymentmode?limit=1000");
      console.log(res);
      setPaymentModes(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (drawerService.mode === "create") {
      loadInvoices();
      loadPaymentMode();
    }
    if (drawerService.mode === "edit") {
    }
  }, [drawerService.mode, drawerService.editCustomerId]);

  return (
    <div>
      <Drawer
        title={
          drawerService.mode === "create" ? "Create Payments" : "Edit Payments"
        }
        placement="right"
        onClose={(e) => {
          drawerService.close();
        }}
        style={{
          display: "flex",
          //
          overflow: "hidden",
          // width: 600,
        }}
        width="1000"
        open={drawerService.isOpen}
      >
        <div
          style={{
            flex: 1,
            height: "99%",
            display: "flex",
          }}
        >
          <div style={{ flex: 1, padding: 10 }}>
            <div style={{ marginBottom: 10 }}>
              <div>Invoice </div>
              <div>
                <Select
                  style={{ width: "100%" }}
                  value={data.invoice_id}
                  onChange={(e) => {
                    setData((pv) => ({ ...pv, invoice_id: e }));
                  }}
                  placeholder="select Invoice"
                >
                  {invoice &&
                    invoice.map((d, i) => (
                      <option key={"in" + i} value={d.id}>
                        {d.seq_number}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Payment Mode </div>
              {/* {JSON.stringify(paymentModes)} */}
              <div>
                <Select
                  style={{ width: "100%" }}
                  value={data.payment_mode_id}
                  onChange={(e) => {
                    setData((pv) => ({ ...pv, payment_mode_id: e }));
                  }}
                  placeholder="select payment mode"
                >
                  {paymentModes.map((d, i) => (
                    <option key={"pm" + i} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Bank Name </div>
              <div>
                <Input
                  vaule={data.bank_name}
                  onChange={(e) => {
                    setData((pv) => ({ ...pv, bank_name: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Payment Amount </div>
              <div>
                <Input
                  style={{ width: "100%" }}
                  value={data.amount_received}
                  onChange={(e) => {
                    setData((pv) => ({
                      ...pv,
                      amount_received: e.target.value,
                    }));
                  }}
                  placeholder="Enter Ammount"
                />
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>Payment number </div>
              <div>
                <Input
                  style={{ width: "100%" }}
                  value={data.number}
                  onChange={(e) => {
                    setData((pv) => ({
                      ...pv,
                      number: e.target.value,
                    }));
                  }}
                  placeholder="Enter Ammount"
                />
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div> Date </div>
              <div>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="select Project"
                  value={data.date}
                  onChange={(e) => {
                    setData((pv) => ({ ...pv, date: e }));
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div>
                <Button
                  type={"primary"}
                  onClick={async (e) => {
                    console.log("creating", {
                      ...data,
                      date: dayjs(data.date).toISOString(),
                    });
                    try {
                      const res = await post("payment", data);
                      console.log(res);
                      alert("payment creation successfull");
                      drawerService.close()
                      loadCustomers()
                    } catch (e) {
                      alert("payment creation failed");
                      console.log(e);
                    }
                  }}
                >
                  Create Payment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PoDrawer;
