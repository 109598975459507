import { usePo } from "./payments.context";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import { get } from "../../../../api/request";
import { useEffect, useState } from "react";
export default function CustomersList() {
  const { POs, drawerService } = usePo();
  //const [payments, setPayments] = useState([]);
  const columns = [
    {
      // title: "Customer Name",
      title: <span style={{ fontSize: 10, color: "$555" }}>Payment No</span>,
      dataIndex: "number",
      key: "customerName",
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}> Invoice No</span>,
      dataIndex: "invoice",
      key: "invoice_id",
      render: (invoice) => invoice.seq_number,
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}> Bank Mame</span>,
      dataIndex: "bank_name",
      key: "poNumber",
    },
    {
      title: (
        <span style={{ fontSize: 10, color: "$555" }}> Amount Received</span>
      ),
      dataIndex: "amount_received",
      key: "detailing",
    },

    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Payment Date</span>,
      dataIndex: "date",
      key: "poDate",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    // {
    //   title: <span style={{ fontSize: 10, color: "$555" }}>PO Date</span>,
    //   dataIndex: "poDate",
    //   key: "poDate",
    //   render: (text, d) => (
    //     <div style={{ display: "flex" }}>
    //       <Button
    //         style={{ fontSize: 12 }}
    //         size="small"
    //         onClick={(e) => {
    //           console.log(d.customerName, d);
    //           drawerService.openToEdit(d.customerName, d);
    //         }}
    //       >
    //         Edit
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div style={{ flex: 1, overflow: "scroll" }}>
      <Table
        dataSource={POs}
        columns={columns}
        pagination={false} // Set to false if pagination is not needed
        bordered
        style={{ width: "100%", padding: "20px" }}
        className="custom-table"
        rowKey="poNumber" // Assuming 'poNumber' is unique
      />
    </div>
  );
}
