import { get, post, put } from "../../api/request";
import objectToUrlParams from "../../utils/objectToUrlParams";
class Timeentries {
  static #baseUrl = "/timeentry";
  static async create(data) {
    return await post(this.#baseUrl, data);
  }
  static async update(id, data) {
    return await put(`${this.#baseUrl}/${id}?`, data);
  }
  static async delete() {}
  static async readAll(filter) {
    return await get(this.#baseUrl);
  }
  static async readByUserId(userId, params = {}) {
    const urlParams = objectToUrlParams(params);
    return await get(`${this.#baseUrl}/user/${userId}?` + urlParams);
  }
  static async readOne(filter) {}
}

export default Timeentries;
