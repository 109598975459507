import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal } from 'antd';
import Projects from '../../../bu/actions/Projects';
import './modal.css';
const ProjectOptionsSelectionModal = ({
  projectOptionsModalOpen,
  setSelectedProject,
  setProjectOptionsModalOpen,
  projectStatus,
  parentOpen,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [projectOptions, setProjectOptions] = useState({ data: [] });
  const limit = 15;
  const tblRef = useRef(null);
  const fetchData = async (value) => {
    const searchMap = {};
    const queryParams = {
      skip: currentPage * limit,
      limit,
    };
    if (projectStatus) {
      searchMap.status = String(projectStatus)?.trim();
      queryParams.searchMap = JSON.stringify(searchMap);
    }
    if (value?.trim()) {
      searchMap.name = value.trim();
      queryParams.searchMap = JSON.stringify(searchMap);
      queryParams.skip = 0;
    }
    const response = await Projects.readAll(queryParams);
    const { data = [], meta = { totalPages: 0, currentPage: 0 } } =
      response ?? {};
    setProjectOptions((prevVal) => {
      return {
        data: [
          ...(value?.trim() || queryParams?.skip === 0 ? [] : prevVal.data),
          ...data?.map((eachEntry) => ({
            name: eachEntry.name,
            id: eachEntry.id,
            startDate: eachEntry.starts_at,
            endDate: eachEntry.ends_at,
          })),
        ],
        meta,
        hasMore: meta.totalPages > meta.currentPage,
      };
    });
    setCurrentPage(meta.currentPage);
  };

  useEffect(() => {
    if (currentPage === 0) fetchData();
    return () => setProjectOptions({ data: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!parentOpen) {
      setProjectOptions({ data: [] });
      setCurrentPage(0);
    }
    if (parentOpen && currentPage === 0) fetchData();
  }, [parentOpen]);

  const handleCancel = () => {
    setProjectOptionsModalOpen(false);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    console.log(
      'scrollTop ===> ',
      scrollTop,
      'scrollHeight ===> ',
      scrollHeight,
      'clientHeight ===> ',
      clientHeight
    );
    const isAtEnd = Math.ceil(scrollTop) === scrollHeight - clientHeight;
    if (isAtEnd && projectOptions.hasMore) {
      fetchData();
    }
  };

  return (
    <>
      <Modal
        title="Select a project"
        open={projectOptionsModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        {projectOptions && projectOptions?.data.length && (
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: '0px',
              overflowY: 'scroll',
              maxHeight: '400px',
              marginBottom: '20px',
            }}
            onScroll={handleScroll}
            ref={tblRef}
          >
            {projectOptions?.data?.map((eachProject) => (
              <>
                <li
                  className="li-project-option"
                  onClick={() => {
                    setSelectedProject(eachProject);
                    setProjectOptionsModalOpen(false);
                  }}
                >
                  {eachProject.name}
                </li>
              </>
            ))}
          </ul>
        )}
      </Modal>
    </>
  );
};
export default ProjectOptionsSelectionModal;
