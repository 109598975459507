import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
// import { get } from "../../api/request";
// import PrimarySidebar from "./PrimarySidebar";
import PrimarySidebar from "./Sidebar";
// import logo from "../../../src/assets/login/logo.png"
import { getAuthTokenFromLocalStorage } from "../../bu/lib/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Menu } from "antd";
import logo from "../../assets/loginicon.png";
import userlogo from "../../assets/userlogo.png";
import bell from "../../assets/bell.png";
import usicaicon from "../../assets/usicaicon.png";
import Profile from "../../bu/actions/Profile";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [emp_id, setEmpId] = useState(localStorage.getItem('employeeid') || '');
  const [userName, setProfileData] = useState([]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  useEffect(() => {
    if (!getAuthTokenFromLocalStorage())
      navigate("/login?redirect=" + location.pathname);
  }, [location]);

  useEffect(() => {
    try {
      const getUserProfile = async () => {
        const userInfo = await Profile.readAll();
        //console.log("user profile", userIfo.first_name , userIfo.last_name);
        setProfileData(userInfo?.first_name + " " + userInfo?.last_name);
      };
      getUserProfile();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <div style={{ paddingLeft: "10px" }}>
        <UserOutlined />
        <Button type="link" style={{ color: "#000" }}>
          My Profile
        </Button>
      </div>
      <Menu.Item key="logout">
        <LogoutOutlined style={{ color: "red" }} />
        <Button type="link" style={{ color: "red" }}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {/* <div
        style={{
          background: "#fff",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "width .3s cubic-bezier(.645,.045,.355,1)",
        }}
      >
        <div
          className="usica-header-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 90,
            width: "100%",
            alignItems: "center",
            margin: "0px 40px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ padding: "0px 15px", paddingTop: "7px" }}
              src={usicaicon}
              alt=""
            />
            <span
              style={{
                fontSize: "28px",
                fontWeight: "600",
                fontFamily: "Inter",
                color: "#000000E0",
              }}
            >
              USICA Teknoservices Pvt. Ltd.
            </span>
          </div>

          <div
            className="usica-header-right-content"
            style={{ display: "flex" }}
          >
            <img
              src={bell}
              alt=""
              style={{ height: "30px", paddingTop: "10px" }}
            />
            <img
              style={{
                padding: "0px 15px",
                width: "35px",
                height: "35px",
                paddingTop: "7px",
              }}
              src={userlogo}
              alt=""
            />
            <Dropdown overlay={menu}>
              <Space style={{ paddingLeft: "5px", fontSize: "16px" }}>
                {userName}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
        </div>
      </div> */}

      <div
        style={{
          position: "absolute",

          width: "100%",
          height: "-webkit-fill-available",
          maxHeight: "calc(100vh - 0px)",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            // width: 260,
            background: "#fff",
            // border: "1px solid #EBEBEB",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PrimarySidebar />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ECECF0D9",
            flex: 1,
            display: "flex",
            flexDirection: "column",

            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: 80,
              background: "#fff",
              display: "flex",
              paddingRight: 20,
              borderBottom: "1px solid #ccc",
            }}
          >
            <div style={{ flex: 1, display: "flex" }}>
              <div
                style={{
                  padding: "8px",
                  display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid red",
                }}
              >
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#291670",
                    marginRight: 5,
                    marginLeft: 15,
                  }}
                >
                  USICA
                </div>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 300,
                    color: "#333",
                    letterSpacing: 0.8,
                  }}
                >
                  Teknoservices Pvt. Ltd.
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  padding: "0px 15px",
                  width: "35px",
                  height: "35px",
                  paddingTop: "7px",
                }}
                src={userlogo}
                alt=""
              />
              <Dropdown overlay={menu}>
                <Space
                  style={{
                    paddingLeft: "5px",
                    fontSize: 14,
                    color: "#555",
                    cursor: "pointer",
                  }}
                >
                  {userName}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              overflow: "hidden",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default App;
