import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, DatePicker, Table, ConfigProvider } from "antd";
import "./index.css";
import emptyicon from '../../../../../assets/EmptyState.jpeg';
import NoData from '../../../../../components/noData/index.js';
import CreateEmployeeRate from "../../../../../components/creationComponents/CreateEmployeeRate.js";
import Rate from "../../../../../bu/actions/Rating";

const { RangePicker } = DatePicker;
const { Search } = Input;


export default function EmployeeRatePage({ messageApi, user }) {
  const { id } = useParams();
  const [openEmployeeRate, setOpenEmployeeRate] = useState(false);
  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ratings, setRatings] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [columns, setColumns] = useState([
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'date',
      render: (created_at) => {
        return (
          <>
            {new Date(created_at).toLocaleDateString('en-GB')}
          </>
        );
      },
    },
    {
      title: 'Efficiency',
      dataIndex: 'evaluation',
      key: 'efficiency',
      render: (evaluation) => {
        return (
          <>
            {evaluation.efficiency}
          </>
        );
      },

    },
    {
      title: 'Quality',
      dataIndex: 'evaluation',
      key: 'quality',
      render: (evaluation) => {
        return (
          <>
            {evaluation.quality}
          </>
        );
      },
    },
    {
      title: 'Team player',
      dataIndex: 'evaluation',
      key: 'teamPlayer',
      render: (evaluation) => {
        return (
          <>
            {evaluation.teamPlayer}
          </>
        );
      },
    },

    {
      title: 'Punctuality',
      dataIndex: 'evaluation',
      key: 'punctuality',
      render: (evaluation) => {
        return (
          <>
            {evaluation.punctuality}
          </>
        );
      },
    },
    {
      title: 'Training and Development',
      dataIndex: 'evaluation',
      key: 'trainingAndDev',
      render: (evaluation) => {
        return (
          <>
            {evaluation.trainingAndDev}
          </>
        );
      },
    },
  ])
  async function getRatingsForUser(page) {

    try {
      console.log('getRatings currentPage', page)
      const ratings = await Rate.readAll(Number(id), { skip: (page - 1) * 10 });
      console.log("Ratings =====>", ratings)
      setRatings(ratings.data)
      setTotal(ratings.meta.total);
    } catch (error) {

    }
  }
  useEffect(() => {
    getRatingsForUser(1)
  }, [])

  const showEmployeeRateDrawer = () => {
    setOpenEmployeeRate(true);
  };

  const onCloseEmployeeRate = () => {
    setOpenEmployeeRate(false);
    getRatingsForUser(1)
    setCurrentPage(1);
  };
  const onChange = (page) => {
    setCurrentPage(page);
    getRatingsForUser(page);
  };
  const handleDateChange = async (dates) => {
    try {
      const filteredData = await Rate.readAll(
        Number(id), {
        starts_at: dates ? new Date(dates[0].$d).toISOString() : '',
        ends_at: dates ? new Date(dates[1].$d).toISOString() : ''
      });
      setDateRange(dates)
      setRatings(filteredData?.data || []);

    } catch (error) {
      console.error('Error fetching filtered ratings:', error);
      setRatings([]);
    }
  };

  return (
    <div style={{ paddingInline: 50, borderBottom: 'none' }}>
      <div style={{ textAlign: "end", paddingTop: 50 }}>
        <Button style={{ width: 136, borderRadius: 2, height: 36 }} type="primary" onClick={showEmployeeRateDrawer}> + Add Rating </Button>
      </div>
      <div className="rate_list_searchbox_container">
        <RangePicker placeholder={['FromDate', 'ToDate']} size="large" onChange={handleDateChange} />
      </div>
      {Array.isArray(ratings) && ratings.length > 0 ? (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: cellPaddingBlock
              },
            },
          }}
        >
          <Table style={{ padding: '0px 20px' }}
            // className='task-table'
            columns={columns}
            dataSource={ratings}
            pagination={{
              onChange,
              total,
              defaultPageSize: 5,
              current: currentPage,
              showSizeChanger: false,
            }}
          />
        </ConfigProvider>
      ) : (
        <div><NoData
          emptyIcon={emptyicon}
          noDataMessage="You have no rates yet"
        /></div>
      )}
      <Drawer
        width={"50%"}
        style={{
          textAlign: "center",
          fontSize: 20,
          fontWeight: 500,
        }}
        title={
          <span style={{ textAlign: "center", fontSize: 20, }}>
            Rate
          </span>
        }
        placement="right"
        onClose={onCloseEmployeeRate}
        open={openEmployeeRate}
      >
        <CreateEmployeeRate onCloseRate={onCloseEmployeeRate} messageApi={messageApi} userId={Number(id)} />
      </Drawer>
    </div>
  );
}
