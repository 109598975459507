import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './createavhievement.css';
import { DatePicker, Input, Button } from "antd";
import { Form, Row, Col } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Achievement from "../../bu/actions/Achievements";

const { Dragger } = Upload;
const { Search } = Input;
const { TextArea } = Input;

const AchievementCreation = ({ messageApi, onCloseDrawer, userId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleQuoteSubmit = (e) => {
    e.preventDefault();
    console.log('Creating quote...');
  };


  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [specialNote, setSpecialNote] = useState("");
  const [date, setDate] = useState(null);
  const [attachments, setAttachments] = useState([]);


  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSpecialNoteChange = (e) => {
    setSpecialNote(e.target.value);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };
  const handleAttachmentOnchange = (e) => {
    setAttachments(e.target.value);
  };

  const handleCreateAchievement = async () => {
    const data = {
      title: title,
      date: date,
      description: description,
      special_note: specialNote,
      attachments: attachments,
      user_id: userId
    }
    try {
      await Achievement.create(data);
      onCloseDrawer();
      messageApi.open({
        type: "success",
        content: " Achievement created"
      });
      navigate(`/profile/${userId}?tab=achievements`);
      form.resetFields();
    
    // Reset individual state values
    setTitle("");
    setDescription("");
    setSpecialNote("");
    setDate(null);
    setAttachments([]);
    } catch (error) {
      console.log(error)
      console.log("message", messageApi)
      messageApi.open({
        type: "error",
        content: error?.error || "Error adding",
      });
    }
  };


  const props = {
    name: 'files',
    multiple: true,
    action: 'http://139.59.85.23/api/v1/file',
    headers: { Authorization: "Bearer " + localStorage.getItem("authToken") },
    onChange(info) {
      const { status, response } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (response && response.fileUrls) {
          setAttachments(response.fileUrls);
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  return (
    <div>
      <Form
        name="achievementeForm"
        className="achievement_form"
        layout="vertical"
        autoComplete="off"
        requiredMark="optional"
        onSubmit={handleCreateAchievement}
        form={form}
      >

        <Row style={{ paddingTop: 20, justifyContent: "space-evenly" }}>
          <div>
            <Col>
              <div className="achievement_form_header_item">
                <Form.Item style={{ width: 280, textAlign: "left", marginTop: 25, }}
                  label={<span style={{ paddingLeft: "10px" }}>Title</span>}
                  name="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your title!",
                    },
                  ]}>
                  <Input
                    placeholder="Enter Title"
                    style={{ width: 264, }}
                    value={title}
                    onChange={handleTitleChange}
                  />
                </Form.Item>

                <Form.Item
                  label={<span style={{ paddingLeft: "10px" }}> Date</span>}
                  style={{ width: 280, marginTop: 25, }}
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Please select your date",
                    },
                  ]}>
                  <DatePicker style={{ width: "100%" }}
                    // value={recordDate}
                    // onChange={handleDateChange}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    value={date}
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </div>

              <Form.Item
                style={{ marginTop: 30, }}
                label={<span style={{ paddingLeft: "10px" }}>Description</span>}
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please write your Description",
                  },
                ]}>
                <TextArea style={{ height: 62, width: 610, resize: 'none', }}
                  value={description}
                  onChange={handleDescriptionChange} />
              </Form.Item>
              <Form.Item
                label={<span style={{ paddingLeft: '5px', marginTop: '20px' }}>Attachments</span>}
                name="attachments"
                rules={[
                  {
                    required: true,
                    message: "Please add file!",
                  },
                ]}>

                <Dragger {...props} style={{ border: 'none', background: '#fff' }} >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined value={attachments}
                      onChange={handleAttachmentOnchange} />
                  </p>
                  <p className="ant-upload-text">Drag and Drop Files</p>
                </Dragger>
              </Form.Item>

              <Form.Item
                style={{ marginTop: 40, }}
                label={<span style={{ paddingLeft: "10px" }}>Special Note</span>}
                name="specialNote"
                rules={[
                  {
                    required: true,
                    message: "special note is manditory ",
                  },
                ]}>
                <TextArea style={{ height: 62, width: 610, resize: 'none', }}
                  value={specialNote}
                  onChange={handleSpecialNoteChange} />
              </Form.Item>
            </Col>
          </div>
        </Row>
        <div style={{ textAlign: "end", paddingTop: 10 }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleCreateAchievement}
            style={{ width: 114.607, height: 39.36, borderRadius: 2, marginLeft: 20 }}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AchievementCreation;
