import "./index.css"

function NoData(props) {
    return (
        <>
            <div className="task-page-empty-icon-container">
                <div className="task-page-icon">
                    <img src={props.emptyIcon} alt="" />
                </div>
                <div className="task-page-title">
                    {props.noDataMessage}
                </div>
            </div>
        </>
    )
}
export default NoData;