import { Button, Drawer, Input, DatePicker, Select, message, InputNumber } from "antd";

import { useEffect, useState } from "react";
import Activities from "../../../../bu/actions/Activities";
import Domain from "../../../../bu/actions/Domain";
import Project from "../../../../bu/actions/Projects";
import dayjs from "dayjs";
import Timeentries from "../../../../bu/actions/Timeentries";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { useAppState } from "../../../../state"
const { TextArea } = Input;
export default function TimeSheetCreateDrawer({ timesheetServices }) {
  const [state] = useAppState();
  const { RangePicker } = DatePicker;
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState("");
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");
  const [projectSearchKey, setProjectSearchKey] = useState("");
  const [subActivity, setSubActivity] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [duration, setDuration] = useState(0);
  const [details, setDetails] = useState("");
  const [timeRange, setDateTimeRange] = useState([]);

  const [projectSelectionError, setProjectSelectionError] = useState(null);
  const [domainSelectionError, setDomainSelectionError] = useState(null);
  const [activitySelectionError, setActivitySelectionError] = useState(null);
  const [quantityError, setQuantityError] = useState(null);
  const [durationError, setDurationError] = useState(null);
  const [detailsError, setDetailsError] = useState(null);
  const [allocatedWorks, setAllocatedWorks] = useState([]);
  const [allocatedWork, setAllocatedWork] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [timeRangeError, setTimeRangeError] = useState("");
  async function loadProjects() {
    try {
      const pres = await Project.readAll({ limit: 1000, allocated: 'true' });
      setProjects(pres.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadAllocatedWork() {
    try {
      let a = await Project.getWorkAllocate(
        selectedProject,
        JSON.parse(localStorage.getItem("userState"))?.user?.user_id
      );
      setAllocatedWorks(a.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (selectedProject) loadAllocatedWork();
  }, [selectedProject]);
  async function loadDomain() {
    try {
      const dRes = await Domain.readAll({ limit: 1000 });
      setDomains(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadActivity() {
    try {
      const dRes = await Activities.readAll({
        limit: 1000,
        // searchMap: JSON.stringify({ parent_activity_id: null }),
      });
      setActivities(dRes.data);
      console.log(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadActivity() {
    try {
      const dRes = await Activities.readAll({ limit: 1000 });
      setActivities(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (timesheetServices.drawer.editMode) {
      console.log("edit mode -----------------------");
      populateForm();
    } else {
      clearForm();

      console.log("Cretate mode -----------------------");
    }
  }, [timesheetServices.drawer.editMode]);
  function clearForm() {
    setSelectedProject(null);
    setDomain(null);
    setActivity(null);
    setQuantity(null);
    setDetails(null);
    setSubActivity(null);
    setAllocatedWork(null);
    setDateTimeRange(null)
  }
  function populateForm() {
    let ti = timesheetServices.timeEntries.filter(
      (t) => timesheetServices.drawer.editId === t.id
    )[0];
    if (!ti) return;
    setSelectedProject(ti.project_id);
    setDomain(ti.domain_id);
    setActivity(ti?.activity_id);
    setDuration(+ti.duration);
    setQuantity(ti.quantity);
    setDateTimeRange([dayjs(ti.start_time), dayjs(ti.end_time)]);
    setDetails(ti.description);
    setAllocatedWork(ti.allocated_work_id);
    // setSubActivity(ti.);
  }
  function validate() {
    let hasError = false;
    if (!selectedProject) {
      setProjectSelectionError("Need to select Project!");
      hasError = true;
    }
    if (!domain) {
      setDomainSelectionError("Domain must be selected!");
      hasError = true;
    }
    if (!activity) {
      setActivitySelectionError("Activity mast be selected!");
      hasError = true;
    }
    if (!quantity) {
      setQuantityError("Quantity is required!");
      hasError = true;
    }

    if (quantity < 0) {
      setQuantityError("Quantity is should be grater then 0!");
      hasError = true;
    }

    if (
      !timeRange ||
      timeRange.length !== 2 ||
      !timeRange[0] ||
      !timeRange[1]
    ) {
      setTimeRangeError("Both start and end time are mandatory.");
      hasError = true
    } else if (duration > 23) {
      hasError = true
    } if (duration <= 0) {
      setTimeRangeError("Duration must be greater than 0");
      setDurationError("Duration must be greater than 0");
      hasError = true
    } else {
      setDurationError('')
      setTimeRangeError("")
    }
    if (!details) {
      setDetailsError("Details is required!");
      hasError = true;
    }

    return !hasError;
  }
  useEffect(() => {
    loadProjects();
    loadDomain();
    loadActivity();
  }, []);

  async function createTimeEntry() {
    try {
      console.log(
        localStorage.getItem("userState"),
        JSON.parse(localStorage.getItem("userState"))
      );

      const data = {
        user_id: JSON.parse(localStorage.getItem("userState"))?.user?.user_id,
        project_id: selectedProject,
        domain_id: domain,
        activity_id: activity,
        allocated_work_id: allocatedWork,
        start_date: dayjs(timeRange[0])
          ?.set("hour", 5)
          .set("minute", 30)
          .set("second", 0)
          .set("millisecond", 0)
          .toISOString(),
        end_date: dayjs(timeRange[1])
          ?.set("hour", 5)
          .set("minute", 30)
          .set("second", 0)
          .set("millisecond", 0)
          .toISOString(),
        start_time: dayjs(timeRange[0]).toISOString(),
        end_time: dayjs(timeRange[1]).toISOString(),
        // duration: dayjs(timeRange[1]).diff(timeRange[0], "hour"),
        duration: duration,
        description: details,
        quantity: +quantity,
      };
      console.log(data);
      const timeentry = await Timeentries.create(data);
      console.log(timeentry);
      timesheetServices.loadTimeEntries();
      console.log({
        type: "success",
        content: "Time Entry successfully created",
      });
      timesheetServices.drawer.close();
    } catch (error) {
      console.log(error);
      console.log({
        type: "error",
        content: error?.response?.data?.error || "Error adding time entry",
      });
    }
  }
  async function updateTimeEntry() {
    try {
      const data = {
        user_id: JSON.parse(localStorage.getItem("userState"))?.user?.user_id,
        project_id: selectedProject,
        domain_id: domain,
        allocated_work_id: allocatedWork,
        activity_id: activity,
        start_date: dayjs(timeRange[0])
          ?.set("hour", 5)
          .set("minute", 30)
          .set("second", 0)
          .set("millisecond", 0)
          .toISOString(),
        end_date: dayjs(timeRange[1])
          ?.set("hour", 5)
          .set("minute", 30)
          .set("second", 0)
          .set("millisecond", 0)
          .toISOString(),
        start_time: dayjs(timeRange[0]).toISOString(),
        end_time: dayjs(timeRange[1]).toISOString(),
        //duration: dayjs(timeRange[1]).diff(timeRange[0], "hour"),
        duration:duration,
        description: details,
        quantity: +quantity,
      };
      console.log(data);
      const timeentry = await Timeentries.update(
        timesheetServices.drawer.editId,
        data
      );
      console.log(timeentry);
      timesheetServices.loadTimeEntries();
      console.log({
        type: "success",
        content: "Time Entry successfully created",
      });
    } catch (error) {
      console.log(error);
      console.log({
        type: "error",
        content: error?.response?.data?.error || "Error adding time entry",
      });
    }
  }
  const disabledDates = (current) => {
    // Disable dates before 2023-01-01 and after 2023-12-31
    return current && (current > dayjs().endOf('day') || current < dayjs().subtract(1, 'day').startOf('day'));

  };
  return (
    <Drawer
      title={
        timesheetServices.drawer.editMode
          ? "Edit Time Entry"
          : "Create Time Entry"
      }
      placement="right"
      onClose={(e) => {
        console.log("close call");
        timesheetServices.drawer.close();
      }}
      style={{
        flex: 1,
        display: "flex",
        overflow: "hidden",
      }}
      open={timesheetServices.drawer.isOpen}
      width={"500px"}
    >
      {/* <div>Create/edit Time Sheet {timesheetServices.drawer.editId}</div>
       */}
      {contextHolder}
      <div
        style={{
          flex: 1,
          height: "99%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <div>
            <FormFieldTitle>Select Project</FormFieldTitle>
            {!selectedProject && (
              <div>
                <Input
                  placeholder={"search project"}
                  value={projectSearchKey}
                  onChange={(e) => setProjectSearchKey(e.target.value)}
                  size="small"
                  style={{ marginBottom: 5 }}
                />
                <div
                  style={{
                    background: "#eee",
                    padding: 5,
                    height: 200,
                    overflow: "scroll",
                  }}
                >
                  {projects
                    .filter(p => p.users.some(user => user.user_id === state?.user?.user_id))
                    .filter((p) =>
                      p.name
                        .toUpperCase()
                        .includes(projectSearchKey.toUpperCase())
                    )
                    .map((p, i) => {
                      return (
                        <div
                          key={"pro" + i}
                          style={{
                            background: "#fff",
                            margin: 2,
                            padding: 4,
                            cursor: "pointer",
                            color: "#222",
                          }}
                          onClick={(e) => {
                            setSelectedProject(p.id);
                            setProjectSelectionError("");
                          }}
                        >
                          {p.name}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {selectedProject && (
              <div
                style={{
                  display: "flex",
                  background: "#eee",
                  alignItems: "center",
                  padding: "2px 5px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    color: "blue",
                    padding: 5,
                  }}
                >
                  {projects.filter((d) => d.id === selectedProject)[0]?.name}
                </div>

                <div>
                  <Button
                    size={"small"}
                    style={{ fontSize: 12 }}
                    onClick={(e) => {
                      setSelectedProject(null);
                    }}
                  >
                    Change Project
                  </Button>
                </div>
              </div>
            )}
            <ErrorMessage>{projectSelectionError}</ErrorMessage>
          </div>
          {allocatedWorks && (
            <div style={{ marginTop: 10 }}>
              <div>
                <FormFieldTitle>Allocated Works:</FormFieldTitle>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"select Allocate work"}
                  value={allocatedWork}
                  onChange={(e) => {
                    let aw = allocatedWorks.filter((w) => w.id == e)[0];
                    if (aw) {
                      setDomain(aw?.domain_id);
                      setActivity(aw?.name);
                      setAllocatedWork(e);
                    }
                  }}
                >
                  {allocatedWorks.map((d, i) => (
                    <option key={"activities" + i} value={d.id}>
                      {d.activity.name}
                    </option>
                  ))}
                </Select>
              </div>
              <ErrorMessage>{activitySelectionError}</ErrorMessage>
            </div>
          )}
          {selectedProject && (
            <div style={{ marginTop: 10 }}>
              <div>
                <FormFieldTitle>Domain:</FormFieldTitle>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"select Domain"}
                  value={domain}
                  onChange={(e) => {
                    setDomain(e);
                    setDomainSelectionError(null);
                  }}
                >
                  {domains.map((d, i) => (
                    <option key={"domain" + i} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Select>
              </div>
              <ErrorMessage>{domainSelectionError}</ErrorMessage>
            </div>
          )}
          {domain && (
            <div style={{ marginTop: 10 }}>
              <div>
                <FormFieldTitle>Activity:</FormFieldTitle>
                <Select
                  style={{ width: "100%" }}
                  placeholder={"select Activity"}
                  value={activity}
                  onChange={(e) => {
                    setActivity(e);
                    setActivitySelectionError(null);
                  }}
                >
                  {activities
                    .filter((a) => a.domain_id === domain)
                    .filter((a) => a.parent_activity_id === null)
                    .map((d, i) => (
                      <option key={"activities" + i} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </Select>
              </div>
              <ErrorMessage>{activitySelectionError}</ErrorMessage>
            </div>
          )}
          {activity &&
            activities.filter((a) => a.parent_activity_id === activity).length >
            0 && (
              <div style={{ marginTop: 10 }}>
                <div>
                  <FormFieldTitle>Sub Activity:</FormFieldTitle>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={"select Activity"}
                    value={subActivity}
                    onChange={(value) => setSubActivity(value)}
                  >
                    {activities
                      .filter((a) => a.parent_activity_id === activity)
                      .map((d, i) => (
                        <option key={"activities" + i} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
          {activity && (
            <div style={{ marginTop: 10 }}>
              <FormFieldTitle>Quantity:</FormFieldTitle>
              <Input
                value={quantity}
                type={"number"}
                onChange={(v) => {
                  setQuantity(v.target.value);
                  setQuantityError(null);
                }}
              />
              <ErrorMessage>{quantityError}</ErrorMessage>
            </div>
          )}
          {activity && (
            <div style={{ marginTop: 10 }}>
              <FormFieldTitle>Duration:</FormFieldTitle>
              <InputNumber
                style={{ width: '150px' }}
                min={0}
                max={23}
                className="create-time-field"
                placeholder="hours worked.."
                value={duration}
                onChange={(value) => {
                  setDuration(value < 0 ? 0 : value)
                  if (value <= 14) {
                    const todayAt9AM = timeRange?.[0] || dayjs().set('hour', 9).set('minute', 0).set('second', 0)
                    setDateTimeRange([todayAt9AM, todayAt9AM.add(value, 'hour')])
                  } else {
                    const yesterdayAt9AM = timeRange?.[0] || dayjs().subtract(1, 'day').set('hour', 9).set('minute', 0).set('second', 0)
                    setDateTimeRange([yesterdayAt9AM, yesterdayAt9AM.add(value, 'hour')])
                  }
                  setDurationError("");
                  setTimeRangeError("")
                }}
              />

              <ErrorMessage>{durationError}</ErrorMessage>
            </div>
          )}
          {activity && (
            <div style={{ marginTop: 10 }}>
              <FormFieldTitle>Start Date/Time and End Date/Time</FormFieldTitle>
              <RangePicker
                style={{ width: '400px' }}
                disabledDate={disabledDates}
                showTime={{ format: "hh:mm A" }}
                value={timeRange}
                format="DD/MM/YYYY hh:mm A"
                onChange={(times) => {
                  setDateTimeRange(times);
                  if (!times) {
                    setDuration(null)
                    return
                  }
                  const differenceInHours = times[1].set('millisecond', 999).diff(times[0].set('millisecond', 0), 'hour');
                  const differenceInMinutes = times[1].set('millisecond', 999).diff(times[0].set('millisecond', 0), 'minutes');
                  const MINUTES_IN_HOUR = 60
                  setDuration(Number((differenceInMinutes / MINUTES_IN_HOUR).toFixed(2)))
                  setTimeRangeError("");
                  if (differenceInHours > 23) {
                    setDurationError("Cannot be greater than 23 hours")
                    setTimeRangeError("Cannot be greater than 23 hours")
                  }
                }}
              />
              {timeRangeError ? timeRangeError : ""}
            </div>
          )}

          {activity && (
            <div style={{ marginTop: 10 }}>
              <FormFieldTitle>Work Details:</FormFieldTitle>
              <TextArea
                showTime
                style={{ width: "100%" }}
                value={details}
                onChange={(e) => {
                  setDetails(e.target.value);
                  setDetailsError("");
                }}
              />
              <ErrorMessage>{detailsError}</ErrorMessage>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "right",
          }}
        >
          <Button onClick={timesheetServices.drawer.close}>Close</Button>
          <Button
            type={"primary"}
            onClick={async (e) => {
              if (validate()) {
                if (timesheetServices.drawer.editMode) {
                  console.log("---------------------UPDATING----------------");

                  updateTimeEntry();
                  messageApi.open({
                    type: "success",
                    content: "Updated",
                  });
                } else {
                  createTimeEntry();
                  messageApi.open({
                    type: "success",
                    content: "Created",
                  });
                }
                timesheetServices.drawer.close()
              } else {
                messageApi.open({
                  type: "error",
                  content: "Please clear all errors",
                });
              }
            }}
          >
            {timesheetServices.drawer.editMode ? "Edit " : "Create "}
          </Button>
          {/* <Button
            onClick={async (e) => {
              console.log("test");
              let a = await Project.getWorkAllocate(
                selectedProject,
                JSON.parse(localStorage.getItem("userState"))?.user?.user_id
              );
              console.log(a);
              loadAllocatedWork();
            }}
          >
            Load
          </Button> */}
        </div>
      </div>
    </Drawer>
  );
}
