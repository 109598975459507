import "../../../../index.css";
import "./index.css";
import { useEffect, useReducer, useState } from "react";
import projectReducer from "./projectReducer";
import ProjectSidebar from "./ProjectSidebar";
import PageHeader from "../../../../components/PageHeader";
import { Button, Drawer, Input } from "antd";
import useProject from "./projectService";
import { json, useNavigate } from "react-router";
import CreateProjectDrawer from "./CreateProject";
import AllocateWorkDrawer from "./AllocateWork";
import AllocateProjectDrawer from "./AllocateProject";
import dayjs from "dayjs";
import User from "../../../../bu/actions/Users";
import { useAppState } from "../../../../state";
const initialState = {
  projects: [],
};

export default function ProjectsPage() {
  //   [projects, loadProjects, page, projectsLoadingStatus]
  const [state] = useAppState();
  const {
    projects,
    loadProjects,
    page,
    projectsMeta,
    projectsLoadingStatus,
    createProjectDrawer,
    workAllocateDrawer,
    projectAllocateDrawer,
    tl,
    filters,
  } = useProject({
    pageSize: 2000,
  });
  const navigate = useNavigate();
  const [scrollTop, setScrollTop] = useState(true);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [scrollInfo, setScrollInfo] = useState({
    scrollTop: 0,
    isBottom: false,
  });
  const [tlFilter, setTlFilter] = useState("");
  const [projectSearchKey, setProjectSearchKey] = useState("");
  useEffect(() => {
    page.change(0);
    tl.load();
    try {
      const us = localStorage.getItem("userState");
      const userState = JSON.parse(us);
      loadUser(userState.user.user_id);
      console.log("-------------------------", us, userState);
    } catch (e) {
      console.log();
    }
  }, []);

  async function loadUser(id) {
    try {
      const ures = await User.readOne(id);
      if ([3, 4].includes(ures.profile.designation_id)) setTlFilter(ures.id);
      console.log("-----========>>>>>>>>>>>>>>>>>>>>>>", ures);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (scrollBottom) page.change(page.value + 1);
  }, [scrollBottom]);

  const handleScroll = (event) => {
    const container = event.currentTarget;
    const isBottom =
      container.clientHeight + container.scrollTop >= container.scrollHeight;
    setScrollInfo({ scrollTop: container.scrollTop, isBottom });
    setScrollBottom(isBottom);

    if (!projectsLoadingStatus.status && isBottom) {
      console.log("loadmore");
    }
  };

  return (
    <div className="page-container page-v-split">
      <div>
        <ProjectSidebar
          openCreateProject={createProjectDrawer.open}
          openAllocateProjectDrawer={projectAllocateDrawer.open}
          openAllocateWorkDrawer={workAllocateDrawer.open}
          tl={tl}
          filters={filters}
        />
      </div>
      <div
        className="page-body"
        style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
      >
        <PageHeader pageTitle={"Projects"} />
        <div
          style={{
            overflow: "scroll",
            flex: 1,
          }}
          onScroll={handleScroll}
        >
          <Input
            style={{ maxWidth: 300, marginLeft: 7 }}
            placeholder={"search project"}
            value={projectSearchKey}
            onChange={(e) => setProjectSearchKey(e.target.value)}
          />
          {displayData(
            projects
              ?.filter((p) =>
                !state?.user?.groups?.includes("GROUP_TEAM_LEAD")
                  ? p
                  : state?.user?.user_id === p.assignee
              )
              ?.filter((e) => {
                const lead = tl.list.filter((d) => d.selected).map((d) => d.id);

                if (lead.length === 0) return true;
                return lead.includes(e.assigned_to.id);
              })
              .filter((e) => {
                let fd = filters.values.fromDate;
                let ed = filters.values.endDate;
                let pfd = dayjs(e.starts_at);
                let ped = dayjs(e.ends_at);

                let ok = true;
                if (!fd && !ed) return true;
                if (fd && fd > pfd) ok = false;
                if (ed && ed < ped) ok = false;
                return ok;
              })
              .filter((e) => {
                let ok = true;
                if (!filters.values.allocated && !filters.values.notAllocated)
                  return true;
                if (
                  (e.status === "ALLOCATED" && filters.values.allocated) ||
                  (e.status === "NOT_ALLOCATED" && filters.values.notAllocated)
                ) {
                  // if(filters.values.allocated )
                  return true;
                }
              })
              .filter((e) => {
                return e.name
                  .toUpperCase()
                  .includes(projectSearchKey.toUpperCase());
              })
              .filter((e) => {
                if (tlFilter) return tlFilter === e.assignee;
                else return true;
              }),
            (project, i) => (
              <div
                style={{
                  background: "#fff",
                  margin: 3,
                }}
              >
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    display: "flex",
                    background: "#f7f7f7",
                  }}
                >
                  <div
                    style={{ flex: 1, fontWeight: 600, }}
                    // onClick={(e) => {
                    //   navigate("/project/" + project.id);
                    // }}
                  >
                    {project.name}{" "}
                    <span style={{ fontSize: 12, color: "#444" }}>
                      USICAP-{project.seq_number}
                    </span>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      fontSize: 10,
                      fontWeight: 600,
                    }}
                  >
                    <span>Lead: </span>
                    <div style={{ flex: 1 }}>
                      {project.assigned_to?.profile?.first_name}
                      {project.assigned_to?.profile?.last_name}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span
                      style={{
                        color:
                          project.status === "ALLOCATED" ? "green" : "orange",
                        border:
                          project.status === "ALLOCATED"
                            ? "1px solid  green"
                            : "1px solid orange",
                        // display: "block",
                        // flex: 1,
                        fontSize: 10,
                        fontWeight: 600,
                        padding: 3,
                        borderRadius: 5,
                      }}
                    >
                      {project.status}
                    </span>
                  </div>
                  {/* <div>
                  <Button
                    size={"small"}
                    style={{ fontSize: 10, color: "blue" }}
                  >
                    Add Employees{" "}
                  </Button>
                </div>
                <div>
                  <Button
                    size={"small"}
                    style={{ fontSize: 10, color: "blue" }}
                  >
                    Allocate Works{" "}
                  </Button>
                </div> */}
                  <div>
                    <Button
                      size={"small"}
                      style={{ fontSize: 10, color: "green" }}
                      onClick={(e) => createProjectDrawer.edit(project.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      size={"small"}
                      style={{ fontSize: 10, color: "green" }}
                      onClick={(e) => {
                        navigate("/project/timeentries/" + project.id);
                      }}
                    >
                      Time entries
                    </Button>
                    <Button
                      size={"small"}
                      style={{ fontSize: 10, color: "green" }}
                      onClick={(e) => {
                        navigate("/project/work-allocation/" + project.id);
                      }}
                    >
                      Work allocations
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    padding: 5,
                    border: "1px solid #fff",
                    display: "flex",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ fontSize: 10, color: "#666" }}>
                      Project Description:
                    </div>
                    <div style={{ flex: 1, color: "#555" }}>
                      {project.description}
                    </div>
                  </div>
                  <div style={{ flex: 1, fontSize: 12 }}>
                    <div style={{ flex: 1 }}>
                      <span style={{ fontSize: 10 }}>Start Date:</span>
                      {project.starts_at?.split("T")[0]}
                    </div>
                    <div style={{ flexT: 1 }}>
                      <span style={{ fontSize: 10 }}>End Date:</span>
                      {project.ends_at?.split("T")[0]}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div
          onClick={() => {
            createProjectDrawer.open();
          }}
        >
          {/* Page footer */}
        </div>
        <CreateProjectDrawer
          createProjectDrawer={createProjectDrawer}
          tl={tl}
          projects={projects}
          page={page}
          loadProjects={loadProjects}
        />
        <AllocateWorkDrawer
          workAllocateDrawer={workAllocateDrawer}
          loadProjects={loadProjects}
          projects={projects}
        />
        <AllocateProjectDrawer
          projectAllocateDrawer={projectAllocateDrawer}
          loadProjects={loadProjects}
          projects={projects}
        />
      </div>
    </div>
  );
}

function displayData(data, component) {
  if (data) {
    return data.map((d, i) => (
      <div key={"data" + Date.now() + i} style={{ padding: 5 }}>
        {component(d, i)}
      </div>
    ));
  }
  return <div>No data</div>;
}
