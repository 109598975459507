import { get, put } from '../../api/request'

class UserProject {
  static #baseUrl = '/userproject'

  static async create(data) {
  }

  static async update(data) {
    return await put(this.#baseUrl, data)
  }
  static async delete() { }
  static async readAll() {

  }

  static async readOne(id) {
    return await get(`${this.#baseUrl}/${id}`)
  }
}

export default UserProject;