import { Button, Drawer, Input } from "antd";
import { useEffect, useState } from "react";
import { getEstimateDivisions, addDivision, getEstimete } from "./data";
import { useParams } from "react-router-dom";
import Division from "./Division";
// const id = 1;
export default function QuoteDetails() {
  const { id } = useParams();
  const [estimationDivisions, setEstimationDivisions] = useState([]);
  const [selectedestimationDivision, setSelectedEstimationDivision] =
    useState(null);
  const [estimates, setEstimates] = useState([]);
  const [selectedestimationType, setSelectedEstimationType] = useState(null);
  const [selectedestimationSectionType, setSelectedEstimationSectionType] =
    useState(null);

  const [newTitle, setNewTitle] = useState("");
  const [divisionDrawer, setDivisionDrawer] = useState({
    open: false,
    mode: null,
  });

  async function loadEstimationDivision(id) {
    const res = await getEstimateDivisions(id);
    console.log(res);
    setEstimationDivisions(res);
  }
  useEffect(() => {
    loadEstimationDivision(id);
  }, []);
  return (
    <div
      style={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: 10,
          display: "flex",
          gap: 10,
          overflow: "scroll",
          marginTop: 20,
        }}
      >
        <Button
          type="primary"
          onClick={(e) => setDivisionDrawer((pv) => ({ ...pv, open: true }))}
        >
          + Add Division
        </Button>
        {estimationDivisions.length === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            No divisions available Please Create one
          </div>
        )}
        {estimationDivisions?.map((d, i) => (
          <Button
            key={"d" + i}
            onClick={(e) => {
              console.log("selecting", d.id);
              setSelectedEstimationDivision(d.id);
            }}
            type={selectedestimationDivision === d.id ? "primary" : "default"}
          >
            {d.title}
          </Button>
        ))}
      </div>
      <div
        style={{
          flex: 1,

          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Division divisionId={selectedestimationDivision} />
      </div>

      {/* --------------------------------------------------------------- */}
      <Drawer
        onClose={(e) => setDivisionDrawer((pv) => ({ ...pv, open: false }))}
        title={"Create Division"}
        open={divisionDrawer.open}
        style={{ display: "flex" }}
      >
        <div style={{ flex: 1 }}>
          <Input
            value={newTitle}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
            placeholder="Enter Title for Division"
          />
        </div>
        <div style={{ marginTop: 20, width: "100%" }}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={async (e) => {
              await addDivision(id, {
                title: newTitle,
                quote_id: id,
                created_at: "2024-02-15T10:00:00Z",
                updated_at: "2024-02-15T10:00:00Z",
              });
              setNewTitle("");
              setDivisionDrawer((pv) => ({ ...pv, open: false }));
              loadEstimationDivision(id);
            }}
          >
            Create
          </Button>
        </div>
      </Drawer>
    </div>
  );
}
