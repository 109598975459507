import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { useQuote } from "./Quote.context";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

const DataTable = ({ data, onEdit }) => {
  const [tableData, setTableData] = useState([]);
  const navigator = useNavigate();
  const { quotes } = useQuote();
  //   useEffect(() => {
  //     // Transform data for table if necessary
  //     setTableData(data);
  //   }, [data]);

  const columns = [
    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Customer</span>,
      dataIndex: "customer",
      key: "customerId",
      render: (c) => c?.name,
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Project</span>,
      dataIndex: "project",
      key: "projectId",
      render: (r) => r?.name,
    },
    {
      title: (
        <span style={{ fontSize: 10, color: "$555" }}>Quote Received Date</span>
      ),
      dataIndex: "received_date",
      key: "quoteReceivedDate",
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY hh:mm") : "--"),
    },
    {
      title: (
        <span style={{ fontSize: 10, color: "$555" }}>Quote Due Date</span>
      ),
      dataIndex: "due_date",
      key: "quoteDueDate",
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY hh:mm") : "--"),
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Remark</span>,
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}>MTO</span>,
      dataIndex: "mto",
      key: "mto",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: (
        <span style={{ fontSize: 10, color: "$555" }}>Connection Design</span>
      ),
      dataIndex: "connectionDesign",
      key: "connectionDesign",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: <span style={{ fontSize: 10, color: "$555" }}>Action</span>,
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          {" "}
          <Button size="small" onClick={() => onEdit(record, "estimate")}>
            Estimate
          </Button>
          <Button size="small" onClick={() => onEdit(record, "pricing")}>
            Pricing
          </Button>
          <Button size="small" onClick={() => onEdit(record, "edit")}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      dataSource={quotes}
      columns={columns}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            console.log(record);
            navigator("/quotes/" + record.id);
          },
        };
      }}
      rowKey="id"
      pagination={false}
    />
  );
};

export default DataTable;
