import { Button, DatePicker, Checkbox } from "antd";
import { useEffect, useState } from "react";
import User from "../../../../bu/actions/Users";
import { useAppState } from "../../../../state"
export default function ProjectSidebar({
  openCreateProject,
  openAllocateProjectDrawer,
  openAllocateWorkDrawer,
  tl,
  filters,
}) {
  const [state] = useAppState();
  const [leads, setLeads] = useState([]);
  async function loadLeads() {
    const leads = await User.getAllLeads(1000);
    setLeads(leads.data.map((d) => ({ ...d, selected: false })));
  }
  useEffect(() => {
    loadLeads();
  }, []);
  return (
    <div
      style={{
        width: 180,
        background: "#fefefe",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        padding: 10,
      }}
    >
      <div style={{ width: "100%", marginBottom: 10 }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={(e) => openCreateProject()}
        >
          Create Project
        </Button>
      </div>
      <div style={{ width: "100%", marginBottom: 10 }}>
        <Button
          style={{ width: "100%" }}
          onClick={(e) => openAllocateProjectDrawer()}
        >
          Allocate Project
        </Button>
      </div>
      <div style={{ width: "100%", marginBottom: 10 }}>
        <Button
          style={{ width: "100%" }}
          onClick={(e) => openAllocateWorkDrawer()}
        >
          Allocate Work
        </Button>
      </div>
      <div style={{ fontSize: 12, colr: "#333", padding: 10 }}>Filters:</div>
      <div style={{ padding: 10 }}>
        <div style={{ fontSize: 12, colr: "#333", padding: 0, color: "#333" }}>
          From Date:
        </div>
        <DatePicker
          style={{ width: "100%" }}
          value={filters.values.fromDate}
          onChange={(e) => filters.update("fromDate", e)}
        />
      </div>
      <div style={{ padding: 10 }}>
        <div style={{ fontSize: 12, colr: "#333", padding: 0, color: "#333" }}>
          End Date:
        </div>
        <DatePicker
          style={{ width: "100%" }}
          value={filters.values.endDate}
          onChange={(e) => {
            filters.update("endDate", e);
            console.log(e, filters);
          }}
        />
      </div>
      {!state?.user?.groups?.includes("GROUP_TEAM_LEAD") &&
        <div style={{ padding: 10, paddingTop: 0 }}>
          <div style={{ fontSize: 12, color: "#444" }}>Team Lead:</div>
          <div style={{ marginTop: 5, maxHeight: 500, overflowY: "scroll" }}>
            {tl?.list?.map((d, i) => (
              <div
                key={"lead-" + i}
                style={{
                  textTransform: "capitalize", fontSize: 12, textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    let _leads = [...tl?.list];
                    _leads[i].selected = e.target.checked;
                    tl?.update(_leads);
                  }}
                />{" "}
                {String(d?.profile?.first_name + ' ' + d?.profile?.last_name).toUpperCase()}
              </div>
            ))}
          </div>
        </div>}
      <div>
        <div style={{ fontSize: 12, color: "#444" }}>Status:</div>
        <div>
          <Checkbox
            onChange={(e) => {
              console.log(e.target.checked);

              filters.update("allocated", e.target.checked);
            }}
            defaultChecked={filters.values.allocated}
          />
          <span style={{ marginLeft: 10, fontSize: 14 }}>Allocated</span>
        </div>
        <div>
          <Checkbox
            onChange={(e) => {
              filters.update("notAllocated", e.target.checked);
            }}
            checked={filters.values.notAllocated}
          />
          <span style={{ marginLeft: 10, fontSize: 14 }}>Not Allocated</span>
        </div>
      </div>
    </div>
  );
}
