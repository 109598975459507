import "../../../../index.css";
import PageHeader from "../../../../components/PageHeader";
import { Button, Drawer, Input, DatePicker, Select, Table } from "antd";
import { useEffect, useState } from "react";
import TimeEntrySidebar from "./TimeEntrySidebar";
import useTimeEntryServices from "./TimeEntryServices";
import TimeSheetCreateDrawer from "./TimeSheetCreateDrawer";
import Domain from "../../../../bu/actions/Domain";
import { get } from "../../../../api/request";
import Timeentries from "../../../../bu/actions/Timeentries";
import dayjs from 'dayjs'
const { TextArea } = Input;

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function TimesheetsPage() {
  const [scrollBottom, setScrollBottom] = useState(false);
  const timesheetServices = useTimeEntryServices();
  const [domains, setDomains] = useState([]);
  const [projects, setProject] = useState([]);
  const [projectTimeentries, setProjectTimeentries] = useState([]);
  
  const { filter } = timesheetServices
  useEffect(() => {
    timesheetServices.loadTimeEntries();
    loadDomain()
    loadProject()
    //loadTimeEntries()
  }, []);

  async function loadTimeEntries(filters) {
  try {
   
    let searchMap ={}
   if(!filters){
     searchMap = {
      user_id: parseInt(
        JSON.parse(localStorage.getItem("userState"))?.user?.user_id
      ),
    };
   } else{
    searchMap = filters
   }
   
    const queryParams = {
      sort: "created_at",
      sortOrder: "desc",
      limit: 1000,
    };
    queryParams.searchMap = JSON.stringify(searchMap);
    const tres = await Timeentries.readByUserId(
      JSON.parse(localStorage.getItem("userState"))?.user?.user_id,
      queryParams
    );
    console.log(tres);
    setProjectTimeentries(tres.data || []);
  } catch(e) {
    setProjectTimeentries([]);
  }
}


  async function loadDomain() {
    try {
      const dRes = await Domain.readAll({ limit: 1000 });
      setDomains(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadProject() {
    try {
      const res = await get(`project?limit=1000`);
      setProject(res.data || []);
      // setProjectTimeentries(res2.data || []);
      // setSelectedUser(0)
    } catch (e) {
      console.log(e);
    }
  }

  const [selectedProject, setSelectedProject] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  function handleFilterChange(filterType, value) {
    console.log(value);
  
    try {
      switch (filterType) {
        case 'project_id':
          if(value === 'all'){
            setSelectedProject(undefined);
            value = undefined;
          } else {
            setSelectedProject(value);
          }
          break;
        case 'domain_id':
          if(value === 'all'){
            setSelectedDomain(undefined);
            value = undefined;
          } else {
            setSelectedDomain(value);
          }
          break;
        case 'date_search':
          setSelectedDateRange(value);
          break;
        default:
          break;
      }
  
      let filters = {
        user_id: parseInt(JSON.parse(localStorage.getItem("userState"))?.user?.user_id),
        project_id: filterType === 'project_id' ? value : selectedProject,
        domain_id: filterType === 'domain_id' ? value : selectedDomain,
      };
  
      if (filterType === 'date_search') {
        const dateSearch = createDateSearch(value);
        if (dateSearch) {
          filters.dateSearch = dateSearch;
        }
      } else {
        const dateSearch = createDateSearch(selectedDateRange);
        if (dateSearch) {
          filters.dateSearch = dateSearch;
        }
      }
  
      //loadTimeEntries(filters);
      timesheetServices.loadTimeEntries(filters);
    } catch (error) {
      console.error(error);
      setProjectTimeentries([]);
    }
  }
  
  function createDateSearch(value) {
    if (!value || value.length !== 2) {
      console.error("Invalid range provided.");
      return null;
    }
  
    const [startDate, endDate] = value;
    const start_date = new Date(startDate);
    start_date.setHours(0, 0, 0, 0);
  
    const end_date = new Date(endDate);
    end_date.setHours(23, 59, 59, 999);
  
    const formattedStartDate = new Date(start_date.getTime() - (start_date.getTimezoneOffset() * 60000)).toISOString().slice(0, -5) + "Z";
    const formattedEndDate = new Date(end_date.getTime() - (end_date.getTimezoneOffset() * 60000)).toISOString().slice(0, -5) + "Z";
  
    return ["start_date", formattedStartDate, formattedEndDate];
  }
  

  const columns = [
    {
      title: '',
      children: [
        {
          title: 'Project',
          dataIndex: ['project', 'name'],
          key: 'name',
          // render: (text, record) => `${record.user.profile.first_name} ${record.user.profile.last_name}`,
        },
        {
          title: 'Lead',
          dataIndex: ['project', 'name'],
          key: 'name',
          render: (text, record) => `${record.project?.assigned_to?.profile?.first_name} ${record.project?.assigned_to?.profile?.last_name}`,
        },
      ]

    },
    {
      title: 'Allocated Work',
      children: [
        {
          title: 'Domain',
          dataIndex: ['allocated_work', 'domain', 'name'],
          key: 'domain',
          render: (text, record) => {
            return <span style={{ color: 'green' }}>{text}</span>;
          },
        },
        {
          title: 'Activity',
          dataIndex: ['allocated_work', 'activity', 'name'],
          key: 'activity',
          render: (text, record) => {
            return <span style={{ color: 'green' }}>{text}</span>;
          },
        },
        {
          title: 'Quantity',
          dataIndex: ['allocated_work', 'quantity'],
          key: 'quantity',
          render: (text, record) => {
            return <span style={{ color: 'green' }}>{text}</span>;
          },
        },
        {
          title: 'Start Time',
          dataIndex: ['allocated_work', 'start_date'],
          key: 'start_date',
          render: (start_date) => {
            return <span style={{ color: 'green' }}>{dayjs(start_date).format('DD/MM/YYYY h:mm A')}</span>;
          },
        },
        {
          title: 'End Time',
          dataIndex: ['allocated_work', 'end_date'],
          key: 'end_date',
          render: (end_date) => {
            return <span style={{ color: 'green' }}>{dayjs(end_date).format('DD/MM/YYYY h:mm A')} </span>;
          },
        },
        {
          title: 'Duration',
          dataIndex: ['allocated_work', 'duration'],
          key: 'duration',
          render: (text, record) => {
            return <span style={{ color: 'green' }}>{text}</span>;
          },
        },
      ],
    },
    {
      title: 'Actual Work',
      children: [
        {
          title: 'Description',
          dataIndex: ['description'],
          key: 'description',
        },
        {
          title: 'Domain',
          dataIndex: ['domain', 'name'],
          key: 'domain',
        },
        {
          title: 'Activity',
          dataIndex: ['activity', 'name'],
          key: 'activity',
        },
        {
          title: 'Quantity',
          dataIndex: ['quantity'],
          key: 'quantity',
        },
        {
          title: 'Start Time',
          dataIndex: ['start_time'],
          key: 'start_time',
          render: (start_time, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = record?.duration;
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{dayjs(start_time).format('DD/MM/YYYY h:mm A')}</span>;
          },
        },
        {
          title: 'End Time',
          dataIndex: ['end_time'],
          key: 'end_time',
          render: (end_time, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = record?.duration;
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{dayjs(end_time).format('DD/MM/YYYY h:mm A')}</span>;
          },
        },
        {
          title: 'Duration',
          dataIndex: ['duration'],
          key: 'duration',
          render: (duration, record) => {
            const allocatedDuration = record?.allocated_work?.duration;
            const actualDuration = duration;
            const durationStyle = {
              color: Number(actualDuration) > Number(allocatedDuration) ? 'red' : 'inherit',
            };
            return <span style={durationStyle}>{duration}</span>;
          },
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          key: 'actions',
          render: (_, record) => (
            <div>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  console.log(record.id); // Assuming `id` is the identifier for the record
                  timesheetServices.drawer.edit(record.id);
                }}
              >
                Edit
              </Button>
            </div>
          ),
        },
      ],
    },
  ]

  const [filter1, setFilter] = useState({ startTime: null });

  const filteredData = timesheetServices.timeEntries.filter(entry => {
    if (!filter.startTime && !filter.domain && !filter.project) {
        return true; // If no startTime, domain, or project is provided, include all entries
    } else {
        const entryDate = dayjs(entry.start_date);

        // Check startTime filter
        if (filter.startTime && !entryDate.isSame(dayjs(filter.startTime), 'day')) {
            return false;
        }

        // Check domain filter
        if (filter.domain && entry.domain_id !== filter.domain) {
            return false;
        }

        // Check project filter
        if (filter.project && entry.project_id !== filter.project) {
            return false;
        }

        // If the entry passed all filters, return true
        return true;
    }
});


  // console.log(filteredData)
  return (
    <div className="page-container page-v-split">
      {/* <TimeEntrySidebar timesheetServices={timesheetServices} /> */}
      <div
        className="page-body"
        style={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageHeader pageTitle={"Time Sheet"} />

        <div style={{ width: "100%", marginBottom: 10 }}>
        <Button
          type="primary"
         
          onClick={(e) => timesheetServices.drawer.open()}
        >
          + Add Time Entry
        </Button>
      </div>
        <div style={{ display: "flex", padding: "10px", borderBottom: "2px solid #000000" }}>
          <div style={{ margin: '10px' }}> Projects :
            {projects.length > 0 && (
              <Select
                placeholder="Select Project"
                // onChange={filter.FilterProject}
                onChange={(value) => handleFilterChange('project_id', value)}
                style={{ width: 200 }}
                defaultValue={'All'}
              >
                <Option key="all" value="all">All</Option>
                {projects.map(project => (
                  <Option key={project?.id} value={project?.id}>
                    {project?.name}
                  </Option>
                ))}
              </Select>
            )}

          </div>

          <div style={{ margin: '10px' }}> Domain :
            <Select
              placeholder="Select Domain"
              // onChange={filter.FilterDomain}
              onChange={(value) => handleFilterChange('domain_id', value)}
              style={{ width: 200 }}
              defaultValue={'All'}
            >
              <Option key="all" value="all">All</Option>
              {domains.map(domain => (
                <Option key={domain?.id} value={domain?.id}>
                  {domain?.name}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: '10px' }}> Actual Work Start Date :
            {/* <RangePicker  onChange={filter.updateStartTime} /> */}
            <RangePicker onChange={(value) => handleFilterChange('date_search', value)} />
           </div>
        </div>
        <div style={{ overflow: "scroll" }}>
          <Table
            columns={columns}
            dataSource={timesheetServices.timeEntries}
            bordered
            size="middle"
            pagination={false}
          />
        </div>
        <TimeSheetCreateDrawer timesheetServices={timesheetServices} />
      </div>
    </div>
  );
}
