import "./index.css";
const monthsStr = [];
function dateFormate(ds) {
  const dt = new Date(ds);
  return dt.getDate() + "-" + (dt.getMonth() + 1) + "-" + dt.getFullYear();
}
function dateDiffInDays(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
}
function ProjectsCard({ project }) {
  return (
    <div
      className="order-ticket-container"
      style={{ padding: 10, borderRadius: 5 }}
    >
      <div
        className="order-ticket-container-section1"
        style={{
          display: "flex",
          alignItems: "center",
          //   justifyContent: "space-around",
        }}
      >
        <div
          className="order-ticket-title-container"
          style={{
            textAlign: "start",
            border: "0px solid red",
            marginRight: 20,
            flex: 1,
          }}
        >
          <h3
            style={{
              margin: 0,
              fontWeight: 600,
              color: "#444",
              letterSpacing: 0.5,
              textTransform: "capitalize",
            }}
          >
            <div style={{ marginBottom: 5 }}> {project.name} </div>
            <div style={{ fontSize: 12, color: "#777" }}>
              (USICAP{project.seq_number})
            </div>
          </h3>
          <h4
            style={{
              margin: 3,
              marginLeft: 0,
              color: "#888",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Team Lead:
            <span style={{ color: "#333", fontWeight: 600 }}>
              {" "}
              {project.assigned_to?.profile?.first_name}
              {project.assigned_to?.profile?.last_name}
            </span>
          </h4>
        </div>

        <div
          className="order-ticket-time"
          style={{
            display: "flex",
            alignItems: "center",
            // flex: 1,
            // border: "1px solid red",
          }}
        >
          <div
            className="order-ticket-arival-time-containerf"
            style={{
              textAlign: "start",

              marginRight: 20,
            }}
          >
            <h2 style={{ margin: 0, fontSize: 14 }}>
              {dateFormate(project?.starts_at)}
            </h2>
            <h3
              style={{
                margin: 0,
                color: "#666",
                fontWeight: 300,
                fontSize: 14,
              }}
            >
              Start Date
            </h3>
          </div>
          <div className="order-ticket-time-hours-container" style={{}}>
            <h4
              style={{
                color: "rgb(66 63 63)",
                fontWeight: 500,
                margin: 0,
                border: "0px solid red",
                textAlign: "center",
                // marginTop: 15,
              }}
            >
              ●--------{dateDiffInDays(project?.starts_at, project?.ends_at)}{" "}
              Days --------●
            </h4>
            <h3
              style={{
                fontWeight: 500,
                margin: 0,
                fontSize: 14,
                textAlign: "center",
                color: "#888",
              }}
            >
              Remaining
            </h3>
          </div>
          <div style={{}} className="order-ticket-departure-time-container">
            <h2 style={{ margin: 0, fontSize: 14 }}>
              {dateFormate(project?.ends_at)}
            </h2>
            <h3
              style={{
                margin: 0,
                color: "#666",
                fontWeight: 300,
                textAlign: "end",
                fontSize: 14,
              }}
            >
              End Date
            </h3>
          </div>
        </div>
        <div
          className="order-ticket-icon-container"
          style={{ display: "flex", gap: 10 }}
        >
          {/* <TbBell style={{ height: 27, width: 27 }} />
          <FiShare2 style={{ height: 27, width: 27 }} /> */}
        </div>
      </div>
      <div
        className="order-ticket-container-section2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          //   paddingInline: 30,
        }}
      >
        <div style={{ marginTop: 10 }}>
          <div
            style={{
              color: project.status === "ALLOCATED" ? "green" : "#ff4827",
              marginRight: 10,
              fontWeight: 500,
              fontSize: 12,
            }}
          >
            {project.status}
          </div>
          <div
            style={{
              textDecoration: "none",
              color: "#222",
              fontWeight: 400,
              fontSize: 12,
            }}
            href="#"
          >
            Details: {project.description}
          </div>
        </div>
        <span style={{ display: "flex" }}>
          <h4 style={{ color: "#f75c40" }}></h4>
          <h4 style={{ color: "#8f8888", fontWeight: 500 }}></h4>
        </span>
      </div>
      <div
        className="order-ticket-container-section3"
        style={{
          display: "flex",

          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
          <span></span>
        </div>
        {/* <button className="order-ticket-select-btn">Select</button> */}
        <div style={{ gap: 5, display: "flex", display: "none" }}>
          <button
            style={{
              fontSize: 12,
              background: "#fff",
              border: "1px solid #888",
              borderRadius: 4,
              padding: "3px 10px",
            }}
          >
            Allocate Project
          </button>
          <button
            style={{
              fontSize: 12,
              background: "#fff",
              border: "1px solid #888",
              borderRadius: 4,
              padding: "3px 10px",
            }}
          >
            Allocate Work
          </button>
        </div>
      </div>
    </div>
  );
}
export default ProjectsCard;
