import { createContext, useContext, useState } from "react"
export const AppStateContext = createContext({})

function getInitialState() {
    try {
        const stateFromLS = JSON.parse(localStorage.getItem('userState'))
        return stateFromLS || {}
    } catch (error) {
        return {}
    }

}

export function AppProvider({ children }) {
    const value = useState(getInitialState)
    console.log("AppProvider", value)
    localStorage.setItem('userState', JSON.stringify(value[0]))
    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    )
}

export function useAppState() {
    const context = useContext(AppStateContext)
    if (!context) {
        throw new Error("useAppState must be used within the AppProvider")
    }
    return context
}
