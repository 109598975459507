import { useState } from "react";
import Timeentries from "../../../../bu/actions/Timeentries";

export default function useTimeEntryServices() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [drawer, setDrawer] = useState({
    editId: null,
    editMode: false,
    close: () => {
      setDrawer({ ...drawer, isOpen: false });
    },
    open: () => {
      setDrawer({ ...drawer, isOpen: true });
    },
    edit: (id) => {
      console.log("edit invoked", id);
      setDrawer({ ...drawer, editMode: true, isOpen: true, editId: id });
    },
    isOpen: false,
  });
  const [filterStartTime, setFilterStartTime] = useState(null)
  
  async function loadTimeEntries( filters) {
    try {
      console.log(
        localStorage.getItem("userState"),
        JSON.parse(localStorage.getItem("userState"))
      );
      let searchMap ={}
      if(!filters){
        searchMap = {
         user_id: parseInt(
           JSON.parse(localStorage.getItem("userState"))?.user?.user_id
         ),
       };
      } else{
       searchMap = filters
      }
      
      // const searchMap = {
      //   user_id: parseInt(
      //     JSON.parse(localStorage.getItem("userState"))?.user?.user_id
      //   ),
      // };
      const queryParams = {
        sort: "created_at",
        sortOrder: "desc",
        limit: 1000,
      };
      queryParams.searchMap = JSON.stringify(searchMap);
      const tres = await Timeentries.readByUserId(
        JSON.parse(localStorage.getItem("userState"))?.user?.user_id,
        queryParams
      );
      console.log(tres);
      setTimeEntries(tres.data);
    } catch (e) {
      console.log(e);
    }
  }
  return {
    timeEntries,
    loadTimeEntries,
    drawer,
    filter: {
      startTime: filterStartTime,
      updateStartTime: (v) => { setFilterStartTime(v) },
    }

  };
}
