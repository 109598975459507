import { get, post } from "../../../../api/request";

export const estimateDivision = [
  {
    id: 1,
    title: " Division 1",
    quote_id: 1,
    created_at: "2024-02-15T10:00:00Z",
    updated_at: "2024-02-15T10:00:00Z",
  },
];
export const estimate = [];
export const primaryEstimation = [
  {
    id: 1,
    estimation_id: 1,
    assembly: "COLUMNS",
    profile_type: "WF",
    quantity: 10,
    duration_in_mins: 900.0,
    weight: 500.0,
    repeatability: 5,
    complexity: 1.1,
    remark: "Estimation for main structural components.",
    created_at: "2024-02-15T12:00:00Z",
    updated_at: "2024-02-15T12:00:00Z",
  },
];
export const secondaryEstimation = [];

export const assembly = [];

export async function getEstimateDivisions(quoteId) {
  const res = await get(`quote/${quoteId}/estimate-division`);
  console.log(res);
  return res.data || [];
}
export async function getEstimete(divisionId) {
  const res = await get(`estimate-division/${divisionId}/estimate`);
  return res.data;
}
export async function getPrimaryEstimation(estimationId) {
  const res = await get(`estimate/${estimationId}/primary-estimation`);
  console.log(res);
  return res.data || [];
}
export async function getSecondaryEstimation(estimationId) {
  return secondaryEstimation.filter((d) => d.estimation_id === estimationId);
}
export async function addDivision(quoteId, data) {
  console.log(data);
  // return estimateDivision.push({ ...data, id: estimateDivision.length + 1 });
  // return estimate.push({ ...data, id: estimate.length + 1 });
  const res = await post(`quote/${quoteId}/estimate-division`, data);
  console.log(res);
  getEstimateDivisions(quoteId);
}
export async function addEstimation(quoteId, data) {
  console.log(data);
  // return estimate.push({ ...data, id: estimate.length + 1 });
  const res = await post(`estimate-division/${quoteId}/estimate`, data);
  console.log(res);
  getEstimateDivisions(quoteId);
  // return res.data || [];
}
export async function addActivity(data) {
  console.log(data);
  return primaryEstimation.push({ ...data, id: primaryEstimation.length + 1 });
}
