import React, { useState, useEffect } from "react";
import { Button, Drawer, Input, Form ,Select} from "antd";
// import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import Activities from "../../bu/actions/Activities";
import Domain from "../../bu/actions/Domain";


const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;

export default function CreateActivity({ messageApi, onCreateActivityCloseDrawer, emitter, isUpdate, recordToUpdate }) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [subActivities, setSubActivities] = useState([
    { name: "", description: "" },
  ]);

  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [submitActivity, setSubmitActivity] = useState(false)

  const [domains, setDomains] = useState([]);
  const handleCreateTask = async () => {
    if (subActivities.length > [...new Set(subActivities.map(eachSubActivity => eachSubActivity?.name?.trim()))].length) {
      console.log('handleCreateTask 2', [...new Set(subActivities.map(eachSubActivity => eachSubActivity?.name?.trim()))]);
      messageApi.open({
        type: 'error',
        content: 'Two or more subactivities have the same name',
      });
      setSubmitActivity(false)
      return
    }
    try {
      const values = await form.validateFields();

      if (isUpdate) {
        console.log('isUpdate update', recordToUpdate)
        await Activities.update({
          id: recordToUpdate.id,
          name: values.Name,
          description: values.Description,
          domain_id : values.Domain,
          subActivities,
        });
      } else {
        await Activities.create({
          name: values.Name,
          description: values.Description,
          domain_id : values.Domain,
          subActivities,
        });
      }
      messageApi.open({
        type: 'success',
        content: `Activity successfully ${isUpdate ? "updated" : "created"}`,
      });
      onCreateActivityCloseDrawer();
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: 'error',
        content: error?.response?.data?.error || `Error ${isUpdate ? "updating" : "creating"} task`,
      });
    } finally {
      setSubmitActivity(false)
    }
  };

  useEffect(() => {
    if (isUpdate) {
      form.setFieldsValue({ "Name": recordToUpdate.name || "", "Description": recordToUpdate.description || "" ,"Domain": recordToUpdate.domain_id || ""})
      setSubActivities(recordToUpdate.sub_activities || [])
      const selectedDomain = domains.find(domain => domain.id === recordToUpdate.name);
      if (selectedDomain) {
        setDomains([selectedDomain]); // Set selected domain to domains state
      }
    }
  }, [form, isUpdate, domains,recordToUpdate, recordToUpdate.description, recordToUpdate.name,recordToUpdate.Domain, recordToUpdate.subActivities,recordToUpdate.domain_id])

  useEffect(() => {
    // Listen for the custom event
    emitter.on('create-activity', () => setSubmitActivity(true));
    emitter.on('clear-activity-form', () => {
      form.setFieldsValue({ "Name": "", "Description": "", "Domain" : "" })
      setSubActivities([])
    });

    return () => {
      // Remove the event listener when the component unmounts
      emitter.off('create-activity', () => setSubmitActivity(true));
    };
  }, [form, onCreateActivityCloseDrawer, emitter, subActivities, messageApi, isUpdate, recordToUpdate.id, recordToUpdate]);


  useEffect(() => {
    if (submitActivity) {
      handleCreateTask()
    }
  }, [submitActivity])

  const onClose = () => {
    setOpen(true);
    form.resetFields();
  };
  const handleRemoveSubActivity = (indexToRemove) => {
    const updatedActivities = subActivities.filter(
      (_, index) => index !== indexToRemove
    );
    setSubActivities(updatedActivities);
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      setTaskName(values.Name); // Update taskName with the form input 'Name'
      setTaskDescription(values.Description); // Update taskDescription with the form input 'Description'
      setOpen(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };


  const addSubActivity = () => {
    setSubActivities([...subActivities, { name: "", description: "" }]);
  };

  const handleSubActivityChange = (index, key, value) => {
    const updatedActivities = [...subActivities];
    updatedActivities[index][key] = value;
    setSubActivities(updatedActivities);
  };

  useEffect(() => {
    loadDomain()
  },[])
  async function loadDomain() {
    try {
      const dRes = await Domain.readAll({ limit: 1000 });
      setDomains(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div >
      <div>
        <Form form={form} {...formItemLayout}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
            <h2
                style={{
                  fontFamily: "roboto",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                }}
              >
                Domain
              </h2>
            <Form.Item
            name="Domain"
            // label="Domain"
            style={{ flex: 1 }}
            // rules={[{ required: true, message: 'Please select a domain!' }]}
          >
            
              <Select
                size={"small"}
                placeholder="Select Domain"
                value={domains}
                onChange={(value) => {
                  // Handle onChange event here
                  console.log(value);
                  form.setFieldsValue({ "Domain": value });
                  //setDomains(value)
                }}
              >
                {domains.map((domain, i) => (
                  <Select.Option key={domain.id} value={domain.id}>
                    {domain.name}
                  </Select.Option>
                ))}
              </Select>
            
          </Form.Item>
          </div>
          <div style={{ flex: 1 }}>
              <h2
                style={{
                  fontFamily: "roboto",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                }}
              >
                Name
              </h2>
              <Form.Item
                name="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the Activity name",
                  },
                ]}
              >
                <Input
                  placeholder="Activity name"
                  value={taskName}
                  onChange={(e) => {
                    setTaskName(e.target.value);
                  }}
                  style={{ width: "267px" }}
                />
              </Form.Item>
            </div>
         
          </div>
          <div style={{ flex: 1 }}>
              <h2
                style={{
                  fontFamily: "roboto",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                }}
              >
                Description
              </h2>
              <Form.Item
                name="Description"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input the Activity description",
              //   },
              // ]}
              >
                <TextArea
                  showCount
                  maxLength={100}
                  placeholder="Autosize height based on content lines"
                  value={taskDescription}
                  onChange={(e) => {
                    setTaskDescription(e.target.value);
                  }}
                  style={{ width: "267px" }}
                />
              </Form.Item>
            </div>
          <div
            style={{
              fontFamily: "roboto",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "22px",
            }}
          >
            Sub Activities
            <div
              style={{
                textAlign: "end",
                marginTop: "40px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#8686ff",
                  width: "112.5px",
                  height: "32px",
                  borderRadius: "6px",
                }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={addSubActivity}
              >
                Add Row
              </Button>
            </div>
            {subActivities.map((subActivity, index) => (
              <div key={index} style={{ display: "flex", paddingTop: index === 0 ? "30px" : "10px" }}>
                <div style={{ flex: 1 }}>
                  <h2
                    style={{
                      fontFamily: "roboto",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      color: index === 0 ? "black" : "white"
                    }}
                  >
                    Name
                  </h2>
                  <Input
                    placeholder="Sub-activity name"
                    value={subActivity.name}
                    onChange={(e) =>
                      handleSubActivityChange(index, "name", e.target.value)
                    }
                    style={{ width: "267px" }}
                  />
                </div>
                <div style={{ flex: 1, paddingLeft: '50px' }}>
                  <h2
                    style={{
                      fontFamily: "roboto",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      color: index === 0 ? "black" : "white"
                    }}
                  >
                    Description
                  </h2>
                  <TextArea
                    showCount
                    maxLength={100}
                    placeholder="Autosize height based on content lines"
                    value={subActivity.description}
                    onChange={(e) =>
                      handleSubActivityChange(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                    style={{ width: "267px" }}
                  />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <h2
                    style={{
                      fontFamily: "roboto",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      color: index === 0 ? "black" : "white"
                    }}
                  >
                    Action
                  </h2>
                  <MinusCircleOutlined
                    style={{ fontSize: "25px", paddingLeft: "10px" }}
                    onClick={() => handleRemoveSubActivity(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Form>
      </div>
    </div>
  );
}
