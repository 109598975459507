import { usePo } from "./Cor.context";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import { get } from "../../../../api/request";
import { useEffect, useState } from "react";
export default function CustomersList() {
  const { POs, drawerService, searchKey } = usePo();

  // const columns = [
  //   {
  //     // title: "Customer Name",
  //     title: <span style={{ fontSize: 10, color: "$555" }}>seq_number </span>,
  //     dataIndex: "seq_number",
  //     key: "customerName",
  //   },
  //   {
  //     title: <span style={{ fontSize: 10, color: "$555" }}> Project Name</span>,
  //     dataIndex: "projectName",
  //     key: "projectName",
  //     render: (text, row) => {
  //       return <span>{row?.project?.name || '-'}</span>;
  //     },
  //   },
  //   {
  //     title: <span style={{ fontSize: 10, color: "$555" }}> cor_amount</span>,
  //     dataIndex: "cor_amount",
  //     key: "poNumber",
  //   },

  //   {
  //     title: (
  //       <span style={{ fontSize: 10, color: "$555" }}> Total PO Amount</span>
  //     ),
  //     dataIndex: "totalPOAmount",
  //     key: "totalPOAmount",
  //   },
  //   {
  //     title: (
  //       <span style={{ fontSize: 10, color: "$555" }}>cor_approved Date</span>
  //     ),
  //     dataIndex: "cor_approved_date",
  //     key: "poDate",
  //     render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
  //   },
  //   {
  //     title: <span style={{ fontSize: 10, color: "$555" }}>Actions</span>,
  //     dataIndex: "poDate",
  //     key: "poDate",
  //     render: (text, d) => (
  //       <div style={{ display: "flex" }}>
  //         <Button
  //           style={{ fontSize: 12 }}
  //           size="small"
  //           onClick={(e) => {
  //             console.log(d.cor, d);
  //             drawerService.openToEdit(d.cor, d);
  //           }}
  //         >
  //           Edit
  //         </Button>
  //       </div>
  //     ),
  //   },
  // ];
  const columns = [
    {
      // title: "Customer Name",
      title: <span style={{ fontSize: 10, color: '$555' }}>Customer Name</span>,
      dataIndex: 'customerName',
      key: 'customerName',
      render: (text, row) => {
        return <span>{row?.quote?.customer?.name || '-'}</span>;
      },
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Quote No</span>,
      dataIndex: 'quoteNo',
      key: 'quoteNo',
      render: (text, row) => {
        return <span>{row?.quote?.seq_number || '-'}</span>;
      },
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> PO Number</span>,
      dataIndex: 'po_number',
      key: 'po_number',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>PO Date</span>,
      dataIndex: 'poDate',
      key: 'poDate',
      render: (text) => dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Detailing</span>,
      dataIndex: 'detailing_amount',
      key: 'detailing_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Design</span>,
      dataIndex: 'design_amount',
      key: 'design_amount',
    },
    {
      title: (
        <span style={{ fontSize: 10, color: '$555' }}> Design Vendor</span>
      ),
      dataIndex: 'design_vendor_amount',
      key: 'design_vendor_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>C to Usica</span>,
      dataIndex: 'c_to_usica',
      key: 'c_to_usica',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>C to Client</span>,
      dataIndex: 'c_to_client',
      key: 'c_to_client',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}> Total PO Amount</span>,
      dataIndex: 'revised_po_amount',
      key: 'revised_po_amount',
    },
    {
      title: <span style={{ fontSize: 10, color: '$555' }}>Actions</span>,
      dataIndex: 'poDate',
      key: 'poDate',
      render: (text, d) => (
        <div style={{ display: 'flex' }}>
          <Button
            style={{ fontSize: 12 }}
            size="small"
            onClick={(e) => {
              console.log(d.id, d);
              drawerService.openToEdit(d.id, {
                ...d,
                po_date: dayjs(d.po_date),
                customerName: d.quote.customer.id,
                quote_id: d.quote.id,
              });
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    // <div style={{ flex: 1, overflow: "scroll" }}>
    //   <Table
    //     dataSource={POs}
    //     columns={columns}
    //     pagination={false} // Set to false if pagination is not needed
    //     bordered
    //     style={{ width: "100%", padding: "20px" }}
    //     className="custom-table"
    //     rowKey="poNumber" // Assuming 'poNumber' is unique
    //   />
    // </div>
    <div style={{ flex: 1, overflow: "scroll" }}>
     {POs && POs.length > 0 ? (
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              <div style={{ display: "flex", background: "#f1f1f1" }}>
                <div style={{ flex: 1, padding: 2 }}>{"Sequence Number"}</div>
                <div style={{ flex: 1, padding: 2 }}>{"Cor Amount"}</div>
                <div style={{ flex: 1, padding: 2 }}>{"Cor approved Date"}</div>
              </div>
              {record?.quote?.cor?.length > 0 ? (
                record?.quote?.cor.map((contact, i) => (
                  <div key={"contact" + i} style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: 2 }}>{contact?.seq_number}</div>
                    <div style={{ flex: 1, padding: 2 }}>{contact?.cor_amount}</div>
                    <div style={{ flex: 1, padding: 2 }}>{contact?.cor_approved_date}</div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center", padding: 10 }}>No Cor available</div>
              )}


            </p>
          ),
          rowExpandable: (record) => record?.POs?.name !== 'Not Expandable',
        }}
        dataSource={POs.map(d => ({ ...d, key: d.id }))}
        pagination={false}

      />
      ) : (
        <div style={{ textAlign: "center", padding: 10 }}>No data available</div>
      )}
    </div>
  );
}
