import React, { useEffect, useState } from "react";
import "./createEmployee.css";
import { Form, Input, Checkbox, Row, Col, DatePicker, Button, Select, Upload, message, Modal } from "antd";
import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import defaultProfile from '../../assets/default-profile-pic.png';
import { CameraOutlined } from '@ant-design/icons';
import Employee from "../../bu/actions/Employee";
import Groups from "../../bu/actions/Groups";
import Role from "../../bu/actions/Roles";
import Designation from "../../bu/actions/Designation";
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const options = [
  {
    value: '1',
    label: 'Not Identified',
  },
  {
    value: '2',
    label: 'Closed',
  },
  {
    value: '3',
    label: 'Communicated',
  },
  {
    value: '4',
    label: 'Identified',
  },
  {
    value: '5',
    label: 'Resolved',
  },
  {
    value: '6',
    label: 'Cancelled',
  },
];

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const CreateEmployee = ({ messageApi }) => {
  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState("")
  // const [isHovered, setIsHovered] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDOB] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [groups, setGroups] = useState([])
  const [designation, setDesignation] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [role, setRole] = useState("");
  const [forcePasswordChange, setForcePasswordChange] = useState(false);


  useEffect(() => {
    const getGroups = async () => {
      try {
        const groups = await Role.readAll()
        setGroups(groups.map(group => { return { value: group.id, label: group.name } }))

      } catch (error) {
        console.log("getProjects error", error.message.includes('401'))
        if (error.message.includes('401'))
          messageApi.open({
            type: 'error',
            content: 'Invalid or expired token. Please login again'
          });
        //      navigate('/login')
      }
    }
    getGroups()
  }, [])
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const designation = await Designation.readAll()
        setDesignation(designation.map(designation => { return { value: designation.id, label: designation.name } }))

      } catch (error) {
        console.log("getProjects error", error.message.includes('401'))
        if (error.message.includes('401'))
          messageApi.open({
            type: 'error',
            content: 'Invalid or expired token. Please login again'
          });
        //      navigate('/login')
      }
    }
    getDesignation()
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Creating employee...');
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handleCreateEmployee = async (data) => {

    try {
      const values = await form.validateFields();
      const dob = values.dob;
      const doj = values.doj;
      const Totalexperience = values.total_experience;
      //return;
      const imageURL = fileList.length > 0 ? fileList[0].thumbUrl : '';
      await Employee.create({
        // employee_id: employeeId,
        first_name: firstName,
        last_name: lastName,
        designation_id: Number(position),
        email: email,
        password: password,
        dob: dob,
        doj: doj,
        total_experience: Number(Totalexperience),
        //file: imageURL,
        // groups: [role],
        groups:[{"id":role}] 
      });
      messageApi.open({
        type: 'success',
        content: 'Employee successfully added',
      });
    } catch (error) {
      console.log('Error:', error);
      let errorMessage = 'An error occurred while creating the employee.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      messageApi.open({
        type: 'error',
        content: errorMessage,
      });
    }

  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const handleForcePasswordChange = (e) => {
    const isChecked = e.target.checked;
    console.log('Force Password Change:', isChecked);
    setForcePasswordChange(isChecked);
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  const validateEmail = (rule, value) => {
    if (!value) {
      return Promise.reject('Please input your email!');
    }
    if (!/^\S+@\S+\.\S+$/.test(value)) {
      return Promise.reject('The input is not a valid email!');
    }
    return Promise.resolve();
  };

  const validateCheckbox = () => {
    if (email && !isCheckboxChecked) {
      return Promise.reject('Please check if you want to send credentials via email!');
    }
    return Promise.resolve();
  };
  const isFieldTouched = (fieldName) => {
    return form.isFieldTouched(fieldName);
  };



  const handleEmployeeIdChange = (e) => {
    setEmployeeId(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleRoleChange = (value) => {
    console.log(`selected ${value}`);
    setRole(value)
  };

  const filterOption = (input, option) =>
    (option.label || '').toLowerCase().includes(input.toLowerCase());

  const validatePassword = (_, value) => {
    if (!value || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject({ message: 'Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character.' });
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };



  const handleUploadChange = (info) => {
    debugger
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadEndpoint = "http://139.59.85.23/api/v1/file";

  const props = {
    name: 'file',
    action: uploadEndpoint,
    method: 'post',
    headers: {
      authorization: 'authorization-text',
    },
    onChange: handleUploadChange,
  };
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image
      </div>
    </div>
  );
  return (
    <Form
      form={form}
      name="employeeForm"
      requiredMark="optional"
      style={{
        maxWidth: 500,
        marginLeft: 55,
        fontSize: 14,
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
        fontWeight: 500,
      }}
      layout="vertical"
      autoComplete="off"
      onSubmit={handleSubmit}

    >
      <Row gutter={8}>
        <div className="container">
          <Col span={12}>
            {/* <Form.Item
              label="Employee ID"
              name="employee_id"
              rules={[
                {
                  required: true,
                  message: 'Please input your employee ID!',
                },
                {
                  pattern: new RegExp('^[a-zA-Z0-9]*$'),
                  message: 'Please enter a valid alphanumeric employee ID!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <Input
                placeholder="Enter employee Id"
                className="inputtext"
                value={employeeId}
                onChange={handleEmployeeIdChange}
              />
            </Form.Item> */}

            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
                {
                  pattern: new RegExp('^[A-Za-z]+$'),
                  message: 'Please enter alphabets only!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <Input
                placeholder="Enter first name"
                className="inputtext"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </Form.Item>


            <Form.Item
              label="Last Name"
              name="last_name"
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
                {
                  pattern: new RegExp('^[A-Za-z]+$'),
                  message: 'Please enter alphabets only!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <Input
                placeholder="Enter last name"
                className="inputtext"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </Form.Item>
            <Form.Item
              label="Position"
              name="position"
              rules={[
                {
                  required: true,
                  message: 'Please select a position!',
                },
              ]}
              validateFirst
              style={{ textAlign: 'left', marginBottom: '12px' }}
            >
              <Select
                showSearch
                mode="single"
                placeholder="Select a person"
                optionFilterProp="children"
                value={position}
                onChange={value => {
                  setPosition(value);
                  form.validateFields(['position']);
                }}
                onBlur={() => form.validateFields(['position'])}
                onSearch={onSearch}
                filterOption={filterOption}
              >
                {/* Mapping the options */}
                {designation.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>



            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: 'Please input your role!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <Select
                showSearch
                mode="single"
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={handleRoleChange}
                onSearch={onSearch}
                filterOption={filterOption}
              >
                {/* Mapping the options */}
                {groups.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>


            <Form.Item
              label="Date of birth  (DD/MM/YYYY)"
              name="dob"
              rules={[
                {
                  required: true,
                  message: 'Please select your date of birth!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left", }}
            >
              <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/YYYY" style={{ width: '100%' }} className="inputtext" />
            </Form.Item>

            <Form.Item
              label="Date of joining  (DD/MM/YYYY)"
              name="doj"
              rules={[
                {
                  required: true,
                  message: 'Please select your date of joining!',
                },
              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" placeholder="DD/MM/YYYY" className="inputtext" />
            </Form.Item>
            <Form.Item
              label="Total Exp (E.g., 7, 8.5)"
              name="total_experience"
              rules={[
                {
                  required: true,
                  message: 'Please input your total experience!',
                },
                {
                  pattern: new RegExp(/^\d{0,2}(\.\d{0,2})?$/),
                  message: 'Please enter a valid number with up to two digits before and after the decimal point!',
                },

              ]}
              validateFirst
              style={{ textAlign: "left" }}
            >
              <Input placeholder="Enter experience" className="inputtext" />
            </Form.Item>

          </Col>

          <Col span={12}>
            <div style={{ display: "flex", justifyContent: 'end' }}>
              <div className="profile-picture">
                {/* <img src={defaultProfile} alt="defaultProfile" style={{ width: "100px" }} /> */}
                <Upload
                  action={uploadEndpoint}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length === 0 && uploadButton}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, validator: validateEmail },
              ]}
              style={{ textAlign: 'left' }}
            >
              <Input
                placeholder="Enter Email"
                prefix={<MailOutlined className="site-form-item-icon" />}
                className="inputtext"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: validatePassword,
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                type="password"
                placeholder="Create password"
                className="inputtext"
                onChange={handleConfirmPasswordChange}
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              validateFirst
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                type="password"
                placeholder="Re-type Password"
                className="inputtext"
                onChange={handlePasswordChange}
              />
            </Form.Item>

           
            {/* <Form.Item
              rules={[{ validator: validateCheckbox }]}
            >
              <Checkbox className="checkbox" onChange={onChange}>
                Send credentials Via Email
              </Checkbox>
            </Form.Item> */}

            {/* <Form.Item>
              <Checkbox className="checkbox" onChange={handleForcePasswordChange}>
                Force to change the password.
              </Checkbox>
            </Form.Item> */}
            <Button
              style={{ justifySelf: "flex-end" }}
              size="large"
              shape="standard"
              type="primary"
              onClick={handleCreateEmployee}
            >
              Create
            </Button>
          </Col>
        </div>

      </Row>
    </Form>
  );
};
export default CreateEmployee;
