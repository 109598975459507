import { get, post } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';
class Suggestion {
    static #baseUrl = '/profile/suggestion'
    static async create(data) {
        return await post(this.#baseUrl, data)

    }
    static async update() { }
    static async delete() { }
    static async readAll(userId, params = {}) {
        const urlParams = objectToUrlParams(params)
        //return await get(this.#baseUrl + '?' + urlParams)
        return await get(this.#baseUrl + `/${userId}` + '?' + urlParams)
        // return await get(this.#baseUrl)
    }
    static async readOne() { }
}

export default Suggestion;
