import React from "react";
import { useEffect, useState } from "react";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Drawer,
  Input,
  DatePicker,
  AutoComplete,
  Pagination,
  message,
  ConfigProvider,
  Checkbox,
} from "antd";
import ProjectsCard from "./ProjectsCard";
import CreateProject from "../../../../components/creationComponents/CreateProject";
import emptyicon from "../../../../assets/EmptyState.jpeg";
import NoData from "../../../../components/noData/index.js";
import "./index.css";
import Project from "../../../../bu/actions/Projects";
import Users from "../../../../bu/actions/Users";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../../../state";
import AllocateProject from "../../../../components/creationComponents/AllocateProject";
import AllocateWork from "../../../../components/creationComponents/AllocateWork.js";
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from "react-infinite-scroll-component";
import User from "../../../../bu/actions/Users";
// import InfiniteScroll from "react-infinite-scroller";
const monthsAbbreviated = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

function dateFormate(ds) {
  const dt = new Date(ds);
  return (
    dt.getDate() +
    "-" +
    monthsAbbreviated[dt.getMonth()] +
    "-" +
    dt.getFullYear()
  );
}
const tlDesignations = ["Team Lead", "Sr. Team Lead", "Project Coordinator"];
export default function ProjectsPage({ messageApi, emitter }) {
  const [state, setState] = useAppState();
  //console.log('ProjectsPage', messageApi)

  const [projectNames, setProjectNames] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [dateRange, setDateRange] = useState(null);
  const [project, setProject] = useState({});
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [openCreateProjectDrawer, setOpenCreateProjectDrawer] = useState(false);
  const [openAllocateProjectDrawer, setOpenAllocateProjectDrawer] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [leads, setLeads] = useState([]);
  const [submittingAllocateWorkDrawer, setSubmittingAllocateWorkDrawer] =
    useState(false);
  const [openAllocateWorkDrawer, setOpenAllocateWorkDrawer] = useState(false);
  const { RangePicker } = DatePicker;
  const { Search } = Input;

  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);

  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    {
      title: "Project Number",
      dataIndex: "seq_number",
      key: "seq_number",
      render: (seq_number) => {
        return <>{`USICAP${seq_number}`}</>;
      },
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Assigned to",
      dataIndex: "assigned_to",
      key: "assigned_to",
      render: (assigned_to) => {
        return (
          <>
            {assigned_to?.profile?.first_name +
              " " +
              assigned_to?.profile?.last_name}
          </>
        );
      },
    },
    {
      title: "Project Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Start Date",
      dataIndex: "starts_at",
      key: "starts_at",
      render: (starts_at) => {
        return <>{new Date(starts_at).toLocaleDateString()}</>;
      },
    },
    {
      title: "End Date",
      dataIndex: "ends_at",
      key: "ends_at",
      render: (ends_at) => {
        return <>{new Date(ends_at).toLocaleDateString()}</>;
      },
    },
  ]);

  // const getProjects = async (page) => {
  //   try {
  //     //console.log('getTasks currentPage', page)
  //     const project = await Project.readAll({ skip: (page - 1) * 10 });
  //     setProject(project);

  //     const names = project.data.map((item) => item.name);
  //     setProjectNames(names);
  //   } catch (error) {
  //     //console.log("getProjects error", error.message.includes('401'))
  //     if (error.message.includes("401"))
  //       messageApi.open({
  //         type: "error",
  //         content: "Invalid or expired token. Please login again",
  //       });
  //   }
  // };

  async function LP() {
    const project = await Project.readAll({ limit: 1000 });
    setProject(project);
  }
  useEffect(() => {
    try {
      // getProjects(1);
      LP();
    } catch (error) {
      console.log(error);
    }
    loadLeads();
  }, []);
  async function loadLeads() {
    const leads = await User.getAllLeads(1000);
    setLeads(leads.data.map((d) => ({ ...d, selected: false })));
  }
  useEffect(() => {
    setTotal(project?.meta?.total);
    setCurrentPage(project?.meta?.currentPage);
    //console.log(100, project)
  }, [project]);

  const handleCreateWork = () => {
    setOpenAllocateWorkDrawer(true);
  };

  const handleCreateProject = () => {
    //console.log('Creating Project...');
    setOpen(true);
    setTaskCreated(true);
  };
  const handleAllocateProject = (shouldOpenAllocateProjectDrawer = true) => {
    setOpenAllocateProjectDrawer(shouldOpenAllocateProjectDrawer);
  };
  const onProjectCreated = (newProject) => {
    setProject([...project, newProject]);
    setOpen(false);
    setTaskCreated(false);
  };

  const onClose = () => {
    setOpen(false);
    LP();
    setCurrentPage(1);
  };
  const onChange = (page) => {
    setCurrentPage(page);
    // getProjects(page);
    LP();
  };

  const onCloseAllocateProjectDrawer = () => {
    setOpenAllocateProjectDrawer(false);
  };

  const onSearch = async (value) => {
    setSearchValue(value);
    try {
      const filteredProjects = await Project.readAll({
        searchMap: JSON.stringify({
          name: value?.trim() || "",
        }),
        // fromDate: dateRange ? new Date(dateRange[0].$d).toISOString() : '',
        // toDate: dateRange ? new Date(dateRange[1].$d).toISOString() : ''
      });
      const updatedProjects =
        value.trim() === "" ? await Project.readAll() : filteredProjects;
      if (updatedProjects && updatedProjects.data) {
        setProject({ ...updatedProjects, data: updatedProjects.data });
      }
    } catch (error) {
      console.error("Error fetching filtered projects:", error);
    }
  };

  const getSuggestions = (value) => {
    return projectNames.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
  };

  const handleDateChange = async (dates) => {
    try {
      //console.log("is clear",dates)
      const filteredProjects = await Project.readAll({
        searchMap: JSON.stringify({
          name: searchValue?.trim() || "",
          dateSearch: [
            "starts_at",
            dates ? new Date(dates[0].$d)?.toISOString()?.split("T")[0] : "",
            dates ? new Date(dates[1].$d)?.toISOString()?.split("T")[0] : "",
          ],
        }),
      });
      setDateRange(dates);
      console.log("filteredData", filteredProjects.data);
      setProject({ ...filteredProjects, data: filteredProjects.data });
    } catch (error) {
      console.error("Error fetching filtered projects:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newCellPaddingBlock = window.innerWidth < 740 ? 10 : 7;
      setCellPaddingBlock(newCellPaddingBlock);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCancel = () => {
    onCloseAllocateProjectDrawer();
  };
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      // onCloseAllocateProjectDrawer();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const onClear = async (e, isForAllocateProject) => {
    console.log("onClear 212", isForAllocateProject);
    emitter.emit(
      isForAllocateProject
        ? "clear-allocate-project-form"
        : "clear-project-form"
    );
  };

  const onSubmit = async (e, isForAllocateProject) => {
    console.log(
      "onClear 217",
      isForAllocateProject ? "allocate-project" : "create-project"
    );
    emitter.emit(isForAllocateProject ? "allocate-project" : "create-project");
  };

  const handleAllocateWorkClear = async () => {
    emitter.emit("clear-allocate-work-form");
  };

  const handleAllocateWorkSubmit = async () => {
    emitter.emit("allocate-work");
    setSubmittingAllocateWorkDrawer(true);
    setSubmittingAllocateWorkDrawer(false);
  };

  const onWorkAllocationCloseDrawer = () => {
    emitter.emit("clear-allocate-work-form");
    setOpenAllocateWorkDrawer(false);
    setSubmittingAllocateWorkDrawer(false);
  };

  // const getUsers = async (name) => {
  //   try {
  //     const users = await Users.readAll({
  //       searchMap: JSON.stringify({
  //         subquery: {
  //           OR: [
  //             {
  //               profile: {
  //                 first_name: { contains: name, mode: "insensitive" },
  //               },
  //             },
  //             {
  //               profile: { last_name: { contains: name, mode: "insensitive" } },
  //             },
  //           ],
  //         },
  //         profile: { designation_id: { in: [] } },
  //       }),
  //     });
  //     console.log("users modified", users?.data);
  //     const modifiedUsers = users?.data?.map((user) => ({
  //       label: `${user.profile.first_name} ${user.profile.last_name}`,
  //       value: user.id,
  //     }));
  //     // setLeads(modifiedUsers || []);
  //   } catch (e) {
  //     console.log(e);
  //     console.log("error");
  //   }
  // };

  return (
    <div
      style={{
        // border: "1px solid red",
        flex: 1,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div style={{ width: 220, background: "#f9f9f9", padding: 10 }}>
        <div
          style={{
            padding: 10,
            background: "#3a7efc",
            color: "#fff",
            fontWeight: 600,
            textAlign: "center",
            borderRadius: 5,
            margin: 10,
            fontSize: 14,
            letterSpacing: 0.6,
            cursor: "pointer",
            userSelect: "none",
            boxShadow: "3px 3px 7px 0px #ccc",
          }}
          onClick={handleCreateProject}
        >
          Create Project
        </div>
        <div style={{ padding: 15 }}>
          {/* <div className="project-sub-menu"> - All Projects</div> */}
          <div className="project-sub-menu" onClick={handleAllocateProject}>
            {" "}
            - Allocate Project
          </div>
          <div className="project-sub-menu" onClick={handleCreateWork}>
            {" "}
            - Allocate Work
          </div>
        </div>
        <div>
          <div style={{ color: "#444", fontSize: 14, marginLeft: 10 }}>
            Filters:
          </div>
          <div style={{ padding: 10 }}>
            <div style={{ fontSize: 12, color: "#444" }}>Search:</div>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              allowClear
              // className="project-search-input"
            />
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <div style={{ fontSize: 12, color: "#444" }}>Date:</div>
            <RangePicker
              onChange={handleDateChange}
              placeholder={["From Date", "To Date"]}
            />
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <div style={{ fontSize: 12, color: "#444" }}>Team Lead:</div>
            <div style={{ marginTop: 5, maxHeight: 500, overflowY: "scroll" }}>
              {leads.map((d, i) => (
                <div
                  key={"lead-" + i}
                  style={{ textTransform: "capitalize", fontSize: 12 }}
                >
                  <Checkbox
                    onChange={(e) => {
                      let _leads = [...leads];
                      _leads[i].selected = e.target.checked;
                      setLeads(_leads);
                    }}
                  />{" "}
                  {d?.profile?.first_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 10,
          // border: "1px solid red",
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 10,
            fontSize: 18,
            fontWeight: 700,
            color: "#555",
            letterSpacing: 0.8,
          }}
        >
          {" "}
          Projects
        </div>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            // border: "1px solid red",
            display: "flex",
          }}
        >
          <div
            style={{
              // display: "flex",
              // flexWrap: "wrap",
              overflow: "scroll",
              // border: "1px solid red",
              flex: 1,
            }}
          >
            {project?.data
              ?.filter((e) => {
                const lead = leads.filter((d) => d.selected).map((d) => d.id);
                if (lead.length === 0) return true;
                return lead.includes(e.assigned_to.id);
              })
              .map((d, i) => (
                <div
                  key={"project" + i}
                  style={{
                    padding: 5,
                  }}
                >
                  {/* <ProjectsCard project={d} /> */}
                  <div
                    style={{
                      background: "#fff",
                      margin: 5,
                      padding: 10,
                      borderRadius: 5,
                      // width: 400,
                      // height: 100,
                    }}
                  >
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontSize: 20,
                        marginBottom: 10,
                        borderBottom: "1px solid #ccc",
                        paddingBottom: 10,
                      }}
                    >
                      {d.name}{" "}
                      <span
                        style={{
                          marginLeft: 20,
                          fontSize: 10,
                          fontWeight: 600,
                          background:
                            d.status === "ALLOCATED"
                              ? "rgb(217 237 217)"
                              : "rgb(250 217 212)",
                          color:
                            d.status === "ALLOCATED"
                              ? "green"
                              : "rgb(227 84 59)",
                          padding: 3,
                          borderRadius: 5,
                        }}
                      >
                        {d.status}
                      </span>
                    </div>
                    <div
                      style={{ fontSize: 14, color: "#444", marginBottom: 10 }}
                    >
                      USICAP{d.seq_number}
                    </div>
                    <div style={{ display: "flex" }}>
                      <table>
                        <tr>
                          <td
                            style={{
                              color: "#666",
                              fontSize: 12,
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <span>Team Lead:</span>
                          </td>
                          <td style={{ fontSize: 14 }}>
                            {d?.assigned_to?.profile?.first_name}{" "}
                            {d?.assigned_to?.profile?.last_name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              color: "#666",
                              fontSize: 12,
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <span>Starts at:</span>
                          </td>
                          <td style={{ fontSize: 14 }}>
                            {" "}
                            {dateFormate(d?.starts_at)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              color: "#666",
                              fontSize: 12,
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <span>Ends at:</span>
                          </td>
                          <td style={{ fontSize: 14 }}>
                            {" "}
                            {dateFormate(d?.ends_at)}
                          </td>
                        </tr>
                      </table>

                      <div
                        style={{
                          marginLeft: 10,
                          padding: 10,
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        <div style={{ color: "#666", fontSize: 12 }}>
                          {" "}
                          Project Description:
                        </div>
                        <div style={{ color: "rgb(42 56 67)" }}>
                          {" "}
                          {d.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <ConfigProvider
        theme={{
          token: {
            paddingLG: "0px",
          },
        }}
      >
        <Drawer
          width={"100%"}
          title={
            <div
              style={{
                display: "flex",
                padding: "6px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Allocate Project</span>
              <div>
                <Button
                  type="default"
                  onClick={(e) => onClear(e, true)}
                  style={{ marginRight: "10px" }}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => onSubmit(e, true)}
                  loading={submitting}
                  style={{ backgroundColor: "#8d85e3" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          }
          placement="right"
          onClose={onCloseAllocateProjectDrawer}
          open={openAllocateProjectDrawer}
          style={{ textAlign: "center" }}
        >
          <AllocateProject
            messageApi={messageApi}
            emitter={emitter}
            onCloseAllocateProjectDrawer={onCloseAllocateProjectDrawer}
            openAllocateProjectDrawer={openAllocateProjectDrawer}
          />
        </Drawer>
      </ConfigProvider>

      <Drawer
        width={window.innerWidth > 1460 ? "728.5px" : "50%"}
        title="Create Project"
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <div style={{ textAlign: "right" }}>
            <Button style={{ marginRight: 8 }} onClick={onClear}>
              Clear
            </Button>
            <Button
              style={{
                backgroundColor: "#8d85e3",
                width: "75.5px",
                height: "30px",
              }}
              type="primary"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        }
      >
        <CreateProject
          messageApi={messageApi}
          onCreateProjectCloseDrawer={onClose}
          emitter={emitter}
        />
      </Drawer>

      <Drawer
        width="100%"
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ paddingTop: 5 }}>Allocate Work</span>
            <div>
              <Button
                type="default"
                onClick={handleAllocateWorkClear}
                style={{ marginRight: "10px" }}
              >
                Clear
              </Button>
              <Button
                type="primary"
                onClick={handleAllocateWorkSubmit}
                loading={submittingAllocateWorkDrawer}
                style={{ background: "#8d85e3" }}
              >
                Submit
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={onWorkAllocationCloseDrawer}
        open={openAllocateWorkDrawer}
        style={{ textAlign: "center" }}
      >
        <AllocateWork
          messageApi={messageApi}
          onWorkAllocationCloseDrawer={onWorkAllocationCloseDrawer}
          emitter={emitter}
          openAllocateWorkDrawer={openAllocateWorkDrawer}
        />
      </Drawer>
    </div>
  );
}
