import { get, post, put } from "../../api/request";
import objectToUrlParams from "../../utils/objectToUrlParams";
class Customer {
  static #baseUrl = "/customer";

  static async create(data) {
    return await post(this.#baseUrl, data);
  }
  static async update(id, data) {
    return await put(`${this.#baseUrl}/${id}`, data);
  }
  static async delete() {}
  static async readAll(params = { limit: 1000 }) {
    const urlParams = objectToUrlParams(params);
    return await get(this.#baseUrl + "?" + urlParams);
  }
  static async readOne() {}
}

export default Customer;
