import React, { useEffect, useState } from 'react';
import { Table, Button, Drawer, Input, AutoComplete, message, ConfigProvider, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import CreateWork from '../../../../components/creationComponents/CreateWork';
import NoData from '../../../../components/noData/index.js';
import emptyicon from '../../../../assets/EmptyState.jpeg';
// import Work from '../../../../bu/actions/Work';  
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../../../state";
import "./index.css";
import AllocateWork from '../../../../components/creationComponents/AllocateWork.js';


export default function AllocateWorkPage({ messageApi, emitter }) {
  const [state, setState] = useAppState();

  const [workData, setWorkData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [work, setWork] = useState({});
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [workCreated, setWorkCreated] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { Search } = Input;
  const { RangePicker } = DatePicker;

  const [columns, setColumns] = useState([
    {
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
  ]);

  const handleCreateWork = () => {
    setOpen(true);
    setWorkCreated(true);
  };

  const onClose = () => {
    setOpen(false);
    // getWork(1);
    setCurrentPage(1);
  };

  const onChange = (page) => {
    setCurrentPage(page);
    // getWork(page);
  };

  const onSearch = async (value) => {

  };
  const handleDateChange = async (dates) => {

  };

  const handleClear = () => {
    emitter.emit('clear-allocate-work-form')
  };

  const handleSubmit = async () => {
    try {
      emitter.emit('allocate-work')
      setSubmitting(true);

    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='work-main-container'>
      <div className="work-container">
        <div className="work-page-button">
          <div className="work-page-title-container">
            <div className="work-page-title">
              Allocated Work
            </div>
          </div>
          <div className="work-page-container">
            <Button
              style={{ height: 30, backgroundColor: "#8686ff" }}
              type="primary"
              onClick={handleCreateWork}
            >
              + Allocate Work
            </Button>
          </div>
        </div>
        <div className='work-table-field'>
          <Search
            placeholder="Search by Project Name"
            // onSearch={onSearch}
            allowClear
            className="work-search-input"
          />
          <RangePicker
            // onChange={handleDateChange}
            placeholder={['From Date', 'To Date']} />
        </div>
      </div>

      {/* {Array.isArray(work?.data) && work?.data.length > 0 ? (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 10
              },
            },
          }}
        >
          <Table
            className='work-table'
            columns={columns}
            dataSource={work?.data || []}
            pagination={{
              onChange,
              total,
              defaultPageSize: 10,
              current: currentPage,
              showSizeChanger: false,
            }}
          />
        </ConfigProvider>
      ) : (
        <div>
          <NoData
            noDataMessage="You have no Allocated Work yet"
          />
        </div>
      )} */}

      <div>
        <NoData
          emptyIcon={emptyicon}
          noDataMessage="You have no Allocated Work yet"
        />
      </div>

      <Drawer
        width="100%"
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ paddingTop: 5 }}>Allocate Work</span>
            <div>
              <Button type="default" onClick={handleClear} style={{ marginRight: '10px' }}>
                Clear
              </Button>
              <Button type="primary" onClick={handleSubmit} loading={submitting} style={{ background: '#8d85e3' }}>
                Submit
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        style={{ textAlign: 'center' }}
      >
        <AllocateWork messageApi={messageApi} onWorkAllocationCloseDrawer={onClose} emitter={emitter} />
      </Drawer>
    </div>
  );
}
