import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import { usePo } from "./Invoice.context";
import dayjs from "dayjs";
import { get } from "../../../../api/request";

const { Option } = Select;

const POForm = () => {
  const { createPo, editPo, drawerService } = usePo();
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);

  const [form] = Form.useForm();
  async function loadCustomers() {
    try {
      const res = await get("customer?limit=1000");
      setCustomers(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadProjects() {
    try {
      const res = await get("project?limit=1000");
      setProjects(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    if (drawerService.mode === "create") createPo(values);
    if (drawerService.mode === "edit") editPo(values);
    // Here you would typically send the form data to the server
  };

  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    if (drawerService.mode === "create") {
      form.resetFields();
      loadCustomers();
      loadProjects();
    }
    if (drawerService.editId) {
      console.log(drawerService.editObject);
      //   form.setFieldValue(drawerService.editObject);
      form.setFieldsValue({
        cToClient: 500,
        ...drawerService.editObject,
        poDate: dayjs(drawerService.editObject.poDate),
      });
    }
  }, [drawerService.editId, drawerService.isOpen, form]);
  return (
    <div style={{}}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Please select a customer!" }]}
          >
            <Select
              showSearch
              placeholder="Search customer name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* Replace with actual customer options */}
              {customers.map((c, i) => (
                <Option key={"cu" + i} value="customer1">
                  Customer 1
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ flex: 1 }}
            name="quoteNo"
            label="Quote No"
            rules={[
              { required: true, message: "Please input the quote number!" },
            ]}
          >
            <Select placeholder="Select quote number">
              {/* Replace with actual quote options */}
              <Option value="quote1">USICAQ1</Option>
              <Option value="quote2">USICAQ2</Option>
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="poNumber"
            label="PO. Number"
            rules={[{ required: true, message: "Please input the PO number!" }]}
          >
            <Input placeholder="USICAP1" />
          </Form.Item>

          <Form.Item
            style={{ flex: 1 }}
            name="detailing"
            label="Detailing"
            rules={[{ required: true, message: "Please input detailing!" }]}
          >
            <Input placeholder="200" />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="design"
            label="Design"
            rules={[{ required: true, message: "Please input design!" }]}
          >
            <Input placeholder="300" />
          </Form.Item>

          <Form.Item
            style={{ flex: 1 }}
            name="designVendor"
            label="Design Vendor"
            rules={[{ required: true, message: "Please input design vendor!" }]}
          >
            <Input placeholder="200" />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="cToUsica"
            label="C to Usica"
            rules={[{ required: true, message: "Please input C to Usica!" }]}
          >
            <Input placeholder="100" />
          </Form.Item>

          <Form.Item
            style={{ flex: 1 }}
            name="cToClient"
            label="C to Client"
            rules={[{ required: true, message: "Please input C to Client!" }]}
          >
            <Input placeholder="Value" />
          </Form.Item>
        </div>
        <Form.Item
          style={{ flex: 1 }}
          name="poDate"
          label="PO. Date"
          rules={[{ required: true, message: "Please select the PO date!" }]}
        >
          <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
        </Form.Item>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }} />
          <Form.Item>
            <Button type="default" onClick={onReset}>
              Clear
            </Button>

            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              {drawerService.mode === "create" && "Create"}
              {drawerService.mode === "edit" && "Edit"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default POForm;
