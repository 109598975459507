import axios from "axios";
const apiEndpoint = process.env.NODE_ENV === 'production' ? window.location.origin + '/api/v1/' : process.env.REACT_APP_API_URL;
console.log(process.env.NODE_ENV)
const axiosInstance = axios.create({
  baseURL: apiEndpoint,
});

// Add a response interceptor to handle HTTP errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // The request was made, and the server responded with a status code that falls outside the range of 2xx

      if (error.response.status === 401) {
        // Handle the error here
        console.error(`HTTP error ${error.response.status}: ${error.response.data.message}`);
        localStorage.removeItem('authToken')
        window.location.replace('/login')
        throw new Error(`HTTP error ${error.response.status}: ${error.response.data.message}`)
      }
      throw error
    }

    // return Promise.reject(error);
  }
);


const get = async (path, params = {}, headers = {}) => {
  const Authorization = "Bearer " + localStorage.getItem("authToken");
  try {
    const response = await axiosInstance.get(path, {
      params: params,
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    // throw error;
  }
};

const post = async (path, data = null, headers = {}) => {
  const Authorization = "Bearer " + localStorage.getItem("authToken");
  try {
    const response = await axiosInstance.post(path, data, {
      headers: { ...headers, Authorization },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

const put = async (path, data = null, headers = {}) => {
  const Authorization = "Bearer " + localStorage.getItem("authToken");
  try {
    const response = await axiosInstance.put(path, data, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
const patch = async (path, data = null, headers = {}) => {
  const Authorization = "Bearer " + localStorage.getItem("authToken");
  try {
    const response = await axiosInstance.patch(path, data, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const del = async (path, headers = {}) => {
  const Authorization = "Bearer " + localStorage.getItem("authToken");
  try {
    const response = await axiosInstance.delete(path, {
      headers: { ...headers, Authorization },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export { get, post, put, patch, del };
