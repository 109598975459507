import { get ,post} from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams'
class Employee {
  static #baseUrl = '/user'

  static async create(data) { 
    return await post(this.#baseUrl, data)
  }
  static async update() { }
  static async delete() { }
  static async readAll(params= {}) {
    const urlParams = objectToUrlParams(params)
    return await get(this.#baseUrl + '?' + urlParams)
  }
  static async readOne() { }
}

export default Employee;
