import Sidebar from "./PaymentsSidebar";
import { InvoiceProvider } from "./payments.context";
import PosList from "./PaymentsList";
import PoDrawer from "./PaymentsDrawer";
function PaymentsPage({}) {
  return (
    <InvoiceProvider>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Sidebar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginLeft: 10 }}>Payments</h2>
          <PosList />
          {/* {JSON.stringify(POs)} */}
          <PoDrawer />
        </div>
      </div>
    </InvoiceProvider>
  );
}

export default PaymentsPage;
