import { useEffect, useState } from "react";
import {
  Space,
  Table,
  Button,
  Drawer,
  Pagination,
  Checkbox,
  Input,
  DatePicker,
  AutoComplete,
  Switch,
  ConfigProvider,
} from "antd";
import { ExportOutlined, DeleteOutlined } from "@ant-design/icons";
import CreateEmployee from "../../../../components/creationComponents/CreateEmployee";
import emptyIcon from "../../../../assets/EmptyState.png";
import NoData from "../../../../components/noData";
import "./index.css";
import { useAppState } from "../../../../state";
import User from "../../../../bu/actions/Users";
import { useNavigate } from "react-router-dom";
import Designation from "../../../../bu/actions/Designation";
import _debounce from "lodash/debounce";

const { RangePicker } = DatePicker;
const { Search } = Input;

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

export default function Employee({ messageApi }) {
  const [openTask, setOpenTask] = useState(false);
  const [state, setState] = useAppState();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [cellPaddingBlock, setCellPaddingBlock] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState(true);

  const getEmployees = async (page) => {
    try {
      setIsLoading(true);
      const users = await User.readAll({ limit: 1000, skip: 0, sort: 'created_at', sortOrder: 'desc' });
      setUsers(users.data);
      setTotal(users?.meta?.total);
      setCurrentPage(users?.meta?.currentPage);
    } catch (error) {
      console.log("getProjects error", error.message.includes("401"));
      if (error.message.includes("401"))
        messageApi.open({
          type: "error",
          content: "Invalid or expired token. Please login again",
        });
      navigate("/login");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      getEmployees();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newCellPaddingBlock = window.innerHeight <= 800 ? 1 : 7;
      setCellPaddingBlock(newCellPaddingBlock);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const designation = await Designation.readAll();
        setDesignation(
          designation.map((designation) => {
            return { value: designation.id, label: designation.name };
          })
        );
      } catch (error) {
        console.log("getProjects error", error.message.includes("401"));
        if (error.message.includes("401"))
          messageApi.open({
            type: "error",
            content: "Invalid or expired token. Please login again",
          });
      }
    };
    getDesignation();
  }, []);

  const handleSwitchChange = async (checked, record) => {
    await User.updateStatus(record.id, { active: checked });
    setUsers((prevUsers) => {
      const userIndex = prevUsers.findIndex((user) => user.id === record.id);
      if (userIndex !== -1) {
        const updatedUsers = [...prevUsers];
        updatedUsers[userIndex].active = checked;
        updatedUsers[userIndex].status = checked ? "ACTIVE" : "INACTIVE";
        return updatedUsers;
      }
      return prevUsers;
    });
  };
  const [designation, setDesignation] = useState([]);
  const [columns, setColumns] = useState();

  const employeeTableColumns = [
    {
      title: "Emp Id",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Emp Name",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => {
        return <>{profile?.first_name + " " + profile?.last_name}</>;
      },
      width: "20%",
    },
    {
      title: "Position",
      dataIndex: "profile",
      key: "profile",
      render: (text, profile) => {
        return <>{profile?.profile?.designation?.name ?? "N/A"}</>;
      },
      width: "15%",
    },
    {
      title: "Role",
      dataIndex: "groups",
      key: "groups",
      render: (groups) => {
        return <>{groups.map((group) => group.name)?.join(",")}</>;
      },
    },
    {
      title: "DOB",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => {
        return <>{new Date(profile?.dob).toLocaleDateString()}</>;
      },
    },
    {
      title: "DOJ",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => {
        return <>{new Date(profile?.doj).toLocaleDateString()}</>;
      },
    },
    {
      title: "Total Exp",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => {
        return <>{profile?.total_experience}</>;
      },
    },
    {
      title: "Department",
      dataIndex: "profile",
      key: "profile",
      render: (text, profile) => {
        return <>{profile?.profile?.designation?.department?.name ?? "N/A"}</>;
      },
    },
    {
      title: "Phone",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => {
        return <>{profile?.phone_number}</>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Active",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          checked={status === "ACTIVE"}
          onChange={(checked, event) => {
            handleSwitchChange(checked, record);
          }}
        />
      ),
      onCell: (record) => ({
        onClick: (event) => {
          event.stopPropagation();
          event.preventDefault();
          return;
        },
      }),
    },
    {
      title: "",
      dataIndex: "description",
      key: "description",
      render: () => (
        <span>
          <DeleteOutlined style={{ color: "red" }} />
        </span>
      ),
    },
  ];

  useEffect(() => {
    setColumns(employeeTableColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (page) => {
    setCurrentPage(page);
    getEmployees(page);
  };
  const handleCreateTask = () => {
    // Handle task creation here (e.g., submit form data to the server)
    console.log("Creating task...");
    setOpenTask(true); // Open the Drawer when the button is clicked
  };

  const onCloseTask = () => {
    setOpenTask(false); // Close the Drawer
  };
  const onChange1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onSearch = async (event) => {
    try {
      setIsLoading(true);
      setSearchValue(event.target.value);
      const users = await User.readAll({
        limit: 1000,
        sort: 'created_at', 
        sortOrder: 'desc',
        searchMap: JSON.stringify({
          subquery: {
            OR: [
              {
                profile: {
                  first_name: {
                    contains: event.target.value,
                    mode: "insensitive",
                  },
                },
              },
              {
                profile: {
                  last_name: {
                    contains: event.target.value,
                    mode: "insensitive",
                  },
                },
              },
            ],
          },
        }),
      });
      setUsers(users.data);
      setTotal(users?.meta?.total);
      setCurrentPage(users?.meta?.currentPage);
    } catch (error) {
      console.error("Error fetching filtered projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounce the search function
  const debouncedSearch = _debounce((value) => {
    onSearch(value);
  }, 300); // 300 milliseconds debounce time

  const handleDateChange = async (dates) => {
    try {
      const query = {};
      if (searchValue?.trim()) {
        query.subquery = {
          OR: [
            {
              profile: {
                first_name: {
                  contains: searchValue?.trim(),
                  mode: "insensitive",
                },
              },
            },
            {
              profile: {
                last_name: {
                  contains: searchValue?.trim(),
                  mode: "insensitive",
                },
              },
            },
          ],
        };
      }
      query.dateSearch = [""];
      const users = await User.readAll({
        name: searchValue?.trim() || "",
        fromDate: dates ? new Date(dates[0].$d).toISOString() : "",
        toDate: dates ? new Date(dates[1].$d).toISOString() : "",
      });
      setDateRange(dates);
      setUsers(users.data);
      setTotal(users?.meta?.total);
      setCurrentPage(users?.meta?.currentPage);
    } catch (error) {
      console.error("Error fetching filtered projects:", error);
    }
  };
  // const paginationSize = 'small';


  const redirectToAnotherPage = (employeeId) => {
    // Assuming the URL format for the target page is '/employee-details/:employeeId'
    navigate(`/profile/${employeeId}`);
  };

  return (
    <div style={{ flex: 1, overflow: "scroll" }}>
      <div
        className="employee-main-container"
        style={{
          padding: "0px 0px",
          borderBottom: "none",
          backgroundColor: "#ECECF0D9",
        }}
      >
        <div className="employee-page-button">
          <div className="employee-page-title-container">
            <div className="employee-page-title">Employee List</div>
          </div>
          <div className="employee-page-container">
            {(state?.user?.permissions.includes("task.create") ||
              state?.user?.permissions.includes("super-admin")) && (
              <>
                <Button
                  style={{ height: 40, Top: "30px" }}
                  type="primary"
                  onClick={handleCreateTask}
                >
                  + Add Employee
                </Button>
              </>
            )}
          </div>
        </div>
        <div style={{ marginLeft: "20px" }}>
          {" "}
          <Input
            placeholder="Search for employee by name. Start typing...."
            allowClear
            onChange={debouncedSearch}
            onKeyDown={debouncedSearch}
            style={{ width: 400 }}
            size="large"
          />
        </div>
        <div
          className="employee-table-field"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Space direction='horizontal' size={200}>
            <Checkbox onChange={onChange1} style={{}}>Inactive Employees</Checkbox>
            <div style={{}}>
              Date of joining
              <RangePicker style={{ marginLeft: '5px' }} size='large' format="DD-MM-YYYY" onChange={handleDateChange} />
            </div>
          </Space> */}
          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Export</span>
            <ExportOutlined style={{ marginLeft: 8 }} />
          </div> */}
        </div>
        {/* <div style={{marginLeft:'30px'}}> <Search placeholder="Search for Emp Id" onSearch={onSearch} style={{ width: 400 }} size='large' /></div> */}
        {/* <div className='employee-table-field'>
          <Space direction='horizontal' size={200}>
            <Checkbox onChange={onChange1} style={{  }}>Inactive Employees</Checkbox>
           
          <div style={{}}>Date of joining
          <RangePicker size='large' format="DD-MM-YYYY" onChange={handleDateChange} />  </div>

          <div style={{ display: 'flex', alignItems: 'right' }}>
      <ExportOutlined style={{ marginRight: 5 }} />
      <span>Export</span>
    </div>
          </Space>
        </div> */}
      </div>
      {Array.isArray(users) && users.length === 0 && !isLoading ? (
        <div>
          <NoData emptyIcon={emptyIcon} noDataMessage="You have no tasks yet" />
        </div>
      ) : (
        // <ConfigProvider
        //   theme={{
        //     components: {
        //       Table: {
        //         cellPaddingBlock: cellPaddingBlock,
        //         rowHoverBg: "#e6f4ff",
        //       },
        //     },
        //   }}
        // >
        //   <Table
        //     onRow={(record, rowIndex) => {
        //       return {
        //         onClick: (event) => {
        //           console.log(record, rowIndex);
        //           navigate(`/profile/${record.id}`);
        //         }, // click row
        //         onDoubleClick: (event) => {}, // double click row
        //         onContextMenu: (event) => {}, // right button click row
        //         onMouseEnter: (e) => {
        //           e.currentTarget.style.cursor = "pointer";
        //         },
        //         onMouseLeave: (event) => {}, // mouse leave row
        //       };
        //     }}
        //     style={{
        //       padding: "0px 20px",
        //       backgroundColor: "#ECECF0D9",
        //       maxWidth: "calc(100vw - 250px)",
        //     }}
        //     className="Employee-Table"
        //     columns={columns}
        //     dataSource={users}
        //     pagination={{
        //       onChange,
        //       total,
        //       defaultPageSize: 10,
        //       current: currentPage,
        //       showSizeChanger: false,
        //       // size: paginationSize,
        //     }}
        //     scroll={{ x: true }}
        //     loading={isLoading}
        //   />
        // </ConfigProvider>
        <table style={{ margin: 20 }} className="employee-table">
          <tr>
            <th>Employee Id</th>
            <th>
              Employee name{" "}
              {/* <button
                onClick={(e) => {
                  setSortDirection(!sortDirection);
                }}
              >
                {sortDirection ? "a-z" : "z-a"}
              </button> */}
            </th>
            <th>Position</th>
            <th>Role</th>
            <th>DOB</th>
            <th>DOJ</th>
            <th>Total Exp</th>
            <th>Department</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Status</th>
            <th>Active</th>
            <th>View Profile</th>
          </tr>
          {/* {users.sort((a, b) => {
              if (a.profile?.first_name > b.profile?.first_name)
                return sortDirection ? 1 : -1;
              if (a.profile?.first_name < b.profile?.first_name)
                return sortDirection ? -1 : 1;
              else return 0;
            }).map((d, i) => ( */}
              {users.map((d, i) => (
              <tr key={d.id}>
                <td>{d.employee_id}</td>
                <td>
                  {d.profile?.first_name} {d.profile?.last_name}
                </td>
                <td>{d.profile?.designation?.name ?? "N/A"}</td>
                <td>{d.groups.map((group) => group.name)?.join(",")}</td>
                <td>{new Date(d.profile?.dob).toLocaleDateString()}</td>
                <td>{new Date(d.profile?.doj).toLocaleDateString()}</td>
                <td>{d.profile?.total_experience}</td>
                <td>{d?.profile?.designation?.department?.name ?? "N/A"}</td>
                <td>{d.profile?.phone_number}</td>
                <td>{d.email}</td>
                <td>{d.status}</td>
                <td>
                  {" "}
                  <Switch
                    checked={d.status === "ACTIVE"}
                    onChange={(checked, event) => {
                      handleSwitchChange(checked, d);
                    }}
                  />
                </td>
                {/* <td>{d.id}</td> */}
                <td onClick={() => redirectToAnotherPage(d.id)} style={{cursor:"pointer"}}>View</td>
                {/* <td>
                  {" "}
                  <span>
                    <DeleteOutlined style={{ color: "red" }} />
                  </span>
                </td> */}
              </tr>
            ))}
        </table>
      )}
      <Drawer
        style={{ textAlign: "center" }}
        width={"50%"}
        title=" Create Employee"
        placement="right"
        onClose={onCloseTask}
        open={openTask}
      >
        <CreateEmployee messageApi={messageApi} />
      </Drawer>
    </div>
  );
}

// {
//   title: "Active",
//   dataIndex: "status",
//   key: "status",
//   render: (status, record) => (
//     <Switch
//       checked={status === "ACTIVE"}
//       onChange={(checked, event) => {
//         handleSwitchChange(checked, record);
//       }}
//     />
//   ),
//   onCell: (record) => ({
//     onClick: (event) => {
//       event.stopPropagation();
//       event.preventDefault();
//       return;
//     },
//   }),
// },
// {
//   title: "",
//   dataIndex: "description",
//   key: "description",
//   render: () => (
//     <span>
//       <DeleteOutlined style={{ color: "red" }} />
//     </span>
//   ),
// },
