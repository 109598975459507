const FormFieldTitle = ({ children }) => (
  <div
    style={{
      fontSize: 12,
      color: "#444",
      marginBottom: 5,
      textTransform: "capitalize",
    }}
  >
    {" "}
    {children}
  </div>
);
export default FormFieldTitle;
