import PoSidebar from "./PoSidebar";
import { PoProvider } from "./Po.context";
import PosList from "./PoList";
import PoDrawer from "./PoDrawer";
function PoPage({}) {
  return (
    <PoProvider>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <PoSidebar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginLeft: 10 }}>PO</h2>
          <PosList />
          {/* {JSON.stringify(POs)} */}
          <PoDrawer />
        </div>
      </div>
    </PoProvider>
  );
}

export default PoPage;
