import { useEffect, useState } from "react";
import "./CreateProject.css";
import Users from "../../bu/actions/Users";
import Projects from "../../bu/actions/Projects";

import { DatePicker, Input, Button, Space, AutoComplete } from "antd";
import Designation from "../../bu/actions/Designation";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const placeHolder = { padding: 5 };
const { Option } = AutoComplete;

const tlDesignations = ["Team Lead", "Sr. Team Lead", "Project Coordinator"];
export default function CreateProject({
  messageApi,
  onCreateProjectCloseDrawer,
  emitter,
}) {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const [projectNameError, setProjectNameError] = useState("");
  const [selectedTeamError, setSelectedTeamError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [projectDescriptionError, setProjectDescriptionError] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [selectedAssigneeId, setSelectedAssigneeId] = useState("");
  const [options, setOptions] = useState([]);
  const [designationId, setDesignationId] = useState(0);
  const [submitProject, setSubmitProject] = useState(false);

  const clearInputs = () => {
    setProjectName("");
    setProjectDescription("");
    setSelectedAssignee("");
    setSelectedAssigneeId("");
    setDateRange(null);
  };
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const designations = await Designation.readAll();
        const designation = designations
          .filter((designation) => tlDesignations.includes(designation.name))
          .map((designation) => designation.id);
        setDesignationId(designation || 0);
      } catch (e) {
        console.log("error");
      }
    };
    getDesignation();
  }, []);

  useEffect(() => {
    emitter.on("create-project", () => setSubmitProject(true));
    emitter.on("clear-project-form", () => {
      clearInputs();
    });

    return () => {
      // Remove the event listener when the component unmounts
      emitter.off("create-project", () => setSubmitProject(true));
      emitter.off("clear-project-form", () => {
        clearInputs();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitProject) {
      console.log("72 project submitted");
      handleCreateClick();
    }
  }, [submitProject]);

  const handleChange = (value, option) => {
    console.log(`selected ${value}`);
    const selectedLabel = value; // The label of the selected option
    const selectedId = option.key;
    setSelectedAssignee(selectedLabel);
    setSelectedAssigneeId(selectedId);
    setSelectedTeamError("");
  };

  const handleCreateClick = async () => {
    let errorFlag = false;
    console.log(
      "handleCreateClick 75",
      projectName,
      selectedAssignee,
      dateRange
    );
    const specialCharRegex = /[!@#$%^&()_+"']/;
    if (!projectName) {
      setProjectNameError("Project Name is mandatory.");
      errorFlag = true;
    } else if (specialCharRegex.test(projectName)) {
      setProjectNameError("Values not allowed for Project Name");
      errorFlag = true;
    } else {
      setProjectNameError("");
    }

    if (!selectedAssignee) {
      setSelectedTeamError("Assign To is mandatory.");
      errorFlag = true;
    } else {
      setSelectedTeamError("");
    }

    if (
      !dateRange ||
      dateRange.length !== 2 ||
      !dateRange[0] ||
      !dateRange[1]
    ) {
      setDateRangeError("Both start and end dates are mandatory.");
      errorFlag = true;
    } else {
      setDateRangeError("");
    }

    if (!projectDescription) {
      setProjectDescriptionError("Project Description is mandatory.");
      errorFlag = true;
    } else {
      setProjectDescriptionError("");
    }

    if (errorFlag) {
      setSubmitProject(false);
      return;
    }

    const data = {
      name: projectName,
      description: projectDescription,
      assignee: Number(selectedAssigneeId),
      starts_at: dateRange ? dateRange[0] : null,
      ends_at: dateRange ? dateRange[1] : null,
    };

    try {
      await Projects.create(data);
      clearInputs();
      onCreateProjectCloseDrawer();
      messageApi.open({
        type: "success",
        content: "Project successfully created",
      });
    } catch (error) {
      console.log(8989, error);
      messageApi.open({
        type: "error",
        content: error?.response?.data || "Error adding time entry",
      });
    } finally {
      setSubmitProject(false);
    }
  };

  const getUsers = async (name) => {
    try {
      const users = await Users.readAll({
        searchMap: JSON.stringify({
          subquery: {
            OR: [
              {
                profile: {
                  first_name: { contains: name, mode: "insensitive" },
                },
              },
              {
                profile: { last_name: { contains: name, mode: "insensitive" } },
              },
            ],
          },
          profile: { designation_id: { in: designationId } },
        }),
      });
      console.log("users modified", users?.data);
      const modifiedUsers = users?.data?.map((user) => ({
        label: `${user.profile.first_name} ${user.profile.last_name}`,
        value: user.id,
      }));
      setOptions(modifiedUsers || []);
      console.log("users modified again", modifiedUsers?.data);
    } catch (e) {
      console.log(e);
      console.log("error");
    }
  };

  const handleSearch = async (value) => {
    await getUsers(value);
    setSearchValue(value);
  };

  return (
    <>
      <div style={{ margin: "auto 50px" }}>
        <div className="create-project-section" style={{ display: "flex" }}>
          <div className="create-project-custom-div">
            <label className="create-project-label">Project Name</label>
            <Input
              className="create-project-field"
              placeholder="Enter the project name"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectNameError("");
              }}
            />
            {
              <div className="create-project-error-message">
                {projectNameError ? projectNameError : " "}
              </div>
            }
          </div>
        </div>
        <div className="create-project-section">
          <div className="create-project-custom-div">
            <label className="create-project-label"> Assign to team</label>
            {/* <AutoComplete
              onSelect={handleChange}
              onSearch={handleSearch}
              // value={selectedAssignee}
              placeholder="Select the team"
              className="create-project-select-project"
            >
              {options.map((option) => (
                <Option key={option.value} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </AutoComplete> */}
            <AutoComplete
              onSelect={handleChange}
              onSearch={handleSearch}
              placeholder="Select the team"
              className="create-project-field"
              notFoundContent={
                searchValue.trim() !== "" && options.length === 0 ? (
                  <div className="create-project-no-data">
                    No such team available
                  </div>
                ) : null
              }
            >
              {options.map((option) => (
                <Option key={option.value} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </AutoComplete>

            {
              <div className="create-project-error-message">
                {selectedTeamError ? selectedTeamError : " "}
              </div>
            }
          </div>
          <div className="create-project-custom-div">
            <label className="create-project-label">
              Start date and End date
            </label>
            <Space direction="vertical" size={12}>
              <RangePicker
                className="create-project-field"
                value={dateRange}
                onChange={(dates) => {
                  setDateRange(dates);
                  setDateRangeError("");
                }}
              />
            </Space>
            {
              <div className="create-project-error-message">
                {dateRangeError ? dateRangeError : " "}
              </div>
            }
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="create-project-custom-discription">
            <label htmlFor="taskName" className="create-project-label">
              Work Description
            </label>
            <TextArea
              style={{ resize: "none" }}
              placeholder="Work Description"
              value={projectDescription}
              onChange={(e) => {
                setProjectDescription(e.target.value);
                setProjectDescriptionError("");
              }}
            />
            {
              <div className="create-project-error-message-description">
                {projectDescriptionError ? projectDescriptionError : " "}
              </div>
            }
          </div>
          {/* <div className="create-project-custom-button">
            <Button
              className="create-project-button" type="primary" onClick={handleCreateClick}>Create</Button>
          </div> */}
        </div>
      </div>
    </>
  );
}
