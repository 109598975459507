import { useEffect, useState } from "react";
import Project from "../../../../bu/actions/Projects";
import Users from "../../../../bu/actions/Users";
import Designation from "../../../../bu/actions/Designation";
const tlDesignations = ["Team Lead", "Sr. Team Lead", "Project Coordinator"];
export default function useProject({ pageSize = 10 }) {
  const [projects, setProjects] = useState([]);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [workAllocaateOpen, setWorkAllocaateOpen] = useState(false);
  const [projectAllocaateOpen, setProjectAllocate] = useState(false);
  const [designationId, setDesignationId] = useState(0);
  const [projectsLoadingStatus, setProjectsLoadingStatus] = useState({
    status: false,
  });
  const [projectsMeta, setProjectsMeta] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(pageSize);
  const [tls, setTls] = useState([]);
  const [projectEditMode, setProjectEditMode] = useState({
    status: false,
    projectId: null,
  });

  const [filters, setFilters] = useState({
    fromDate: "",
    endDate: "",
    allocated: true,
    notAllocated: true,
  });
  function updateFilters(filterKey, value) {
    console.log(filterKey, value);
    let tf = { ...filters };
    tf[filterKey] = value;
    setFilters(tf);
  }
  useEffect(() => {
    console.log("change called");
    if (page >= 0) loadProjects();
    else setPage(0);
  }, [page]);
  async function loadProjects(append = true) {
    console.log("Loading projects----", page);
    try {
      setProjectsLoadingStatus({ status: true });
      const projectsData = await Project.readAll({
        skip: page * limit,
        limit: pageSize,
        sort: "created_at",
        sortOrder: "desc",
      });
      console.log(projectsData);
      if (append) setProjects([...projects, ...projectsData.data]);
      else {
        setProjects([...projectsData.data]);
      }
      console.log(projects);
      setProjectsMeta(projectsData.meta);
      setProjectsLoadingStatus({ status: false });
    } catch (e) {
      console.log(e);
      setProjectsLoadingStatus({ status: false });
    }
  }

  async function createProject({}) {}
  async function loadTLs() {
    try {
      const designations = await Designation.readAll();
      const designation = designations
        .filter((designation) => tlDesignations.includes(designation.name))
        .map((designation) => designation.id);
      setDesignationId(designation || 0);
      const users = await Users.readAll({
        searchMap: JSON.stringify({
          profile: { designation_id: { in: designation } },
        }),
        limit: 1000,
      });
      setTls(users.data.map((d) => ({ ...d, selected: false })));
    } catch (e) {
      console.log(e);
    }
  }
  return {
    projects,
    projectsMeta,
    loadProjects,
    page: { value: page, change: setPage },
    projectsLoadingStatus,
    createProjectDrawer: {
      editMode: projectEditMode.status,
      isOpen: createProjectOpen,
      editProjectId: projectEditMode.projectId,
      open: () => {
        setProjectEditMode({
          status: false,
          projectId: null,
        });
        setCreateProjectOpen(true);
      },
      close: () => {
        setCreateProjectOpen(false);
      },
      edit: (projectId) => {
        console.log("edit called", projectId);
        setProjectEditMode({
          status: true,
          projectId: projectId,
        });
        setCreateProjectOpen(true);
      },
    },
    workAllocateDrawer: {
      isOpen: workAllocaateOpen,
      open: () => {
        setWorkAllocaateOpen(true);
      },
      close: () => {
        setWorkAllocaateOpen(false);
      },
    },
    projectAllocateDrawer: {
      isOpen: projectAllocaateOpen,
      open: () => {
        setProjectAllocate(true);
      },
      close: () => {
        setProjectAllocate(false);
      },
    },
    tl: { load: loadTLs, list: tls, update: setTls },
    filters: { values: filters, update: updateFilters },
  };
}
