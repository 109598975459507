import React, { useEffect } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import { usePo } from "./payments.context";
import dayjs from "dayjs";

const { Option } = Select;

const POForm = () => {
  const { createPo, editPo, drawerService } = usePo();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    if (drawerService.mode === "create") createPo(values);
    if (drawerService.mode === "edit") editPo(values);
    // Here you would typically send the form data to the server
  };

  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    console.log("edit id updated", drawerService.editId);
    if (drawerService.mode === "create") {
      form.resetFields();
    }
    if (drawerService.editId) {
      console.log(drawerService.editObject);
      //   form.setFieldValue(drawerService.editObject);
      form.setFieldsValue({
        cToClient: 500,
        ...drawerService.editObject,
        poDate: dayjs(drawerService.editObject.poDate),
      });
    }
  }, [drawerService.editId, drawerService.isOpen, form]);
  return (
    <div style={{}}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Please select a customer!" }]}
          >
            <Select
              showSearch
              placeholder="Search customer name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* Replace with actual customer options */}
              <Option value="customer1">Customer 1</Option>
              <Option value="customer2">Customer 2</Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ flex: 1 }}
            name="quoteNo"
            label="Quote No"
            rules={[
              { required: true, message: "Please input the quote number!" },
            ]}
          >
            <Select placeholder="Select quote number">
              {/* Replace with actual quote options */}
              <Option value="quote1">USICAQ1</Option>
              <Option value="quote2">USICAQ2</Option>
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }} />
          <Form.Item>
            <Button type="default" onClick={onReset}>
              Clear
            </Button>

            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              {drawerService.mode === "create" && "Create"}
              {drawerService.mode === "edit" && "Edit"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default POForm;
