import { get, post, put } from '../../api/request'
import objectToUrlParams from '../../utils/objectToUrlParams';
class Quote {
  static #baseUrl = '/po'
  static async create(data) {
    return await post(this.#baseUrl, data)

  }
  static async update(data, id) {
    console.log(10, id)
    return await put(`${this.#baseUrl}/${id}`, data)
  }
  static async delete() { }
  static async readAll(params = {}) {
    const urlParams = objectToUrlParams(params)
    return await get(this.#baseUrl + '?' + urlParams)
    // return await get(this.#baseUrl)
  }
  static async readOne() { }
}

export default Quote;
