import { Select, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { get, put } from "../../../../api/request";
import { addActivity } from "./data";
export default function PrimaryEstimate({
  estimate,
  divisionId,
  estimationType,
}) {
  const [data, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [assembly, setAssembly] = useState([]);
  async function loadAssembly() {
    try {
      const res = await get("assembly");
      console.log("getting assembly", res);
      setAssembly(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadPrimary() {
    try {
      console.log(`estimate/${estimate?.id}/primary-estimation`);
      console.log(estimate);
      const res = await get(`estimate/${estimate?.id}/primary-estimation`);
      setData(res.data);
      setOData(JSON.parse(JSON.stringify([...res.data])));
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    loadAssembly();
    loadPrimary();
    console.log(estimate, divisionId, estimationType);
  }, [estimate, divisionId, estimationType]);
  useEffect(() => {
    console.log("data");
    console.log(data);
  }, [data]);
  useEffect(() => {
    console.log("some change happning");
  });
  return (
    <div
      style={{
        padding: 10,
        background: "#aaa",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div style={{ float: "right" }}>
        {" "}
        <Button
          onClick={async (e) => {
            console.log(estimate);
            // const res = await get(`estimate/${estimate?.id}/primary-estimation`);
            setData((pv) => [...pv, {}]);
            // console.log(res);
          }}
          type="primary"
          style={{ marginBottom: 20 }}
        >
          Add Item
        </Button>
      </div>
      <div style={{ flex: 1, overflow: "scroll" }}>
        {/* {JSON.stringify(data)} */}
        <table style={{ width: "100%" }}>
          <thead>
            <tr
              style={{
                fontSize: 12,
                background: "#eee",
              }}
            >
              <td style={{ padding: 5 }}>SL/NO</td>
              <td style={{ padding: 5 }}>ASSEMBLY</td>
              <td style={{ padding: 5 }}>PROFILE TYPE</td>
              <td style={{ padding: 5 }}>PROFILE PREFIX</td>
              <td style={{ padding: 5 }}> QTY</td>
              <td style={{ padding: 5 }}>TIME /ELEMENT IN MIN</td>
              <td style={{ padding: 5 }}>Weight</td>
              <td style={{ padding: 5 }}>TOTAL ESTIMATE</td>
              <td style={{ padding: 5 }}>REPETABILITY % (-)</td>
              <td style={{ padding: 5 }}>COMPLEXITY % (+)</td>
              <td style={{ padding: 5 }}>TOTAL TIME IN HOUR</td>
              <td style={{ padding: 5 }}>REMARKS</td>
              <td style={{ padding: 5 }}></td>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr
                key={"main" + i}
                style={{
                  background: i % 2 === 0 ? "#fff" : "#f1f1f1",
                  fontSize: 13,
                }}
              >
                <td style={{ padding: 5, textAlign: "center" }}>{i + 1}</td>
                <td style={{ padding: 5 }}>
                  {/* {d.assembly_id} {data[0].assembly_id} */}
                  <Select
                    style={{ minWidth: 150 }}
                    defaultValue={d.assembly_id}
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].assembly_id = e;
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                  >
                    {assembly.map((d, i) => (
                      <option key={"ass" + i} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </Select>
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].profile_type_id = e;
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                    style={{ minWidth: 150 }}
                    defaultValue={d?.profile_type?.name}
                  >
                    {assembly.filter((a) => a.id === d.assembly_id)[0] &&
                      assembly
                        .filter((a) => a.id === d.assembly_id)[0]
                        .profile_type.map((d) => (
                          <option value={d.id}>{d.name}</option>
                        ))}
                  </Select>
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  {d?.profile_type?.profile_prefix}
                </td>
                <td style={{ padding: 5 }}>
                  <Input
                    value={d.quantity}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].quantity = parseInt(e.target.value);
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    style={{ width: 40 }}
                  />
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Input
                    value={d.duration_in_mins}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].duration_in_mins = e.target.value;
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    style={{ width: 50, textAlign: "center" }}
                  />
                </td>
                <td style={{ padding: 5 }}>
                  <Input
                    value={d.weight}
                    onChange={(e) => {}}
                    style={{ width: 50 }}
                  />
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Input
                    value={d.duration_in_mins}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].duration_in_mins = parseFloat(e.target.value);
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    style={{ width: 40 }}
                  />
                </td>
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].repeatability = e;
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                    style={{ minWidth: 50 }}
                    defaultValue={d?.repeatability}
                  >
                    <option value={0}>{0}</option>
                    <option value={10}>{10}</option>
                    <option value={20}>{20}</option>
                    <option value={30}>{30}</option>
                  </Select>
                  {/* <Input
                    value={d.repeatability}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].repeatability = parseInt(e.target.value);
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    tyle={{ width: 40 }}
                  /> */}
                </td>
                {/* <td style={{ padding: 5, textAlign: "center" }}>0.15</td> */}
                <td style={{ padding: 5, textAlign: "center" }}>
                  <Select
                    onChange={(e) => {
                      console.log(e);
                      setData((dt) => {
                        dt[i].complexity = e;
                        console.log(dt);
                        return [...dt];
                      });
                      console.log(data);
                    }}
                    style={{ minWidth: 90 }}
                    defaultValue={d?.complexity}
                  >
                    {[0.5, 1.0, 1.25, 2.0, 2.5, 3.0, 3.5, 4.0, 5.0].map(
                      (d, i) => (
                        <option value={d}>{d}</option>
                      )
                    )}
                  </Select>
                  {/*                   <Input
                    value={d.complexity}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].complexity = parseFloat(e.target.value);
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    style={{ width: 40 }}
                  /> */}
                </td>
                {/* <td style={{ padding: 5, textAlign: "center" }}>0.15</td> */}
                <td style={{ padding: 5, textAlign: "center" }}>1.50 </td>
                <td style={{ padding: 5 }}>
                  <Input
                    value={d.remark}
                    onChange={(e) => {
                      setData((dt) => {
                        dt[i].remark = e.target.value;
                        console.log(dt);
                        return [...dt];
                      });
                    }}
                    style={{ width: 100 }}
                  />
                </td>
                <td
                  style={{
                    padding: 5,
                    fontSize: 10,
                    minWidth: 80,
                    color: "blue",
                  }}
                >
                  {!(JSON.stringify(data[i]) === JSON.stringify(oData[i])) && (
                    <Button
                      type="primary"
                      onClick={async (e) => {
                        try {
                          console.log(data, oData);
                          // console.log(JSON.stringify(data), JSON.stringify(oData));
                          console.log(
                            JSON.stringify(data) === JSON.stringify(oData)
                          );
                          const res = await put(
                            `estimate/${estimate?.id}/primary-estimation`,
                            {
                              primaryEstimations: data.map((d) => {
                                delete d.estimate_id;
                                return d;
                              }),
                            }
                          );
                          console.log(res);
                          loadPrimary();
                          alert("updated successfully");
                        } catch (e) {
                          console.log(e);
                          alert("update Failed");
                        }
                      }}
                    >
                      save
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div></div>
      </div>
    </div>
  );
}
