import Sidebar from "./InvoiceSidebar";
import { InvoiceProvider } from "./Invoice.context";
import PosList from "./InvoiceList";
import PoDrawer from "./InvoiceDrawer";
function InvoicePage({}) {
  return (
    <InvoiceProvider>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Sidebar />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginLeft: 10 }}>Invoices</h2>
          <PosList />
          {/* {JSON.stringify(POs)} */}
          <PoDrawer />
        </div>
      </div>
    </InvoiceProvider>
  );
}

export default InvoicePage;
