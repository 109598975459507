import { CustomersProvider, useCustomer } from "./Customer.context";
import { Button, Table } from "antd";

export default function CustomersList() {
  const { customers, extend, drawerService, searchKey } = useCustomer();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Street',
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Zip/Postal Code',
      dataIndex: 'zip_code',
      key: 'zip_code',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <Button onClick={(e) => drawerService.openToEdit(record.id)}>
          Edit
        </Button>
      ),
    }
  ];
  return (
    <div style={{ flex: 1, overflow: "scroll" }}>
     {customers && customers.length > 0 ? (
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              <div style={{ display: "flex", background: "#f1f1f1" }}>
                <div style={{ flex: 1, padding: 2 }}>{"Name"}</div>
                <div style={{ flex: 1, padding: 2 }}>{"Phone"}</div>
                <div style={{ flex: 1, padding: 2 }}>{"Email"}</div>
              </div>
              {record?.contacts.length > 0 ? (
                record.contacts.map((contact, i) => (
                  <div key={"contact" + i} style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: 2 }}>{contact.name}</div>
                    <div style={{ flex: 1, padding: 2 }}>{contact.phone_number}</div>
                    <div style={{ flex: 1, padding: 2 }}>{contact.email}</div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center", padding: 10 }}>No contacts available</div>
              )}


            </p>
          ),
          rowExpandable: (record) => record?.contacts?.name !== 'Not Expandable',
        }}
        dataSource={customers.filter(d => (d.name.toUpperCase().includes(searchKey.toUpperCase()) || d.city.toUpperCase().includes(searchKey.toUpperCase()) || d.street.toUpperCase().includes(searchKey.toUpperCase()) || d.zip_code.toUpperCase().includes(searchKey.toUpperCase()) || d.state.toUpperCase().includes(searchKey.toUpperCase()) || d.country.toUpperCase().includes(searchKey.toUpperCase()))).map(d => ({ ...d, key: d.id }))}
        pagination={false}

      />
      ) : (
        <div style={{ textAlign: "center", padding: 10 }}>No data available</div>
      )}
    </div>
  );
}
