import { redirect, useNavigate } from "react-router-dom";
import {
  Button,
  Drawer,
  Form,
  Input,
  DatePicker,
  Table,
  Space,
  ConfigProvider,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "./index.css";
import emptyicon from "../../../../assets/EmptyState.jpeg";
import NoData from "../../../../components/noData/index.js";
import Role from "../../../../bu/actions/Roles.js";
import CreateRole from "../../../../components/creationComponents/CreateRole";

const { RangePicker } = DatePicker;
const { Search } = Input;

export default function RolePage({ messageApi }) {
  const [form] = Form.useForm();

  const [searchValue, setSearchValue] = useState("");
  const [roles, setRoles] = useState({});

  const [editButtonName, setEditButtonName] = useState("Save");
  const [editTitleName, setEditTitleName] = useState("Create Role");
  const [isFormTypeUpdate, setIsFormTypeUpdate] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);

  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);

  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);

  const showRoleDrawer = () => {
    setOpen(true);
    setEditButtonName("Save");
    setEditTitleName("Create Role");
    form.resetFields(["roleName", "description"]);
  };
  const onClose = () => {
    setOpen(false);
    getQuotes(1);
    setCurrentPage(1);
    console.log("drawer closing");
    setIsFormTypeUpdate(false);
    form.resetFields();
  };
  const onChange = (page) => {
    setCurrentPage(page);
    getQuotes(page);
  };

  const handleEditClick = async (record) => {
    try {
      const roleDetails = await Role.readById(record.id);
      setEditButtonName("Update");
      setEditTitleName("Edit Permissions");
      setIsFormTypeUpdate(true);
      setSelectedRole({
        ...roleDetails,
        permissions: roleDetails.permissions,
      });
      setOpen(true);
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  };

  const getQuotes = async (page) => {
    const response = await Role.readAll({ skip: (page - 1) * 10 });
    setRoles(response);
    setTotal(response.length);
  };
  useEffect(() => {
    try {
      getQuotes(1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Space size="middle">
          <span
            style={{ color: "blue" }}
            onClick={() => handleEditClick(record)}
          >
            <EditOutlined />
          </span>
          {/* <span style={{ color: "red" }}>
            <DeleteOutlined />
          </span> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const newCellPaddingBlock = window.innerWidth < 740 ? 10 : 7;
      setCellPaddingBlock(newCellPaddingBlock);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const onSearch = async (name) => {
  //   setSearchValue(name);
  //   try {
  //     const filteredRole = await Role.readAll({ name: name?.trim() || "" });
  //     setRoles(filteredRole);
  //   } catch (error) {
  //     console.error("Error fetching filtered projects:", error);
  //   }
  // };
  const [filteredRoles, setFilteredRoles] = useState([]);

const onSearch = async (name) => {
  setSearchValue(name);
  try {
    const filteredRole = await Role.readAll({ name: name?.trim() || "" });
    setFilteredRoles(filteredRole);
  } catch (error) {
    console.error("Error fetching filtered roles:", error);
  }
};

useEffect(() => {
  setRoles(filteredRoles);
}, [filteredRoles]);
  return (
    <div
      style={{ borderBottom: "none", backgroundColor: "#ECECF0D9", flex: 1 }}
    >
      <div className="role-container">
        <div className="role-page-button">
          <div className="role-page-title-container">
            <div className="role-page-title">Role List</div>
          </div>
          <div className="role-page-container">
            <Button
              style={{ height: 40 }}
              type="primary"
              onClick={showRoleDrawer}
            >
              + Create Role
            </Button>
          </div>
        </div>
        <div className="role-table-field">
          <Input
            placeholder="Search by Name"
            onChange={(e) => onSearch(e.target.value)}
            allowClear
            className="role-search-input"
          />
        </div>
      </div>
      <div style={{ padding: 20, overflow:"scroll" }}>
        {Array.isArray(roles) && roles.length > 0 ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  cellPaddingBlock: cellPaddingBlock,
                },
              },
            }}
          >
            <Table
              style={{ backgroundColor: "#ECECF0D9" }}
              className="task-table"
              columns={columns}
              dataSource={roles}
              // pagination={{
              //   onChange,
              //   total,
              //   defaultPageSize: 10,
              //   current: currentPage,
              //   showSizeChanger: false,
              // }}
              pagination={false}
            />
          </ConfigProvider>
        ) : (
          <div>
            <NoData
              emptyIcon={emptyicon}
              noDataMessage="You have no roles yet"
            />
          </div>
        )}
      </div>
      <Drawer
        width={"50%"}
        style={{
          textAlign: "center",
          fontSize: 20,
          fontWeight: 500,
        }}
        title={
          <span style={{ textAlign: "center", fontSize: 20, fontWeight: 500 }}>
            {editTitleName}
          </span>
        }
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div>
          <CreateRole
            form={form}
            onCloseRole={onClose}
            messageApi={messageApi}
            selectedRole={selectedRole}
            isFormTypeUpdate={isFormTypeUpdate}
            editButtonName={editButtonName}
          />
        </div>
      </Drawer>
    </div>
  );
}
