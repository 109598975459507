import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, DatePicker, Typography } from "antd";
import { usePo } from "./Cor.context";
import dayjs from "dayjs";
import { get } from "../../../../api/request";

const { Option } = Select;

const POForm = () => {
  const { createPo, editPo, drawerService } = usePo();
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const watchCustomerName = Form.useWatch("customerName", form);
  async function loadCustomers() {
    const res = await get(`customer?limit=1000`);
    setCustomers(res.data);
  }
  async function loadProjects(customerId) {
    console.log(20, customerId);
    const res = await get(`project?limit=1000&
    searchMap=${JSON.stringify({
      NOT: { po: null },
    })}`);
    setProjects(res.data);
  }
  useEffect(() => {
    if (watchCustomerName) {
      loadProjects(watchCustomerName);
    }
  }, [watchCustomerName]);
  const [corAmount, setCorAmount] = useState(0);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    if (drawerService.mode === "create") createPo(values) (drawerService.close());
    if (drawerService.mode === "edit") editPo(values);
    // Here you would typically send the form data to the server
  };

  const onReset = () => {
    form.resetFields();
    setCorAmount(null);
  };
  useEffect(() => {
    console.log("edit id updated", drawerService.editId);
    if (drawerService.mode === "create") {
      form.resetFields();
    }
    loadCustomers();
    if (drawerService.editId) {
      console.log(drawerService.editObject);
      //   form.setFieldValue(drawerService.editObject);
      form.setFieldsValue({
        cToClient: 500,
        ...drawerService.editObject,
        poDate: dayjs(drawerService.editObject.poDate),
      });
    }
  }, [drawerService.editId, drawerService.isOpen, form]);

  const handleCorAmountChange = (value) => {
    const parsedCorAmount = parseFloat(value) + 10 || 0;
    setCorAmount(parsedCorAmount.toFixed(2)); // Format to 2 decimal places
  };
  return (
    <div style={{}}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="customerName"
            label="Customer name"
            rules={[{ required: true, message: "Please select a customer!" }]}
          >
            <Select
              showSearch
              placeholder="Search customer name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* Replace with actual customer options */}
              {customers.map((d, i) => (
                <Option value={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="project_id"
            label="Project Name"
            rules={[{ required: true, message: "Please select a Project!" }]}
          >
            <Select
              showSearch
              placeholder="Search Project name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={!form.getFieldValue("customerName")}
            >
              {/* Replace with actual customer options */}
              {projects.map((d, i) => (
                <Option value={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}>
          {/* <Form.Item
            style={{ flex: 1 }}
            name="corNumber"
            label="COR. Number"
            rules={[{ required: true, message: "Please input the COR number!" }]}
          >
            <Input placeholder="USICAP1" />
          </Form.Item> */}

          <Form.Item
            style={{ flex: 1 }}
            name="corAmount"
            label="COR Amount"
            rules={[
              {
                required: true,
                message: "Please input COR Amount!",
              },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  "Please enter a valid integer or floating-point number!",
              },
            ]}
          >
            <Input
              placeholder="200"
              value={corAmount}
              onChange={(e) => handleCorAmountChange(e.target.value)}
            />
          </Form.Item>
        </div>
        <div style={{ display: "flex", gap: 15 }}></div>
        <Form.Item
          style={{ flex: 1 }}
          name="corApprovedDate"
          label="COR Approved Date"
          rules={[
            { required: true, message: "Please select the COR Approved date!" },
          ]}
        >
          <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Revised PO Amount"
          name="revisedPOAmount"
        >
          <Typography.Text>{corAmount}</Typography.Text>
        </Form.Item>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }} />
          <Form.Item>
            <Button type="default" onClick={onReset}>
              Clear
            </Button>

            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              {drawerService.mode === "create" && "Create"}
              {drawerService.mode === "edit" && "Edit"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default POForm;
