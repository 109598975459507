import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, DatePicker } from 'antd';
import { usePo } from './Po.context';
import dayjs from 'dayjs';

const { Option } = Select;

const POForm = () => {
  const { createPo, editPo, drawerService } = usePo();
  const [form] = Form.useForm();
  const watchCustomerName = Form.useWatch('customerName', form);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [disableCustomer, setdisableCustomer] = useState(false);

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    if (drawerService.mode === 'create') createPo(values);
    if (drawerService.mode === 'edit') editPo(values);
    drawerService.close();
    // Here you would typically send the form data to the server
  };

  const onReset = () => {
    form.resetFields();
  };
  useEffect(() => {
    console.log('edit id updated', drawerService.editId);
    if (drawerService.mode === 'create') {
      form.resetFields();
      setdisableCustomer(false)
    }
    if (drawerService.editId) {
      console.log(drawerService.editObject);
      //   form.setFieldValue(drawerService.editObject);
      setSelectedCustomer('')
      setSelectedProject('')
      setdisableCustomer(true);
      form.setFieldsValue({
        cToClient: 500,
        ...drawerService.editObject,
        poDate: dayjs(drawerService.editObject.poDate),
      });
    }
  }, [drawerService.editId, drawerService.isOpen, form]);

  useEffect(() => {
    if (watchCustomerName) {
      //drawerService.fetchQuotesForCustomers();
     // drawerService.fetchProjectsForCustomers()
    }
  }, [watchCustomerName]);
  return (
    <div style={{}}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ display: 'flex', gap: 15 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: 'Please select a customer!' }]}
          >
            <Select
              showSearch
              placeholder="Search customer name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(customerId) => {
                // Handle the selection of customer here
                console.log("Selected customer ID:", customerId);
                drawerService.fetchProjectsForCustomers(customerId);
                setSelectedCustomer(customerId)
                form.setFieldValue('project_id',null)
              }}
              disabled={disableCustomer}
            >
              {drawerService &&
                drawerService.customers.map((customer) => (
                  <Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="project_id"
            label="Project Name"
            rules={[{ required: true, message: 'Please select a project' }]}
          >
            <Select placeholder="Select a project" disabled={!selectedCustomer}  onChange={(projectId) => {
                // Handle the selection of customer here
                console.log("Selected project ID:", projectId);
                drawerService.fetchQuotesForCustomers(projectId);
                setSelectedProject(projectId);
                form.setFieldValue('quote_id',null)
              }}  >
              {drawerService &&
                drawerService.projects &&
                drawerService.projects.map((project) => (
                  <Option key={project.id} value={project.id}>
                    {project.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
        <Form.Item
            style={{ flex: 1 }}
            name="quote_id"
            label="Quote Number"
            rules={[{ required: true, message: 'Please select a quote' }]}
          >
            <Select placeholder="Select a quote" disabled={!selectedProject}>
              {drawerService &&
                drawerService.quotes &&
                drawerService.quotes.map((quote) => (
                  <Option key={quote.id} value={quote.id}>
                    {quote.seq_number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="po_number"
            label="PO. Number"
            rules={[{ required: true, message: 'Please input the PO number!' }]}
          >
            <Input placeholder="USICAP1" />
          </Form.Item>

          
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
        <Form.Item
            style={{ flex: 1 }}
            name="detailing_amount"
            label="Detailing"
            rules={[
              { required: true, message: 'Please input detailing!' },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  'Please enter a valid integer or floating-point number!',
              },
            ]}
          >
            <Input placeholder="200" />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="design_amount"
            label="Design"
            rules={[
              { required: true, message: 'Please input design!' },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  'Please enter a valid integer or floating-point number!',
              },
            ]}
          >
            <Input placeholder="300" />
          </Form.Item>

          
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
        <Form.Item
            style={{ flex: 1 }}
            name="design_vendor_amount"
            label="Design Vendor"
            rules={[
              { required: true, message: 'Please input design vendor!' },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  'Please enter a valid integer or floating-point number!',
              },
            ]}
          >
            <Input placeholder="200" />
          </Form.Item>
          <Form.Item
            style={{ flex: 1 }}
            name="c_to_usica"
            label="C to Usica"
            rules={[
              { required: true, message: 'Please input C to Usica!' },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  'Please enter a valid integer or floating-point number!',
              },
            ]}
          >
            <Input placeholder="100" />
          </Form.Item>

         
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
        <Form.Item
            style={{ flex: 1 }}
            name="c_to_client"
            label="C to Client"
            rules={[
              { required: true, message: 'Please input C to Client!' },
              {
                pattern: /^(\d+|\d+\.\d+)$/,
                message:
                  'Please enter a valid integer or floating-point number!',
              },
            ]}
          >
            <Input placeholder="Value" />
          </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name="po_date"
          label="PO. Date"
          rules={[{ required: true, message: 'Please select the PO date!' }]}
        >
          <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>\
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} />
          <Form.Item>
            <Button type="default" onClick={onReset}>
              Clear
            </Button>

            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              {drawerService.mode === 'create' && 'Create'}
              {drawerService.mode === 'edit' && 'Edit'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default POForm;
