import { useEffect, useState } from 'react';
import { Space, Table, Button, Drawer, Input, DatePicker, ConfigProvider } from 'antd';
import CreateTask from '../../../../components/creationComponents/CreateTask';
import { EditOutlined } from "@ant-design/icons";
import emptyIcon from '../../../../assets/EmptyState.png';
import NoData from '../../../../components/noData';
import "./index.css";
import Tasks from '../../../../bu/actions/Tasks';
import { useAppState } from "../../../../state";

export default function TasksPage({ messageApi }) {
  const [state, setState] = useAppState();
  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [taskNames, setTaskNames] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const { RangePicker } = DatePicker;
  const { Search } = Input;

  const [cellPaddingBlock, setCellPaddingBlock] = useState(10);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: '',
    },
    {
      title: 'Description',
      dataIndex: '',
      key: '',
    },
    {
      title: 'Action',
      key: 'action',
      
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record) => (
        <Space size="middle">
          <span style={{ color: 'blue' }}>
            <EditOutlined />
          </span>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: 1,
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    },
    {
      key: 2,
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
    },
    {
      key: 4,
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
      description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
    },
  ];

  const getTasks = async (page) => {
    console.log('getTasks currentPage', page);

    const response = await Tasks.readAll({ skip: (page - 1) * 10 });
    setTasks(response.data);
    const names = response.data.map((item) => item.name);
    setTaskNames(names);
    console.log(response.data);
    setTotal(response.meta.total);
  };

  useEffect(() => {
    try {
      getTasks(1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");

  const handleCreateTask = async () => {
    setOpen(true);
    setTaskCreated(true);
  };

  const onTaskCreated = (newTask) => {
    // Update the state with the new task
    setTasks([...tasks, newTask]);
    setOpen(false);
    setTaskCreated(false); // Reset the flag
  };

  const onClose = () => {
    setOpen(false);
    getTasks(1);
    setCurrentPage(1);
  };

  const onChange = (page) => {
    setCurrentPage(page);
    getTasks(page);
  };

  const onSubmit = async () => {
    setOpen(false);
    try {
      const filteredTasks = await Tasks.readAll({
        // name: value?.trim() || '',
        fromDate: dateRange ? new Date(dateRange[0].$d).toISOString() : '',
        toDate: dateRange ? new Date(dateRange[1].$d).toISOString() : '',
      });
      console.log("dateRange ====>", filteredTasks);
      setTasks(filteredTasks.data);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleDateChange = async (dates) => {
    try {
      console.log("is clear", dates);
      const filteredTasks = await Tasks.readAll({
        name: searchValue?.trim() || '',
        fromDate: dates ? new Date(dates[0].$d).toISOString() : '',
        toDate: dates ? new Date(dates[1].$d).toISOString() : '',
      });
      setDateRange(dates);
      setTasks(filteredTasks.data);
    } catch (error) {
      console.error('Error fetching filtered projects:', error);
    }
  };

  const expandedRowRender = (record) => {
    // Add your JSX content for the expanded row here
    return (
      <p>{record.address}</p>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const newCellPaddingBlock = window.innerWidth < 740 ? 10 : 7;
      setCellPaddingBlock(newCellPaddingBlock);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="task-main-container" style={{ padding: "0px 0px", borderBottom: 'none', backgroundColor: '#ECECF0D9' }}>
        <div className="task-page-button">
          <div className="task-page-title-container">
            <div className="task-page-title">
              List Activity
            </div>
          </div>
          <div className="task-page-container">
            {(state?.user?.permissions.includes('task.create') || state?.user?.permissions.includes('super-admin')) && (
              <>
                <Button
                  style={{ height: 40 }}
                  type="primary"
                  onClick={handleCreateTask}
                >
                  + Create Activity
                </Button>
              </>)}
          </div>
        </div>
        <div className='task-table-field'>
          <Search
            className="task-search-input"
            placeholder="Search for task name"
            // onSearch={onSearch}
            allowClear
          />
          <RangePicker
            onChange={handleDateChange}
            placeholder={['From Date', 'To Date']}
          />
        </div>
      </div>
      {Array.isArray(tasks) && tasks.length > 0 ? (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: cellPaddingBlock,
              },
            },
          }}
        >
          <Table
            style={{ padding: '0px 20px', backgroundColor: '#ECECF0D9' }}
            className='task-table'
            columns={columns}
            dataSource={tasks}
            expandedRowRender={expandedRowRender}
            pagination={{
              onChange,
              total,
              defaultPageSize: 10,
              current: currentPage,
              showSizeChanger: false,
            }}
            expandable={{
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            // dataSource={data}
          />
        </ConfigProvider>
      ) : (
        <div>
          <NoData
            emptyIcon={emptyIcon}
            noDataMessage="You have no tasks yet"
          />
        </div>
      )}
      <Drawer
        width="50%"
        title="Update Activity"
        placement="right"
        onClose={onClose}
        visible={open}
        extra={
          <div style={{ textAlign: "right", marginTop: 20 }}>
            <Button style={{ marginRight: 8 }} onClick={onClose}>
              Clear
            </Button>
            <Button
              style={{
                backgroundColor: "#8686ff",
                width: "75.5px",
                height: "30px",
              }}
              type="primary"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        }
      >
       {/* <CreateActivites messageApi={messageApi}  onCreateProjectCloseDrawer={onClose} /> */}
      </Drawer>
    </div>
  );
}
