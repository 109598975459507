import { Drawer, Input, Button, Select } from "antd";
import { useEffect, useState } from "react";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import { useCustomer } from "./Customer.context";
import Customer from "../../../../bu/actions/Customer";
const { Option } = Select;
function CustomerDrawer() {
  const { customers, drawerService, loadCustomers } = useCustomer();
  const [customer, setCustomer] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    contacts: [],
  });
  const [customerErrors, setCustomerErrors] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    contacts: [],
  });
  function validateCustomer() {
    const { name, street, city, state, country, zip_code } = customer;
    let hasError = false;
    console.log(name);
    if (!name) {
      setCustomerErrors((pv) => ({ ...pv, name: "name is missing !" }));
      hasError = true;
    }
    if (!street) {
      setCustomerErrors((pv) => ({ ...pv, street: "street missing !" }));
      hasError = true;
    }
    if (!city) {
      setCustomerErrors((pv) => ({ ...pv, city: "city missing !" }));
      hasError = true;
    }
    if (!state) {
      setCustomerErrors((pv) => ({ ...pv, state: "state missing !" }));
      hasError = true;
    }
    if (!country) {
      setCustomerErrors((pv) => ({ ...pv, country: "country missing !" }));
      hasError = true;
    }
    if (!zip_code) {
      setCustomerErrors((pv) => ({ ...pv, zip_code: "zip_code missing !" }));
      hasError = true;
    }
    return hasError;
  }
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setCustomerErrors((pv) => ({ ...pv, [name]: "" }));
  //   setCustomer((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };
  const [states, setStates] = useState([]); 
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      // If the selected country changes, load states
      loadStates(value);
    }

    setCustomerErrors((pv) => ({ ...pv, [name]: "" }));
    setCustomer((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const loadStates = (selectedCountry) => {
    // Your predefined data for states based on countries
    const stateData = {
      USA: [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
        "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",
        "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
        "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
      ],
      Canada: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan"]      ,
      India: ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry"],
    };

    const statesForCountry = stateData[selectedCountry] || [];
    setStates(statesForCountry);
    setCustomer((prevFormData) => ({
      ...prevFormData,
      state: "", // Reset the state when changing the country
    }));
  };
  
  useEffect(() => {
    if (drawerService.mode === "create") {
      setCustomer({
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
        contacts: [],
      });
      setCustomerErrors({
        name: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
        contacts: [],
      });
    }
    if (drawerService.mode === "edit") {
      const cu = customers.filter((d) => d.id === drawerService.editCustomerId);
      if (cu.length === 1) setCustomer({ ...cu[0] });
    }
  }, [drawerService.mode, drawerService.editCustomerId]);
  function updateConatact(e, i) {
    try {
      console.log(e, e.target.data);
      const _c = { ...customer };
      _c.contacts[i][e.target.name] = e.target.value;
      setCustomer(_c);
    } catch (e) {
      console.log(e);
    }
  }
  function deleteContact(i) {
    try {
      const _c = { ...customer };
      _c.contacts.splice(i, 1);
      setCustomer(_c);
    } catch (e) {
      console.log(e);
    }
  }
  function addContact() {
    try {
      const _c = { ...customer };
      _c.contacts.push({ name: "", phone_number: "", email: "" });
      setCustomer(_c);
    } catch (e) {
      console.log(e);
    }
  }
  const countries = ['USA', 'Canada', 'India'];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: () => <a>Delete</a>,
    },
  ];
  return (
    <div>
      <Drawer
        title={
          drawerService.mode === "create" ? "Create Customer" : "Edit Customer"
        }
        placement="right"
        onClose={(e) => {
          drawerService.close();
        }}
        style={{
          display: "flex",
          overflow: "hidden",
          // width: 600,
        }}
        width="700"
        open={drawerService.isOpen}
      >
        <h4>Customer Info</h4>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>Name</FormFieldTitle>
          <Input name="name" value={customer.name} onChange={handleChange} />
          <ErrorMessage>{customerErrors.name}</ErrorMessage>
        </div>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>Street</FormFieldTitle>
          <Input
            name="street"
            value={customer.street}
            onChange={handleChange}
          />
          <ErrorMessage>{customerErrors.street}</ErrorMessage>
        </div>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>city</FormFieldTitle>
          <Input name="city" value={customer.city} onChange={handleChange} />
          <ErrorMessage>{customerErrors.city}</ErrorMessage>
        </div>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>Country</FormFieldTitle>
          <Select
            name="country"
            value={customer.country}
            placeholder="select country"
            onChange={(value) => handleChange({ target: { name: "country", value } })}
            style={{ width: "100%", minWidth: "100%" }}
          >
            <Option value="">Select Country</Option>
            {countries.map((country) => (
              <Option key={country} value={country}>
                {country}
              </Option>
            ))}
          </Select>
          <ErrorMessage>{customerErrors.country}</ErrorMessage>
        </div>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>State</FormFieldTitle>
          <Select
            name="state"
            value={customer.state}
            placeholder="select state"
            onChange={(value) => handleChange({ target: { name: "state", value } })}
            style={{ width: "100%", minWidth: "100%" }}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select State</Option>
            {states.map((state) => (
              <Option key={state} value={state}>
                {state}
              </Option>
            ))}
          </Select>
          <ErrorMessage>{customerErrors.state}</ErrorMessage>
        </div>
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>ZIP/Postal Code</FormFieldTitle>
          <Input
            name="zip_code"
            value={customer.zip_code}
            onChange={handleChange}
          />
          <ErrorMessage>{customerErrors.zip_code}</ErrorMessage>
        </div>
        <hr style={{ marginTop: 30 }} />
        <div>
          <div style={{ display: "flex" }}>
            <h4 style={{ flex: 1 }}>Conatacts</h4>
            <Button onClick={addContact} type="primary">
              +
            </Button>
          </div>
          <div style={{ padding: 10, background: "#f1f1f1" }}>
            {customer.contacts &&
              customer.contacts.map((c, i) => (
                <div
                  key={"cu" + i}
                  style={{
                    margin: 10,
                    background: "#fff",
                    padding: 10,
                    display: "flex",
                    gap: 15,
                  }}
                >
                  <h3>{i + 1}.</h3>
                  <div style={{ flex: 1 }}>
                    <FormFieldTitle>name</FormFieldTitle>
                    <Input
                      name="name"
                      value={c.name}
                      onChange={(e) => updateConatact(e, i)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <FormFieldTitle>Phone</FormFieldTitle>
                    <Input
                      name="phone_number"
                      value={c.phone_number}
                      onChange={(e) => updateConatact(e, i)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <FormFieldTitle>Email</FormFieldTitle>
                    <Input
                      name="email"
                      value={c.email}
                      onChange={(e) => updateConatact(e, i)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{ fontSize: 12, color: "red" }}
                      size="small"
                      onClick={(e) => {
                        deleteContact(i);
                      }}
                    >
                      DELETE
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div style={{ padding: 10, display: "flex" }}>
          <div style={{ flex: 1 }} />
          <Button
            type="primary"
            onClick={async (e) => {
              if (validateCustomer()) return alert("clear all errors");
              else if (drawerService.mode === "create")
                await Customer.create(customer);
              if (drawerService.mode === "edit")
                await Customer.update(drawerService.editCustomerId, customer);
              loadCustomers();
              drawerService.close();
            }}
          >
            Save
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default CustomerDrawer;
