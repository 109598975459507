import React, { createContext, useContext, useEffect, useState } from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { get, post } from "../../../../api/request";

const PoContext = createContext();

export const PoProvider = ({ children }) => {
  const [POs, setPOs] = useState([]);
  const [searchKey, setSerachKey] = useState("");
  const [drawerState, setDrawerState] = useState({
    open: false,
    mode: "create",
    editId: null,
    editObject: null,
  });

  function openToCreate() {
    const _d = { ...drawerState };
    _d.open = true;
    _d.editId = null;
    _d.editObject = null;
    _d.mode = "create";
    setDrawerState(_d);
  }
  function openToEdit(id, object) {
    console.log(id, "from tehb open to edit ");
    const _d = { ...drawerState };
    _d.open = true;
    _d.mode = "edit";
    _d.editId = id;
    _d.editObject = object;
    setDrawerState(_d);
  }
  function close() {
    const _d = { ...drawerState };
    _d.open = false;
    setDrawerState(_d);
  }

  const loadCors = async () => {
    try {
      try {
        const res = await get("po?limit=1000");
        setPOs(res.data);
        console.log(res);
      } catch (e) {
        console.log(e);
      }
    } catch (e) {}
  };
  useEffect(() => {
    loadCors();
  }, []);
  const createPo = async (data) => {
    console.log(
      data,
      "cor_approved_date",
      dayjs(data.corApprovedDate).toISOString()
    );
    try {
      const res = await post("cor", {
        cor_approved_date: dayjs(data.corApprovedDate).toISOString(),
        project_id: data.project_id,
        cor_amount: parseInt(data.corAmount),
      });
      console.log(res);
      alert('Cor created successfully')
      loadCors()
    } catch (e) {
      console.log(e);
      alert("cor creation failed");
    }
    // setPOs((p) => [...p, data]);
  };
  const editPo = (data) => {
    setPOs((p) => [...p, data]);
  };

  return (
    <PoContext.Provider
      value={{
        POs,
        searchKey,
        setSerachKey,
        loadCors,
        createPo,
        editPo,
        editId: null,
        drawerService: {
          isOpen: drawerState.open,
          openToCreate,
          close,
          openToEdit,
          mode: drawerState.mode,
          editId: drawerState.editId,
          editObject: drawerState.editObject,
        },
      }}
    >
      {children}
    </PoContext.Provider>
  );
};
PoContext.propTypes = {
  children: propTypes.node.isRequired,
};
export const usePo = () => useContext(PoContext);
