import { Button } from "antd";
import { useEffect, useState } from "react";
import { getPrimaryEstimation } from "./data";
import OtherEstimation from "./OtherEstimation";
import PrimaryEstimate from "./PrimaryEstimate";
export default function Estimation({ divisionId, estimationType, estimates }) {
  const [selectedSection, setSelectedSection] = useState("primary");
  const [primaryEstimate, setprimaryEstimate] = useState([]);
  async function loadPrimary() {
    // const res = await getPrimaryEstimation(estimates[0]?.id);
    // setprimaryEstimate(res);
  }
  useEffect(() => {
    console.log("calling", estimates, divisionId, estimationType);
    // if (estimates[0]?.id) loadPrimary();
  }, [divisionId, estimationType, estimates, selectedSection]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
      }}
    >
      {/* {JSON.stringify(estimates)} */}
      <div
        style={{
          display: "flex",
          gap: 10,
          padding: 10,
          background: "#aaa",
          // border: "1px solid #000",
        }}
      >
        <Button
          type={selectedSection === "primary" ? "primary" : "default"}
          onClick={(e) => setSelectedSection("primary")}
        >
          Primary
        </Button>
        <Button
          type={selectedSection === "other" ? "primary" : "default"}
          onClick={(e) => setSelectedSection("other")}
        >
          other
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
        }}
      >
        {/* Estimation detail component:{JSON.stringify(estimate)}
        {JSON.stringify(primaryEstimate)} */}
        {selectedSection === "primary" && (
          <PrimaryEstimate
            data={primaryEstimate}
            estimate={estimates[0]}
            loadPrimary={loadPrimary}
            divisionId={divisionId}
            estimationType={estimationType}
          />
        )}
        {selectedSection === "other" && (
          <OtherEstimation estimate={estimates[0]} />
        )}
      </div>
    </div>
  );
}
