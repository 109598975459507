import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import "../About/index.css";
import Designation from "../../../../../bu/actions/Designation";
import { Button, Drawer, Input, Form, DatePicker, message, Select } from "antd";
import { LeftOutlined } from '@ant-design/icons';

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

const CustomComponent = ({ user }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [designation, setDesignation] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});

  const renderDetail = (label, value) => (
    <div className="detail">
      <div className="detail-label">{label}</div>
      <div className="detail-value">{value}</div>
    </div>
  );

  const showDrawer = () => {
    console.log("hellooo", user)
    setIsDrawerVisible(true);
    setInitialFormValues({
      firstName: user?.profile?.first_name,
      lastName: user?.profile?.last_name,
      dateOfBirth: user?.profile?.dob ? dayjs(user?.profile?.dob) : null,
      dateOfJoining: user?.profile?.doj ? dayjs(user?.profile?.doj) : null,
      email: user?.email,
      phone: user?.profile?.phone,
      position: user?.profile?.designation?.id, // Assuming designation id should be used
    });
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const onFinish = (values) => {
    console.log("Received values:", values);
  };
  const handleDateOfBirthChange = (date, dateString) => {
    if (!dateString) {
      message.error("Please select a Date of Birth.");
    }
  };

  const handleDateOfJoiningChange = (date, dateString) => {
    if (!dateString) {
      message.error("Please select a Date of Joining.");
    }
  };
  useEffect(() => {
    const getDesignation = async () => {
      try {
        const designation = await Designation.readAll();
        setDesignation(
          designation.map((designation) => {
            return { value: designation.id, label: designation.name };
          })
        );
           // Set initial form values with user profile data
    setInitialFormValues({
      firstName: user?.profile?.first_name,
      lastName: user?.profile?.last_name,
      dateOfBirth: user?.profile?.dob ? dayjs(user?.profile?.dob) : null,
      dateOfJoining: user?.profile?.doj ? dayjs(user?.profile?.doj) : null,
      email: user?.email,
      phone: user?.profile?.phone,
      position: user?.profile?.designation?.id, // Assuming designation id should be used
    });
      } catch (error) {
        console.log("getProjects error", error.message.includes("401"));
        if (error.message.includes("401")) {
        }
      }
    };
    getDesignation();
  }, []);
  const onChange = (selectedValues) => {
    setSelectedPositions(selectedValues);
  };

  const onSearch = (searchText) => {};

  const filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <div>
      <div className="empprofiledata">
      {/* <div className="employeprofile-editbutton">
          <Button
            type="primary"
            onClick={showDrawer}
            className="employeprofile-edit-button"  
          >
            Edit Profile
          </Button>
        </div> */}
        <div className="empprofiledetails">
          <div className="empprofiledata-section1">
            {renderDetail("Employee ID", user.employee_id)}
            {renderDetail(
              "Name",
              user?.profile?.first_name + " " + user?.profile?.last_name
            )}
          </div>
          <div className="empprofiledata-section2">
           
            {renderDetail(
              "Date of Birth",
              <DatePicker
              value={user?.profile?.dob ? dayjs(user?.profile?.dob) : null}
                format={dateFormat}
                bordered={false}
                size="large"
                className="custom-date-picker"
              />
            )}
            
            {renderDetail(
              "Date of Joining",
              
              <DatePicker
                // defaultValue={
                //   user?.profile?.doj ? dayjs(user?.profile?.doj) : null
                // }
                value={user?.profile?.doj ? dayjs(user?.profile?.doj) : null}
                format={dateFormat}
                bordered={false}
                size="large"
                className="custom-date-picker"
              />
            )}
          </div>
          <div className="empprofiledata-section3">
            {renderDetail("Position", user?.profile?.designation?.name)}
            {renderDetail("Department", user?.profile?.designation?.department?.name)}
          </div>
          <div className="empprofiledata-section3" style={{justifyContent:'initial'}}>
            {renderDetail(
              "Experience",
              `${user?.profile?.exp ? user?.profile?.exp : "--"} years`
            )}
            {renderDetail(
              "Total Experience",
              `${
                user?.profile?.total_experience ? user?.profile?.total_experience : "--"
              } years`
              
            )}
          </div>
        </div>
        <div className="employeecontactdetails">
          <h2 className="contactdetaills-title">Contact Details</h2>
          <div className="contactdetails">
            {renderDetail("Phone Number", user?.profile?.phone)}
            {renderDetail("Email Id", user?.email)}
          </div>
        </div>
      </div>

      <div>
        <Drawer
          width="50%"
          icon={<LeftOutlined />}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
               <Button
                icon={<LeftOutlined />}
                type="text"
                onClick={onCloseDrawer}
              />
              <span style={{ fontSize: '18px', paddingLeft:"300px" }}>Edit Profile</span>
            </div>
          }
          placement="right"
          closable={false}
          onClose={onCloseDrawer}
          visible={isDrawerVisible}
        >
          <Form
            name="editUserProfile"
            onFinish={onFinish}
            requiredMark="optional"
            initialValues={initialFormValues}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "9px",
                borderRadius: "inherit",
                paddingRight: "30px",
              }}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please enter your first name!" },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <Input
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                  placeholder="Enter first name"
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please enter your last name!" },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <Input
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                  placeholder="Enter last name"
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderRadius: "inherit",
              }}
            >
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Please select a Date of Birth!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <DatePicker
                  onChange={handleDateOfBirthChange}
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Date of Joining"
                name="dateOfJoining"
                rules={[
                  {
                    required: true,
                    message: "Please select a Date of Joining!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <DatePicker
                  onChange={handleDateOfJoiningChange}
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                borderRadius: "inherit",
              }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <Input
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                  placeholder="Enter Email"
                />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
              >
                <Input
                  style={{
                    borderRadius: "inherit",
                    width: "276px",
                    height: "32px",
                  }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                width: "278px",
                paddingLeft:"67px",
                
              }}
            >
              <Form.Item
                label="Position"
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Please input your position!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="top"
                validateFirst
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  dropdownStyle={{ borderRadius: 'inherit' }} 
                  
                >
                  {designation.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="employeprofile-update-button"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </div>
  );
};

export default CustomComponent;
