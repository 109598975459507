import { QuoteProvider, useQuote } from "./Quote.context";
import { Button } from "antd";
import DataTable from "./Quote.table";
export default function QuoteList() {
  const { quotes, drawerService, searchKey } = useQuote();
  const { estimateDrawerService } = useQuote();
  const handleEdit = (record, action) => {
    console.log("Edit record:", record);
    if (action === "estimate")
      estimateDrawerService.openToCreateEstimate(record.id);
    if (action === "pricing") drawerService.openToEdit(record.id);
    if (action === "edit") drawerService.openToEdit(record.id);
  };
  return (
    <div style={{ flex: 1, overflow: "scroll", padding: 20 }}>
      <DataTable data={quotes} onEdit={handleEdit} />
    </div>
  );
}
