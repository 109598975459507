import {
  Drawer,
  Input,
  Button,
  message,
  DatePicker,
  Select,
  Checkbox,
} from "antd";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Project from "../../../../bu/actions/Projects";
import UserProject from "../../../../bu/actions/UserProject";
import FormFieldTitle from "../../../../components/global/FormFieldTitle";
import ErrorMessage from "../../../../components/global/ErrorMessage";
import Domain from "../../../../bu/actions/Domain";
import Activities from "../../../../bu/actions/Activities";
import { useAppState } from "../../../../state";
import { get } from "../../../../api/request";
import { json } from "react-router";
const { RangePicker } = DatePicker;
export default function AllocateWorkDrawer({
  workAllocateDrawer,
  loadProjects,
  projects,
}) {
  const [state] = useAppState();
  // const [projects, setProjects] = useState([]);
  const [eKey, setEKey] = useState('');
  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectSearchKey, setProjectSearchKey] = useState("");
  const [employeesList, setEmployeesList] = useState([]);
  const [projectSelectionError, setProjectSelectionError] = useState(null);
  const [employeeSearchKey, setEmployeeSearchKey] = useState("");
  const [projectAlloctedUsers, setProjectAlloctedUsers] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allocatedWorks, setAllocatedWorks] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [errorMessage, setErrorMessage] = useState("");
  const [domains, setDomains] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    loadProjects(false);
    loadDomain();
    loadActivity();
  }, []);
  useEffect(() => {
    console.log(
      workAllocateDrawer.isOpen,
      "--------------------------------------------------"
    );
    setSelectedProject(null);
    setSelectedEmployees([]);
  }, [workAllocateDrawer.isOpen]);
  useEffect(() => {
    if (selectedProject) {
      loadProjectUsers();
    }
  }, [selectedProject]);
  // async function loadProjects() {
  //   try {
  //     const pres = await Project.readAll({ limit: 1000 });
  //     setProjects(pres.data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  let test = [];
  for (let i = 0; i < 1000; i++) {
    test[i] = i;
  }
  async function loadDomain() {
    try {
      const dRes = await Domain.readAll({ limit: 1000 });
      setDomains(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadActivity() {
    try {
      const dRes = await Activities.readAll({ limit: 1000 });
      setActivities(dRes.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadProjectUsers() {
    try {
      const res = await get(
        `allocatedwork?searchMap=%7B%22project_id%22%3A${selectedProject}%7D&limit=1000`
      );
      setAllocatedWorks(res?.data || []);
    } catch (e) {
      setAllocatedWorks([]);
      console.log(e);
    }
    if (!selectedProject) return;
    try {
      const pres = await UserProject.readOne(selectedProject);
      setProjectUsers(pres);
      setProjectAlloctedUsers(
        pres.map((u) => ({
          id: u.user_id,
          first_name: u.user?.profile?.first_name,
          last_name: u.user?.profile?.last_name,
          start_date: u.start_date,
          end_date: u.end_date,
          selected: true,
        }))
      );
      console.log(pres);
    } catch (e) {
      console.log(e);
    }
  }
  function validate() {
    const errors = { hasError: false };
    const issues = [];

    selectedEmployees.map((d, i) => {
      console.log(d);

      issues[i] = {};
      if (!d.domain) issues[i].domain = "missing";
      if (!d.activity) issues[i].activity = "missing";
      if (!d.endDate) issues[i].endDate = "missing";
      if (!d.startDate) issues[i].startDate = "missing";
      // if (!d.quantity) issues[i].quantity = "missing";
      if (!d.duration) issues[i].duration = "missing";
      if (d.duration < 0) issues[i].duration = "duration cant be less then 0";
      if (d.quantity < 0) issues[i].quantity = "quantity cant be less then 0";
    });
    console.log(issues);
    console.log(
      issues.map((d) => Object.keys(d).length).reduce((a, b) => a + b, 0)
    );
    const error_count = issues
      .map((d) => Object.keys(d).length)
      .reduce((a, b) => a + b, 0);
    errors.hasError = error_count > 0;
    errors.errors = issues;
    return errors;
  }


  const handleRangePickerChange = (dates, i) => {
    const _t = [...selectedEmployees];
    if (dates && dates[0] && dates[1]) {
      const differenceInMinutes = dates[1].set('millisecond', 999).diff(dates[0].set('millisecond', 0), 'minutes');
      const MINUTES_IN_HOUR = 60
      const duration = (Number((differenceInMinutes / MINUTES_IN_HOUR).toFixed(2)))
      _t[i] = { ..._t[i], startDate: dates[0], endDate: dates[1], duration };
    } else {
      _t[i] = { ..._t[i], startDate: null, endDate: null, duration: null };
    }
    setSelectedEmployees(_t);
  };

  const handleDurationChange = (e, i) => {
    const _t = [...selectedEmployees];
    const duration = (e.target.value);
    _t[i] = { ..._t[i], duration };

    if (duration <= 14) {
      const todayAt9AM = dayjs().set('hour', 9).set('minute', 0).set('second', 0);
      const endDate = todayAt9AM.add(duration, 'hour');
      _t[i] = { ..._t[i], startDate: todayAt9AM, endDate };
    } else {
      const yesterdayAt9AM = dayjs().subtract(1, 'day').set('hour', 9).set('minute', 0).set('second', 0)
      //setDateTimeRange([yesterdayAt9AM, yesterdayAt9AM.add(duration, 'hour')])
      const endDate = yesterdayAt9AM.add(duration, 'hour');
      _t[i] = { ..._t[i], startDate: yesterdayAt9AM, endDate };
    }

    setSelectedEmployees(_t);
  };
  return (
    <Drawer
      title="Allocate Work"
      placement="right"
      onClose={(e) => {
        console.log("close call");
        workAllocateDrawer.close();
      }}
      open={workAllocateDrawer.isOpen}
      style={{ border: "0px solid red" }}
      width="1500"
    >
      {contextHolder}
      <div
        style={{
          height: "99%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <FormFieldTitle>Select Project</FormFieldTitle>
          {!selectedProject && (
            <div>
              <Input
                placeholder={"search project"}
                value={projectSearchKey}
                onChange={(e) => setProjectSearchKey(e.target.value)}
                size="small"
                style={{ marginBottom: 5 }}
              />
              <div
                style={{
                  background: "#eee",
                  padding: 5,
                  height: 200,
                  overflow: "scroll",
                }}
              >
                {projects
                  .filter((p) =>
                    !state?.user?.groups?.includes("GROUP_TEAM_LEAD")
                      ? p
                      : state?.user?.user_id === p.assignee
                  )
                  .filter((p) => p.status == "ALLOCATED")
                  .filter((p) =>
                    p.name
                      .toUpperCase()
                      .includes(projectSearchKey.toUpperCase())
                  )
                  .map((p, i) => {
                    return (
                      <div
                        key={"pro" + i}
                        style={{
                          background: "#fff",
                          margin: 2,
                          padding: 4,
                          cursor: "pointer",
                          color: "#222",
                        }}
                        onClick={(e) => {
                          setSelectedProject(p.id);
                          setProjectSelectionError("");
                          setSelectedEmployees([]);
                        }}
                      >
                        {p.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {/* {selectedProject && <div>some thing</div>} */}
          {selectedProject && (
            <div
              style={{
                display: "flex",
                background: "#eee",
                alignItems: "center",
                padding: "2px 5px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "blue",
                  padding: 5,
                }}
              >
                {projects.filter((d) => d.id === selectedProject)[0]?.name}
              </div>

              <div>
                <Button
                  size={"small"}
                  style={{ fontSize: 12 }}
                  onClick={(e) => {
                    setSelectedProject(null);
                  }}
                >
                  Change Project
                </Button>
              </div>
            </div>
          )}
          <ErrorMessage>{projectSelectionError}</ErrorMessage>
        </div>
        {selectedProject && (
          <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
            <div
              style={{
                width: 250,
                background: "#f1f1f1",
                marginRight: 10,
                height: "100%",
                overflowY: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: 10 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    setSelectedEmployees(projectAlloctedUsers.map((d) => d));
                  }}
                >
                  Add all Employees{" "}
                </Button>
              </div>
              <div style={{ flex: 1, overflowY: "scroll" }}>
                {projectAlloctedUsers.map((u, i) => (
                  <div
                    key={"memnersSelect" + i}
                    style={{
                      fontSize: 12,
                      background: "#fff",
                      margin: 3,
                      display: "flex",
                      padding: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      {u?.first_name} {u?.last_name} - {u.id}
                    </div>
                    <Button
                      size={"small"}
                      type="primary"
                      onClick={(e) => {
                        setSelectedEmployees([
                          projectAlloctedUsers[i],
                          ...selectedEmployees,
                        ]);
                      }}
                    >
                      {">>"}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                background: "#f1f1f1",
                flex: 1,
                overflowY: "scroll",
                padding: 5,
              }}
            >
              {selectedEmployees.map((u, i) => (
                <div style={{ margin: 3 }}>
                  {" "}
                  <div
                    key={"memners" + i}
                    style={{
                      padding: 3,
                      background: "#fff",
                      display: "flex",
                      borderBottom: "1px solid #ddd",
                      background: "#ddd",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontSize: 14,
                        fontWeight: 600,
                        color: "#555",
                      }}
                    >
                      <Checkbox style={{ marginRight: 10 }} /> {u?.first_name}{" "}
                      {u?.last_name}
                    </div>

                    <div
                      style={{
                        fontSize: 10,
                        color: "red",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                      onClick={(e) => {
                        let _ts = [...selectedEmployees];
                        _ts.splice(i, 1);
                        setSelectedEmployees(_ts);
                      }}
                    >
                      Remove
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 3,
                      paddingLeft: 35,
                      background: "#fff",
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    <div>
                      <Select
                        size={"small"}
                        value={selectedEmployees[i].domain}
                        placeholder="Select Domain"
                        onChange={(e) => {
                          let _t = [...selectedEmployees];
                          _t[i].domain = e;
                          setSelectedEmployees(_t);
                          console.log(e);
                        }}
                      >
                        {domains.map((domain, ind) => (
                          <option
                            key={("domain" + i + "-", ind)}
                            value={domain.id}
                          >
                            {domain.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <Select
                        size={"small"}
                        placeholder="Select Activity"
                        value={selectedEmployees[i].activity}
                        onChange={(e) => {
                          let _t = [...selectedEmployees];
                          _t[i].activity = e;
                          _t[i].subActivity = "";
                          setSelectedEmployees(_t);
                          console.log(e, activities);
                        }}
                      >
                        {activities
                          .filter(
                            (e) => e.domain_id === selectedEmployees[i].domain
                          )
                          .filter((a) => a.parent_activity_id === null)
                          .map((activity, ind) => (
                            <option
                              key={("activity" + i + "-", ind)}
                              value={activity.id}
                            >
                              {activity.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                    {selectedEmployees[i].activity && (
                      <div>
                        <Select
                          size={"small"}
                          placeholder="Select Sub Activity"
                          style={{ minWidth: 100 }}
                          value={selectedEmployees[i].subActivity}
                          onChange={(e) => {
                            let _t = [...selectedEmployees];
                            _t[i].subActivity = e;
                            setSelectedEmployees(_t);
                          }}
                        >
                          {activities
                            .filter(
                              (a) =>
                                a.parent_activity_id ===
                                selectedEmployees[i].activity
                            )
                            .map((activity, ind) => (
                              <option
                                key={("subActivity" + i + "-", ind)}
                                value={activity.id}
                              >
                                {activity.name}
                              </option>
                            ))}
                        </Select>
                      </div>
                    )}
                    <div>
                      <Input
                        type="number"
                        min="0"
                        size="small"
                        placeholder={"Quantity"}
                        value={selectedEmployees[i].quantity}
                        onChange={(e) => {
                          let _t = [...selectedEmployees];
                          _t[i].quantity = e.target.value;
                          setSelectedEmployees(_t);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 3,
                      paddingLeft: 35,
                      marginBottom: 10,
                      paddingLeft: 35,
                      background: "#fff",
                      display: "flex",
                      gap: 5,
                      borderRadius: "0 0 10px 10px",
                    }}
                  >
                    <div>
                      <RangePicker
                        size={"small"}
                        format="DD/MM/YYYY hh:mm A"
                        value={[selectedEmployees[i].startDate, selectedEmployees[i].endDate]}
                        onChange={(dates) => handleRangePickerChange(dates, i)}

                      />
                    </div>

                    <div>
                      <Input
                        min={0}
                        max={23}
                        type="number"
                        size="small"
                        placeholder={"Duration (in hrs)"}
                        value={selectedEmployees[i].duration === null ? '' : selectedEmployees[i].duration}
                        onChange={(e) => handleDurationChange(e, i)}

                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                width: 300,
                padding: 10,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <h3>Allocated Works</h3>
              <div
                style={{
                  flex: 1,
                  overflow: "scroll",
                  border: "1px solid #ccc",
                  padding: 5,
                }}
              >
                <Input
                  placeholder={"search employees"}
                  value={eKey}
                  onChange={(e) => setEKey(e.target.value)}
                />
                {allocatedWorks
                  .filter(
                    (d) =>
                      !eKey ? d : (d.user?.profile?.first_name
                        ?.toUpperCase()
                        .includes(eKey?.toUpperCase() || '') ||
                        d.user?.profile?.last_name
                          ?.toUpperCase()
                          .includes(eKey?.toUpperCase() || ''))
                  )
                  .map((d, i) => (
                    <div
                      key={"aw" + i}
                      style={{ padding: 10, borderBottom: "1px solid #ccc" }}
                    >
                      <div style={{ fontSize: 17, fontWeight: 500 }}>
                        {" "}
                        {d?.user?.profile?.first_name}{" "}
                        {d?.user?.profile?.last_name}
                      </div>
                      <div>Domain:{d.domain?.name}</div>
                      <div>Activity:{d.activity?.name}</div>
                      <div>Quantity:{d.quantity}</div>
                      <div>Duration:{d.duration}</div>
                      <div>Start date:{dayjs(d.start_date).format("DD-MM-YYYY hh:mm A")}</div>
                      <div>End date:{dayjs(d.end_date).format("DD-MM-YYYY hh:mm A")}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            paddingTop: 10,
            borderTop: "1px solid #ccc",
          }}
        >
          <div style={{ flex: 1 }} />
          <Button
            onClick={async (e) => {
              try {
                const status = validate();
                console.log(status);
                if (!status.hasError && selectedEmployees.length > 0) {
                  // selectedEmployees
                  console.log("call API");
                  const data = selectedEmployees.map((emp) => {
                    return {
                      description: "",
                      start_date: emp.startDate.toISOString(),
                      end_date: emp.endDate.toISOString(),
                      activity_id: emp.activity,
                      user_id: emp.id,
                      domain_id: emp.domain,
                      project_id: selectedProject,
                      duration: emp.duration,
                      // activity: {
                      //   connect: { id: emp.activity },
                      // },
                      // domain: { connect: { id: emp.domain } },
                      quantity: +emp.quantity,
                      // project: { connect: { id: selectedProject } },
                      // user: { connect: { id: emp.id } },
                    }
                  })
                  console.log('data===>', data)
                  await Project.allocateWork(selectedProject, data)
                  loadProjects(false);
                  workAllocateDrawer.close();
                  console.log("worklog created: -------", data);
                  messageApi.open({
                    type: "success",
                    content: "Work allocated ",
                  });

                } else {
                  console.log(status);
                  status.errors.map((d, i) => {
                    messageApi.open({
                      type: "error",
                      content:
                        selectedEmployees[i].first_name + " " + "has error!",
                    });
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
